import type { AxiosResponse } from 'axios';
import { type UseQueryOptions, type UseQueryResult, useQuery } from 'react-query';
import { ERROR } from '../../helpers/constants/ErrorMessages';
import { QUERY } from '../../helpers/constants/api';
import { ROWS_PER_PAGE } from '../../helpers/constants/misc';
import { useApiResponseHandler } from '../../helpers/hooks/useApiResponseHandler';
import { type UsePageIndexReturnType, usePageIndex } from '../../helpers/hooks/usePageIndex';
import type { AxiosApiError, FetchOfficesResponse } from '../../types';
import { mgmtAxios } from './share/axios';

type Data = FetchOfficesResponse;
interface Params {
  offset: number;
}
type UseFetchOffices = (
  options?: UseQueryOptions<Data, AxiosApiError, Data, (string | Params)[]>,
) => {
  query: UseQueryResult<Data, AxiosApiError>;
  pageIndexHooks: UsePageIndexReturnType;
};

export const useFetchOffices: UseFetchOffices = options => {
  const { handleError } = useApiResponseHandler();
  const [pageIndex, handleChangePageIndex] = usePageIndex();
  const requestParams: Params = { offset: pageIndex * ROWS_PER_PAGE };
  const queryFn = async () => {
    const res: AxiosResponse<Data> = await mgmtAxios.get('/offices', {
      params: requestParams,
    });

    return res.data;
  };
  const query = useQuery([QUERY.OFFICES, 'list', requestParams], queryFn, {
    onError: (err: AxiosApiError) => {
      handleError(ERROR.FETCH_OFFICES, err.response?.data ?? undefined);
    },
    keepPreviousData: true,
    staleTime: 5000,
    ...options,
  });

  return {
    query,
    pageIndexHooks: [pageIndex, handleChangePageIndex],
  };
};
