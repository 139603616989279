export const isNumber = (x: unknown): x is number => typeof x === 'number';

export const isString = (x: unknown): x is string => typeof x === 'string';

export type Primitive = string | number | bigint | boolean | symbol | null | undefined;

export const isPrimitive = (value: unknown): value is Primitive => {
  if (value === null || value === undefined) {
    return true;
  }
  switch (typeof value) {
    case 'string':
    case 'number':
    case 'bigint':
    case 'boolean':
    case 'symbol': {
      return true;
    }
    default:
      return false;
  }
};
