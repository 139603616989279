import styled from '@emotion/styled';
import { Box, Button, Typography } from '@material-ui/core';
import { isLocalDev } from 'helpers/constants/misc';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Layout } from '../../../components/layout/AuthLayout';
import { ds } from '../../../components/theme/authTheme/ds';
import { ConfirmModal } from '../../../components/ui-group';
import { Title } from '../../../components/ui/Title';
import { AUTH, MGMT } from '../../../helpers/constants/Routes';
import { USER_TYPES } from '../../../helpers/constants/user';
import { ErrorMessages } from '../../../helpers/utils/ApiErrors';
import { SetAuthenticationCookie } from '../../../helpers/utils/SetAuthenticationCookie';
import { GetOfficeList, SelectOffice } from '../../../store/api/ApiService';
import type { OfficeType } from '../../../store/models/Office';
import type * as ApiResponseTypes from '../../../store/models/Responses';
import type { Session } from '../../../store/models/Session';
import { RedirectContext } from '../../../store/repository/RedirectContext';
import { OfficeSelectTemplate } from './OfficeSelectTemplate';

const Wrapper = styled.div`
  @media (max-width: ${ds.bp('md')}) {
    input {
      width: 30vw;
    }
  }

  @media (max-width: ${ds.bp('sm')}) {
    font-size: 0.7rem;

    input {
      width: 50vw;
    }

    p {
      font-size: 0.7rem;
    }

    button {
      padding: 0.2rem 0.5rem;
    }
  }
`;

interface State {
  screenLoading: boolean;
  tableLoading: boolean;
  searchLabel: string;
  selectedPage: number;
  totalPages: number;
  officeList: OfficeType[];
  shouldDisplayOfficeId: boolean;
  errorMessage: string;
}

type Action =
  | {
      type: 'fetchOfficeStart';
    }
  | {
      type: 'refetchOfficeStart';
    }
  | {
      type: 'fetchOfficeSuccess';
      payload: { selectedPage: number; totalPages: number; officeList: OfficeType[] };
    }
  | {
      type: 'fetchOfficeSuccessAdmin';
      payload: { selectedPage: number; totalPages: number; officeList: OfficeType[] };
    }
  | {
      type: 'fetchOfficeFailure';
      payload: { errorMessage: string };
    }
  | {
      type: 'selectOfficeStart';
    }
  | {
      type: 'selectOfficeFailure';
      payload: { errorMessage: string };
    };

const initialState: State = {
  screenLoading: true,
  tableLoading: false,
  searchLabel: '',
  selectedPage: 1,
  totalPages: 0,
  officeList: [],
  shouldDisplayOfficeId: false,
  errorMessage: '',
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // ---------- fetch ----------
    case 'fetchOfficeStart':
      return {
        ...state,
        screenLoading: true,
      };
    case 'refetchOfficeStart':
      return {
        ...state,
        tableLoading: true,
      };
    case 'fetchOfficeSuccess':
      return {
        ...state,
        screenLoading: false,
        tableLoading: false,
        searchLabel: '医院名',
        selectedPage: action.payload.selectedPage,
        totalPages: action.payload.totalPages,
        officeList: action.payload.officeList,
      };
    case 'fetchOfficeSuccessAdmin':
      return {
        ...state,
        screenLoading: false,
        tableLoading: false,
        searchLabel: 'クリニックID、医院名',
        selectedPage: action.payload.selectedPage,
        totalPages: action.payload.totalPages,
        officeList: action.payload.officeList,
        shouldDisplayOfficeId: true,
      };
    case 'fetchOfficeFailure':
      return {
        ...state,
        screenLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    // ---------- select ----------
    case 'selectOfficeStart':
      return {
        ...state,
        screenLoading: true,
      };
    case 'selectOfficeFailure':
      return {
        ...state,
        screenLoading: false,
      };

    default:
      return state;
  }
};

export const OfficeSelectPage: React.VFC = () => {
  const [
    {
      screenLoading,
      tableLoading,
      searchLabel,
      errorMessage,
      selectedPage,
      totalPages,
      officeList,
      shouldDisplayOfficeId,
    },
    dispatch,
  ] = useReducer<React.Reducer<State, Action>>(reducer, initialState);
  const [keyword, setKeyword] = useState<string>('');
  const [modal, setModal] = useState<any>({
    show: false,
    selectedOfficeId: 0,
    appName: '',
  });
  const [modalConfirmed, setModalConfirmed] = useState<boolean>(false);
  const { appQuery, redirectAppNameList, redirectToApp, redirectHandler, setLogoutMessage } =
    useContext(RedirectContext);

  const searchRef = useRef<HTMLInputElement>(null);

  const fetchOffices = async (
    page: number,
    refetch: boolean,
  ): Promise<ApiResponseTypes.OfficeListResponseType | void> => {
    if (refetch) {
      dispatch({ type: 'refetchOfficeStart' });
    } else {
      dispatch({ type: 'fetchOfficeStart' });
    }

    const officesData = await GetOfficeList(page, keyword)
      .then((res: ApiResponseTypes.OfficeListResponseType) => {
        if (res.userType === USER_TYPES.ACCOUNTANTS) {
          window.location.href = `${process.env.REACT_APP_APOTOOL_URL as string}managements/`;

          return res;
        }
        if (!keyword.length && !res.offices.length) {
          // 値をglobalStateにセットしたいので、ここはuseReducerのdispatchメソッドを使っていません。
          setLogoutMessage('契約期限が過ぎているため、ご利用できません。');
          redirectHandler(AUTH.LOGOUT, true);

          return res;
        }

        if (res.userType === USER_TYPES.ADMIN) {
          dispatch({
            type: 'fetchOfficeSuccessAdmin',
            payload: { selectedPage: page, totalPages: res.totalPages, officeList: res.offices },
          });
        } else {
          dispatch({
            type: 'fetchOfficeSuccess',
            payload: { selectedPage: page, totalPages: res.totalPages, officeList: res.offices },
          });
        }

        return res;
      })
      .catch((err: Error) => {
        dispatch({
          type: 'fetchOfficeFailure',
          payload: { errorMessage: ErrorMessages(err.message) },
        });
      });

    searchRef.current?.focus();

    return officesData;
  };

  const officeSelectHandler: (
    officeId: number,
    toApotool?: boolean,
    offices?: OfficeType[],
  ) => Promise<void> = async (officeId, toApotool, offices = officeList) => {
    const selectedOffice = offices.filter(office => office.id === officeId);

    const accessibleAppList = selectedOffice[0].accessible;

    // 選択した医院がリダイレクト先のアプリを契約しているかどうかを確認
    // モーダルを確認してApotoolへのリダイレクトに同意したらmodalConfirmedがtrueになるためofficeSelectの処理を続行
    if (appQuery && !accessibleAppList.includes(appQuery) && !modalConfirmed) {
      setModal({ show: true, selectedOfficeId: officeId, appName: redirectAppNameList[appQuery] });

      return;
    }

    dispatch({ type: 'selectOfficeStart' });
    await SelectOffice({ officeId })
      .then((res: Session) => {
        // 医院選択でssoAuthenticatedを更新する
        SetAuthenticationCookie(res?.ssoAuthenticated);
        if (isLocalDev) {
          window.location.href = `${window.location.origin}/${MGMT.REDIRECT_TO_USERS}`;
        } else {
          redirectToApp(toApotool, res);
        }
      })
      .catch((err: Error) => {
        dispatch({
          type: 'selectOfficeFailure',
          payload: { errorMessage: ErrorMessages(err.message) },
        });
      });
  };

  const handleSearchSubmit = () => {
    fetchOffices(1, true);
  };

  const logoutHandler = () => {
    redirectHandler(AUTH.LOGOUT, true);
  };

  useEffect(() => {
    if (modalConfirmed) {
      setModal({ ...modal, show: false });
      officeSelectHandler(modal.selectedOfficeId, true);

      return;
    }

    (async () => {
      const officesData = await fetchOffices(1, false);
      // 医院選択画面に遷移した時にofficeのデータが1つのみだったら画面表示せずにそのままリダイレクト
      if (officesData && officesData.offices.length === 1 && officesData.totalPages === 1) {
        officeSelectHandler(officesData.offices[0].id, false, officesData.offices);
      }
    })();
  }, [modalConfirmed]);

  return (
    <>
      <Title title="医院一覧" />
      <Wrapper>
        {modal.show && (
          <ConfirmModal
            title="Apotool & Boxへ移動します"
            description={`この医院は${modal.appName}の契約がないため、Apotoolへ移動します。よろしいですか？`}
            submit={{
              label: 'はい',
              submit: () => setModalConfirmed(true),
            }}
            modal={{ isShow: modal.show, showToggler: () => setModal({ ...modal, show: false }) }}
          />
        )}
        <Box m="3vh 5vw">
          <Layout loading={screenLoading}>
            <OfficeSelectTemplate
              errorMessage={errorMessage}
              tableLoading={tableLoading}
              shouldDisplayOfficeId={shouldDisplayOfficeId}
              onSearchSubmit={handleSearchSubmit}
              searchLabel={searchLabel}
              keyword={keyword}
              onKeywordChange={newValue => setKeyword(newValue)}
              officeList={officeList}
              onOfficeChange={newValue => officeSelectHandler(newValue)}
              totalPages={totalPages}
              selectedPage={selectedPage}
              onSelectedPageChange={newValue => fetchOffices(newValue, true)}
            />
            <Box mt={2}>
              <Button
                disabled={screenLoading || tableLoading}
                style={{ display: 'block', textAlign: 'left' }}
                onClick={() => logoutHandler()}
                variant="outlined"
              >
                <Typography variant="body1">ログアウト</Typography>
              </Button>
            </Box>
          </Layout>
        </Box>
      </Wrapper>
    </>
  );
};
