import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

export const SelectWrapper = styled.div`
  appearance: none;
  background-color: white;
  border: 1px solid ${ds.color('grey', '4')};
  border-radius: ${ds.get('radius')};
  color: ${ds.color('grey')};
  cursor: pointer;
  height: 32px;
  outline: none;
  padding: 0;
  position: relative;
  text-indent: 0.01px;
  text-overflow: '';
  vertical-align: middle;
  width: 100%;

  ::before {
    background-color: 'red';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${ds.color('grey', '6')};
    content: '';
    height: 0;
    padding: 0;

    /* z-index: 1; */
    pointer-events: none;
    position: absolute;
    right: 0.8em;
    top: 0.8em;
    width: 0;

    :active {
      background-color: 'red';
    }

    &:active {
      background-color: 'red';
    }
  }
`;

export const Select = styled.ul<{ width?: string }>`
  background-color: white;
  border: 1px solid ${ds.color('grey', '4')};
  border-radius: 0 0 ${ds.get('radius')} ${ds.get('radius')};
  position: absolute;
  width: ${props => props.width || 'auto'};
  z-index: 9;

  &.hide {
    display: none;
  }

  button:last-of-type {
    border-radius: 0 0 ${ds.get('radius')} ${ds.get('radius')};
  }
`;

export const Option = styled.button<{ width?: string }>`
  background-color: ${ds.brand('white')};
  border-top: 1px solid ${ds.color('grey', '4')};
  color: ${ds.brand('black')};
  cursor: pointer;
  display: block;
  line-height: 16px;
  list-style: none;
  margin: 0 0;
  padding: 20px 20px;
  width: ${props => props.width || '100%'};
`;
