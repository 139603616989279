import DesignSystem from 'design-system-utils';

const designTokens = {
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    serif: 'Georgia, "Times New Roman", Times, serif',
    monospace: 'Menlo, monospace',
  },
  fontWeight: {
    normal: 300,
    bold: 700,
    heading: 700,
  },

  colors: {
    colorPalette: {
      // from materialUI color
      // https://material-ui.com/customization/color/
      grey: {
        base: '#fafafa',
        '1': '#fafafa',
        '2': '#f5f5f5',
        '3': '#e0e0e0',
        '4': '#bdbdbd',
        '5': '#9e9e9e',
        '6': '#757575',
        '7': '#616161',
        '8': '#424242',
        '9': '#212121',
      },
      amber: {
        base: '#ffc107',
        '50': '#fff8e1',
        '100': '#ffecb3',
        '200': '#ffe082',
        '300': '#ffd54f',
        '400': '#ffca28',
        '500': '#ffc107',
        '600': '#ffb300',
        '700': '#ffa000',
        '800': '#ff8f00',
        '900': '#ff6f00',
      },
      deepOrange: {
        base: '#ff3d00',
        '50': '#fbe9e7',
        '100': '#ffccbc',
        '200': '#ffab91',
        '300': '#ff8a65',
        '400': '#ff7043',
        '500': '#ff5722',
        '600': '#f4511e',
        '700': '#e64a19',
        '800': '#d84315',
        '900': '#bf360c',
      },
      teal: {
        base: '#009688',
        '50': '#e0f2f1',
        '100': '#b2dfdb',
        '200': '#80cbc4',
        '300': '#4db6ac',
        '400': '#26a69a',
        '500': '#009688',
        '600': '#00897b',
        '700': '#00796b',
        '800': '#00695c',
        '900': '#004d40',
      },
    },
    brand: {
      // accent color
      light: '#5de7e6',
      main: '#00b4b4',
      dark: '#008484',
      // a little lighter or darker than main
      lilLight: '#4de7c6',
      lilDark: '#009999',
      // base color
      base: '#f0f0ed',
      // normal text color
      white: '#ffffff',
      black: '#1f1f1f',
      // other
      success: '#27a745',
      danger: '#dc3545',
      muted: '#f6f6f6',
    },
  },

  breakpoints: {
    xs: '320px',
    sm: '600px',
    md: '1025px',
    lg: '1280px',
    xl: '1920px',
  },

  zIndex: {
    background: -100,
    select: 500,
    interviewTitle: 700,
    header: 1000,
    drawer: 1200,
    backdrop: 1300,
    modal: 1301,
    snackbar: 1400,
    tooltip: 1500,
  },

  spacing: {
    scale: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64],
  },

  shadow: {
    '0': '1px 2px 3px 1px rgba(0,0,0,0.2)',
    // from https://material-ui.com/customization/default-theme/#default-theme
    '1': '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '2': '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '3': '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '4': '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '5': '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '6': '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  },

  radius: '4px',
};

export const ds = new DesignSystem(designTokens);
