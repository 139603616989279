import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useCallback, useEffect, useMemo } from 'react';
import {
  type CellProps,
  type Column,
  type TableOptions,
  useRowSelect,
  useTable,
} from 'react-table';
import { MgmtLayout } from '../../../components/layout/mgmtLayout/MgmtLayout';
import { TableCheckbox } from '../../../components/ui/Table/TableCheckbox';
import { TextWithRuby } from '../../../components/ui/TextWithRuby/TextWithRuby';
import { Tooltip } from '../../../components/ui/Tooltip/Tooltip';
import { MGMT } from '../../../helpers/constants/Routes';
import { initialUsersTableData } from '../../../helpers/constants/initialTableData';
import { useRouter } from '../../../helpers/hooks/useRouter';
import { convertSelectedRowIds } from '../../../helpers/utils/convertSelectedRowIds';
import { convertUsersForUsersTable } from '../../../helpers/utils/convertUsersForUsersTable';
import { useDeleteUsers } from '../../../store/api/useDeleteUsers';
import { useFetchManager } from '../../../store/api/useFetchManager';
import { useFetchUsers } from '../../../store/api/useFetchUsers';
import type { Username, UsersTableData, UsersTableState } from '../../../types';
import { UsersTemplate } from './UsersTemplate';

export const UsersPage: React.FC = () => {
  const {
    history,
    location: { state },
  } = useRouter<UsersTableState | null>();
  const userIdsState = state?.userIds ?? undefined;
  const pageIndexState = state?.pageIndex ?? undefined;

  // ---------- api(query) ----------
  const { data: loginUser } = useFetchManager({ enabled: false });
  const {
    query: { data: usersData, isLoading },
    usersState,
    usersHandlers,
  } = useFetchUsers();

  // ---------- table ----------
  const tableData = useMemo(
    () => (usersData ? convertUsersForUsersTable(usersData) : initialUsersTableData),
    [usersData],
  );
  const columns: Column<UsersTableData>[] = useMemo(
    () => [
      {
        Header: 'ユーザー名',
        Cell: ({ value }: CellProps<UsersTableData, Username>) => (
          <Tooltip variant="intrinsic" title={value.name} isWrappedInScroll>
            <TextWithRuby ruby={value.kana} text={value.name} />
          </Tooltip>
        ),
        accessor: 'username',
        maxWidth: 180,
      },
      {
        Header: '所属クリニック名',
        accessor: 'office',
        maxWidth: 180,
      },
      {
        Header: 'メールアドレス',
        accessor: 'email',
        maxWidth: 180,
      },
    ],
    [],
  );
  // idは表示しない
  const visibleColumns: (keyof UsersTableData)[] = ['username', 'office', 'email'];
  const tableOptions: TableOptions<UsersTableData> = {
    data: tableData,
    columns,
    defaultColumn: {
      Cell: ({ value }: CellProps<UsersTableData, React.ReactText>) => (
        <Tooltip variant="intrinsic" title={value} isWrappedInScroll>
          {value}
        </Tooltip>
      ),
    },
    visibleColumns,
    getRowId: (originalRow: UsersTableData) => `${originalRow.id}`,
    autoResetSelectedRows: false,
    initialState: {
      selectedRowIds: userIdsState ? convertSelectedRowIds.toObject(userIdsState) : {},
    },
  };
  const usersTable = useTable<UsersTableData>(tableOptions, useRowSelect, hooks => {
    hooks.allColumns.push(hooksColumns => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <TableCheckbox {...getToggleAllRowsSelectedProps()} title={undefined} />
        ),
        Cell: ({ row }: CellProps<UsersTableData>) => (
          <TableCheckbox isWrappedInScroll {...row.getToggleRowSelectedProps()} title={undefined} />
        ),
      },
      ...hooksColumns,
      {
        id: 'edit',
        Header: () => null,
        Cell: ({ row, state: { selectedRowIds } }: CellProps<UsersTableData, string>) => {
          const selectedUserIds = convertSelectedRowIds.toArray(selectedRowIds);

          return (
            <Tooltip variant="standard" title="編集" isWrappedInScroll>
              <IconButton
                color="primary"
                onClick={() =>
                  history.push(`/${MGMT.USER_EDIT}/${row.id}`, {
                    userIds: selectedUserIds,
                    pageIndex: usersState.pageIndex,
                  })
                }
              >
                <Edit />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ]);
  });

  // ---------- delete ----------
  const { mutate: deleteFn, isLoading: isDeleting } = useDeleteUsers({
    onSuccess: () => {
      usersTable.dispatch({ type: 'resetSelectedRows' });
      usersHandlers.handleChangePageIndex(undefined, 0);
    },
  });
  const userIds = convertSelectedRowIds.toArray(usersTable.state.selectedRowIds);
  const handleDelete = useCallback(() => {
    deleteFn({ userIds });
  }, [deleteFn, userIds]);

  useEffect(() => {
    if (pageIndexState) {
      usersHandlers.handleChangePageIndex(undefined, pageIndexState);
    }
  }, []);

  return (
    <MgmtLayout
      documentTitle="ユーザー一覧"
      usersTableState={{ pageIndex: usersState.pageIndex, userIds }}
    >
      {loginUser && (
        <UsersTemplate
          usersTable={usersTable}
          usersState={usersState}
          usersHandlers={usersHandlers}
          handleDelete={handleDelete}
          loginUser={loginUser}
          totalCount={usersData?.totalCount ?? 0}
          status={{
            isLoading,
            isDeleting,
          }}
        />
      )}
    </MgmtLayout>
  );
};
