import { Box, Button } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Redirect } from 'react-router-dom';
import { type CellProps, type TableOptions, useTable } from 'react-table';
import { MgmtAdminLayout } from '../../../components/layout/mgmtLayout/MgmtAdminLayout';
import { CircularProgress } from '../../../components/ui/CircularProgress/CircularProgress';
import { Tooltip } from '../../../components/ui/Tooltip/Tooltip';
import { MGMT } from '../../../helpers/constants/Routes';
import { QUERY } from '../../../helpers/constants/api';
import { initialAdminMgmtUsersTableData } from '../../../helpers/constants/initialTableData';
import { useRouter } from '../../../helpers/hooks/useRouter';
import { convertMgmtUsersForMgmtUsersTable } from '../../../helpers/utils/convertMgmtUsersForMgmtUsersTable';
import { useFetchAdminMgmtUsers } from '../../../store/api/useFetchAdminMgmtUsers';
import { useSelectAccount } from '../../../store/api/useSelectAccount';
import type { AdminMgmtUsersTableData, FetchManagerResponse } from '../../../types';
import { AdminMgmtUserTemplate } from './AdminMgmtUserTemplate';

export const AdminMgmtUserPage: React.FC = () => {
  const { history } = useRouter();

  // ---------- api(query)----------
  const { data, isLoading } = useFetchAdminMgmtUsers();
  const queryClient = useQueryClient();
  const loginUserData = queryClient.getQueryData<FetchManagerResponse>([QUERY.MANAGER]);
  const userId = loginUserData?.user.id ?? undefined;
  const isAdmin = loginUserData?.user.isAdmin ?? undefined;

  // ---------- api(mutation) ----------
  const { mutate, isLoading: isMutating } = useSelectAccount({
    onSuccess: () => history.push(`/${MGMT.USERS}`),
  });
  const handleSelectAccount = useCallback(
    (newUserId: string) => {
      mutate({ userId: newUserId });
    },
    [mutate],
  );

  // ---------- table ----------
  const tableData = useMemo(() => {
    if (data) {
      return convertMgmtUsersForMgmtUsersTable(data.mgmtUsers);
    }

    return initialAdminMgmtUsersTableData;
  }, [data]);
  const columns = useMemo(
    () => [
      {
        Header: '権限',
        accessor: 'userType',
        maxWidth: 80,
      },
      {
        Header: '契約者名',
        accessor: 'managerName',
        maxWidth: 120,
      },
      {
        Header: 'クリニック名',
        accessor: 'officeName',
        maxWidth: 180,
      },
      {
        Header: 'メールアドレス',
        accessor: 'email',
        maxWidth: 120,
      },
      {
        Header: '',
        accessor: 'login',
        maxWidth: 80,
        Cell: ({ row }: CellProps<AdminMgmtUsersTableData, string>) => {
          const isCurrentSelectedUser = userId !== undefined && row.id === `${userId}`;

          return (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                disableElevation
                disabled={isCurrentSelectedUser}
                onClick={() => handleSelectAccount(row.id)}
              >
                {isCurrentSelectedUser ? 'ログイン中' : 'ログイン'}
              </Button>
            </Box>
          );
        },
      },
    ],
    [handleSelectAccount, userId],
  );
  // idは表示しない
  const visibleColumns: (keyof AdminMgmtUsersTableData)[] = [
    'userType',
    'managerName',
    'officeName',
    'email',
    'login',
  ];
  const tableOptions: TableOptions<AdminMgmtUsersTableData> = {
    data: tableData,
    columns,
    visibleColumns,
    defaultColumn: {
      Cell: ({ value }: CellProps<AdminMgmtUsersTableData, React.ReactText>) => (
        <Tooltip variant="intrinsic" title={`${value}`} isWrappedInScroll>
          {value}
        </Tooltip>
      ),
    },
    getRowId: (originalRow: AdminMgmtUsersTableData) => `${originalRow.userId}`,
  };
  const adminMgmtUsersTable = useTable<AdminMgmtUsersTableData>(tableOptions);

  // 管理者ではない(直接URLを叩いた場合など)ときはリダイレクト
  if (isAdmin === false) {
    return <Redirect to={`/${MGMT.USERS}`} />;
  }

  return (
    <MgmtAdminLayout documentTitle="管理ユーザー選択画面">
      {isMutating && <CircularProgress />}
      <AdminMgmtUserTemplate adminMgmtUsersTable={adminMgmtUsersTable} isLoading={isLoading} />
    </MgmtAdminLayout>
  );
};
