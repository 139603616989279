import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

export const HiddenInput = styled.input`
  display: none;
  opacity: 0;
`;

type CheckBoxProps = {
  theme?: { main: string };
};

export const CheckBox = styled.label<CheckBoxProps>`
  background-color: ${ds.color('grey', '2')};
  border-radius: ${ds.get('radius')};
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin-right: ${ds.space(2)};
  padding: 12px 12px 12px 48px;
  position: relative;
  transition: background-color 0.2s linear;
  vertical-align: middle;

  &:hover {
    background-color: ${ds.color('grey', '3')};
  }

  &::after {
    border: 2px solid ${props => props.theme.main || ds.brand('main')};
    border-radius: 2px;
    content: '';
    display: block;
    height: 16px;
    left: 15px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    transition: border-color 0.2s linear;
    width: 16px;
  }

  &.checked {
    &::before {
      border-bottom: 3px solid ${props => props.theme.main || ds.brand('main')};
      border-right: 3px solid ${props => props.theme.main || ds.brand('main')};
      content: '';
      display: block;
      height: 15px;
      left: 22px;
      margin-top: -8px;
      opacity: 1;
      position: absolute;
      top: 50%;
      transform: rotate(45deg);
      transition: opacity 0.2s linear;
      width: 5px;
    }
  }

  /* 医院側 */
  &.disabled {
    background-color: ${ds.color('grey', '2')};
    cursor: grab;

    &::after {
      border: 2px solid grey;
    }
  }
`;

type RadioProps = {
  theme?: { main: string };
};
export const Radio = styled.label<RadioProps>`
  background-color: ${ds.color('grey', '2')};
  border-radius: ${ds.get('radius')};
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 12px 12px 12px 48px; /* 丸ポチと選択肢を少しだけ離す */
  position: relative;
  transition: background-color 0.2s linear;
  vertical-align: middle;

  /* afterは◎の外側 */
  &::after {
    border: 2px solid ${props => props.theme.main || ds.brand('main')};
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    left: 15px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    transition: border-color 0.2s linear;
    width: 16px;
  }

  &:hover {
    background-color: ${ds.color('grey', '3')};

    &::after {
      border-color: ${props => props.theme.main || ds.brand('main')};
    }
  }

  /* beforeは◎の内側 */
  &.checked {
    &::before {
      background-color: ${props => props.theme.main || ds.brand('main')};
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      left: 18px;
      margin-top: -5px;
      position: absolute;
      top: 50%;
      transition: opacity 0.2s linear;
      width: 10px;
    }
  }

  /* 医院側 */
  &.disabled {
    background-color: ${ds.color('grey', '2')};
    cursor: grab;

    &::after {
      border: 2px solid grey;
    }
  }
`;

// 最初にこのコンポーネントを作ったとき隣接兄弟結合子+の使い方がわからなかったので↑になってしまっているが、
// 本当は↓を使いたい
export const StyledInput = styled.input`
  display: none;

  :checked + label::after {
    border-bottom: 3px solid ${ds.color('grey', '6')};
    border-right: 3px solid ${ds.color('grey', '6')};
    content: '';
    display: block;
    height: 15px;
    left: 32px;
    margin-top: -10px;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    transition: opacity 0.2s linear;
    width: 5px;
  }
`;

export const StyledCheckBox = styled.label`
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin-left: -14px;
  margin-top: -14px;
  padding: 12px 12px 32px 60px;
  position: relative;
  transition: background-color 0.2s linear;
  vertical-align: middle;

  &::before {
    border: 2px solid ${ds.color('grey', '6')};
    border-radius: 4px;
    content: '';
    display: block;
    height: 20px;
    left: 24px;
    position: absolute;
    top: 12px;
    width: 20px;
  }
`;
