import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { MgmtLayout } from '../../../components/layout/mgmtLayout/MgmtLayout';
import { CircularProgress } from '../../../components/ui/CircularProgress/CircularProgress';
import { MGMT } from '../../../helpers/constants/Routes';
import { USER_TYPES } from '../../../helpers/constants/user';
import { useRouter } from '../../../helpers/hooks/useRouter';
import { convertToKana } from '../../../helpers/utils/convertToKana';
import { useSaveUserForm } from '../../../helpers/validator/useSaveUserForm';
import { useDeleteUsers } from '../../../store/api/useDeleteUsers';
import { useFetchManager } from '../../../store/api/useFetchManager';
import { useFetchUser } from '../../../store/api/useFetchUser';
import { useUpdateUser } from '../../../store/api/useUpdateUser';
import { UserEditTemplate } from './UserEditTemplate';

export const UserEditPage: React.VFC = () => {
  const {
    params: { userId },
    paramsInt: { userId: userIdInt },
    history,
  } = useRouter<{ userIds: number; pageIndex: number }, { userId: string }>();

  // ---------- form ----------
  const methods = useSaveUserForm();
  const {
    reset,
    watch,
    setValue,
    formState: { dirtyFields },
    handleSubmit,
  } = methods;

  // ---------- api(query) ----------
  const { data: loginUser } = useFetchManager({ enabled: false });
  const { data: targetUserData } = useFetchUser(userIdInt, {
    onSuccess: res => {
      reset({
        name: res.name,
        kana: res.kana,
        officeIds: res.offices.map(office => office.id),
        email: res.email,
        memo: res.memo,
      });
    },
  });
  const isManager = loginUser?.userType === USER_TYPES.MANAGER ?? false;

  const normalUserOfficeIds = targetUserData?.offices.map(office => office.id) ?? [];
  const loginUserOfficeIds = loginUser?.offices.map(office => office.id) ?? [];

  // ---------- 管理ユーザーの所属クリニック ----------
  const loginUserOfficeList =
    loginUser?.offices.map(office => ({
      label: office.name,
      value: office.id,
      mutable: true,
    })) ?? [];

  // ---------- 一般ユーザーの所属クリニックで、管理ユーザーは所属していないクリニック ----------
  const immutableOfficeList =
    targetUserData?.offices
      .filter(userOffice => !loginUserOfficeIds.includes(userOffice.id))
      .map(office => ({
        label: office.name,
        value: office.id,
        mutable: false,
      })) ?? [];

  // ---------- submit ----------
  const requiredInputs = watch(['name', 'kana', 'officeIds', 'email']);
  const isSubmitAble =
    Object.keys(dirtyFields).length > 0 &&
    Object.values(requiredInputs).every(requiredValue => requiredValue.length > 0);
  const { mutate, isLoading: isMutating } = useUpdateUser({
    onSuccess: () => history.push(`/${MGMT.USERS}`),
  });
  const onSubmit = handleSubmit(({ name, kana, email, officeIds, memo }) => {
    mutate({
      userId,
      name,
      kana,
      email,
      addOfficeIds: officeIds.filter(officeId => !normalUserOfficeIds.includes(officeId)),
      deleteOfficeIds: normalUserOfficeIds.filter(officeId => !officeIds.includes(officeId)),
      memo,
    });
  });

  // ---------- kana ----------
  const kanaInputValue = watch('kana');
  const handleConvertToKana = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setValue('kana', convertToKana(e.target.value), {
        shouldDirty: true,
      });
    },
    [setValue],
  );

  // ---------- delete ----------
  const { mutate: deleteFn } = useDeleteUsers({
    onSuccess: () => history.push(`/${MGMT.USERS}`),
  });
  const handelDelete = () => {
    deleteFn({ userIds: [Number.parseInt(userId, 10)] });
  };

  return (
    <MgmtLayout documentTitle="編集">
      <FormProvider {...methods}>
        {isMutating && <CircularProgress />}
        {loginUser && targetUserData && (
          <UserEditTemplate
            isManager={isManager}
            officeList={[...immutableOfficeList, ...loginUserOfficeList]}
            timestamps={{
              createdAt: targetUserData.createdAt,
              updatedAt: targetUserData.updatedAt,
            }}
            submit={{
              isSubmitAble,
              onSubmit,
            }}
            kanaInput={{
              kanaInputValue,
              handleConvertToKana,
            }}
            handleDelete={handelDelete}
          />
        )}
      </FormProvider>
    </MgmtLayout>
  );
};
