import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Lock } from '@material-ui/icons';
import Div100vh from 'react-div-100vh';

interface Props {
  handleGoToApotool: () => void;
  handleLogout: () => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconAndTextWrapper: {
    display: 'flex',
    width: 450,
    columnGap: theme.spacing(3),
    // 中心より気持ち上目に配置する
    marginBottom: theme.spacing(7),
    '@media (min-width:0px) and (orientation: landscape)': {
      marginBottom: theme.spacing(6),
    },
    '@media (min-width:600px)': {
      marginBottom: theme.spacing(8),
    },
  },
  lockIcon: {
    fontSize: 72,
    color: theme.palette.text.secondary,
  },
  message: {
    marginBottom: theme.spacing(4),
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    columnGap: theme.spacing(2),
  },
  goToApotool: {
    textTransform: 'none',
  },
}));

export const AuthErrorTemplate: React.VFC<Props> = ({ handleGoToApotool, handleLogout }) => {
  const classes = useStyles();

  return (
    <Div100vh className={classes.root}>
      <div className={classes.iconAndTextWrapper}>
        <Lock className={classes.lockIcon} />
        <div>
          <Typography variant="h3" gutterBottom>
            アクセス権が必要です
          </Typography>
          <Typography className={classes.message} variant="body1" color="textSecondary">
            Apotool&Boxへ戻るか、アクセス権のあるアカウントに切り替えてください。
          </Typography>
          <div className={classes.buttonWrapper}>
            <Button variant="contained" color="primary" fullWidth onClick={handleLogout}>
              アカウント切替
            </Button>
            <Button
              className={classes.goToApotool}
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleGoToApotool}
            >
              Apotool&Boxへ
            </Button>
          </div>
        </div>
      </div>
    </Div100vh>
  );
};
