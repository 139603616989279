import { makeStyles } from '@material-ui/core/styles';
import type { TableInstance } from 'react-table';
import { PageTitle } from '../../../components/ui/PageTitle/PageTitle';
import { Table } from '../../../components/ui/Table/Table';
import type { AdminMgmtUsersTableData } from '../../../types';

interface Props {
  adminMgmtUsersTable: TableInstance<AdminMgmtUsersTableData>;
  isLoading: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridAutoRows: 'auto 1fr',
    height: '100%',
  },
}));

export const AdminMgmtUserTemplate: React.VFC<Props> = ({ adminMgmtUsersTable, isLoading }) => {
  const classes = useStyles();
  const { headerGroups, rows, prepareRow, getTableProps } = adminMgmtUsersTable;
  const tableProps = getTableProps();

  return (
    <div className={classes.root}>
      <PageTitle paragraph>管理ユーザー一覧</PageTitle>
      <Table
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        totalCount={rows.length}
        tableProps={tableProps}
        isLoading={isLoading}
      />
    </div>
  );
};
