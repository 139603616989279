import { makeStyles } from '@material-ui/core/styles';
import type { TableInstance } from 'react-table';
import { PageTitle } from '../../../components/ui/PageTitle/PageTitle';
import { Table } from '../../../components/ui/Table/Table';
import type { OfficesTableData } from '../../../types';

interface Props {
  officesTable: TableInstance<OfficesTableData>;
  paging: {
    pageIndex: number;
    handleChangePageIndex: (_: unknown, newPageIndex: number) => void;
  };
  totalCount: number;
  isLoading: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridAutoRows: 'auto 1fr',
    height: '100%',
  },
}));

export const OfficesTemplate: React.FC<Props> = ({
  officesTable,
  paging,
  totalCount,
  isLoading,
}) => {
  const classes = useStyles();
  const { headerGroups, rows, prepareRow, getTableProps } = officesTable;
  const tableProps = getTableProps();

  return (
    <div className={classes.root}>
      <PageTitle paragraph>クリニック一覧</PageTitle>
      <Table
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        totalCount={totalCount}
        pageIndex={paging.pageIndex}
        handleChangePageIndex={paging.handleChangePageIndex}
        tableProps={tableProps}
        tableCellProps={{ size: 'medium' }}
        isLoading={isLoading}
      />
    </div>
  );
};
