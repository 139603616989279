import styled from '@emotion/styled';
import { Box, Button, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { useRef } from 'react';
import { ds } from '../../../components/theme/authTheme/ds';
import { StyledTable } from '../../../components/ui';
import type { OfficeType } from '../../../store/models/Office';

interface Props {
  errorMessage: string;
  tableLoading: boolean;
  shouldDisplayOfficeId: boolean;
  onSearchSubmit: () => void;
  searchLabel: string;
  keyword: string;
  onKeywordChange: (newValue: string) => void;
  officeList: OfficeType[];
  onOfficeChange: (newValue: number) => void;
  totalPages: number;
  selectedPage: number;
  onSelectedPageChange: (newValue: number) => void;
}

export const OfficeTable = styled(StyledTable)`
  th,
  td {
    padding: 1rem 0.3rem;
    text-align: left;

    &.id,
    &.btn {
      text-align: center;
    }

    &.id {
      width: 4vw;
    }

    &.name {
      width: 15vw;
    }

    &.tel,
    &.openingTime,
    &.btn {
      width: 10vw;
    }

    &.address {
      width: 20vw;
    }
  }

  button {
    padding: 0.6rem;
  }

  @media (max-width: ${ds.bp('sm')}) {
    font-size: 0.7rem;

    th,
    td {
      &.address {
        display: none;
      }

      &.btn {
        width: 13vw;
      }
    }

    button {
      font-size: 0.4rem;
      padding: 0.3rem;
    }
  }
`;

const useStyles = makeStyles(theme => ({
  inputLabel: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  wrapper: {
    minHeight: '75vh',
  },
  loaderWrapper: {
    minHeight: '57vh',
  },
  form: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: ({ matchesSm }: { matchesSm: boolean }) => (matchesSm ? '0' : '0 auto'),
  },
}));

export const OfficeSelectTemplate: React.VFC<Props> = ({
  errorMessage,
  tableLoading,
  shouldDisplayOfficeId,
  onSearchSubmit,
  searchLabel,
  keyword,
  onKeywordChange,
  officeList,
  onOfficeChange,
  totalPages,
  selectedPage,
  onSelectedPageChange,
}) => {
  // ページネーションのスタイリング用
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles({ matchesSm });

  const searchRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Box my="1rem">
        <Typography color="error">{errorMessage || null}</Typography>
      </Box>
      <Box mt="5vh" mb="2vh">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction={matchesSm ? 'row' : 'column'}
          wrap={matchesSm ? 'nowrap' : 'wrap'}
          style={{ margin: '0' }}
        >
          <form
            className={classes.form}
            onSubmit={e => {
              e.preventDefault();
              onSearchSubmit();
            }}
          >
            <TextField
              disabled={tableLoading}
              label={searchLabel}
              value={keyword}
              autoFocus
              inputRef={searchRef}
              variant="outlined"
              style={{
                width: matchesXs ? '50vw' : '20vw',
                height: matchesXs ? 30 : 'auto',
              }}
              InputProps={{
                style: {
                  height: matchesXs ? 30 : 'auto',
                },
              }}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              size="small"
              onChange={e => onKeywordChange(e.target.value)}
            />
            <Box ml={1}>
              <Button disabled={tableLoading} type="submit" disableElevation variant="outlined">
                <Typography variant="body1">検索</Typography>
              </Button>
            </Box>
          </form>
          <Pagination
            count={totalPages}
            page={selectedPage}
            onChange={(_, page) => onSelectedPageChange(page)}
            siblingCount={0}
            showFirstButton={!!matchesSm}
            showLastButton={!!matchesSm}
            style={{ margin: matchesSm ? '0' : '4vh auto' }}
          />
        </Grid>
      </Box>
      <OfficeTable>
        <thead>
          <tr>
            <th className="id" style={{ display: shouldDisplayOfficeId ? 'table-cell' : 'none' }}>
              ID
            </th>
            <th className="name">クリニック名</th>
            <th className="tel">電話番号</th>
            <th className="address">住所</th>
            <th className="openingTime">診療時間</th>
            <th className="btn" />
          </tr>
        </thead>
        <tbody>
          {officeList.map((office: OfficeType) => (
            <tr key={office.id} style={{ borderTop: '1px solid #c8caca' }}>
              <td className="id" style={{ display: shouldDisplayOfficeId ? 'table-cell' : 'none' }}>
                {office.id}
              </td>
              <td className="name">{office.name}</td>
              <td className="tel">{office.tel}</td>
              <td className="address">{office.address}</td>
              <td className="openingTime">
                {office.opensAt} - {office.closesAt}
              </td>
              <td className="btn">
                <Button
                  disabled={tableLoading}
                  style={{ borderRadius: '20px' }}
                  onClick={() => onOfficeChange(office.id)}
                  disableElevation
                  variant="contained"
                  color="primary"
                >
                  管理画面へ
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </OfficeTable>
    </>
  );
};
