import type { History, Location, LocationState } from 'history';
import { useMemo } from 'react';
import {
  type match as Match,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

type UserRouterReturnType<
  TLocationState extends LocationState,
  TParams extends Record<string, string> = Record<string, never>,
> = {
  params: TParams;
  paramsInt: { [k: string]: number };
  location: Location<TLocationState>;
  history: History<TLocationState>;
  match: Match<TParams> | null;
};

export const useRouter = <
  TState extends LocationState,
  TParams extends Record<string, string> = Record<string, never>,
>(): UserRouterReturnType<TState, TParams> => {
  const params = useParams<TParams>();
  const paramsInt = Object.fromEntries<number>(
    Object.entries<string>(params).map(([key, value]: [string, string]) => [
      key,
      Number.parseInt(value, 10),
    ]),
  );
  const location = useLocation<TState>();
  const history = useHistory<TState>();
  const match = useRouteMatch<TParams>();

  return useMemo(
    () => ({
      params,
      paramsInt,
      location,
      history,
      match,
    }),
    [params, paramsInt, location, history, match],
  );
};
