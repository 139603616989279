import { type RefObject, useCallback, useEffect, useLayoutEffect } from 'react';
import { useRafState } from './useRafState';

export interface ScrollState {
  scrollLeft: number;
  scrollTop: number;
  isScrollLeftEnd: boolean;
  isScrollTopEnd: boolean;
  hasVerticalScroll: boolean;
  hasHorizontalScroll: boolean;
}

type Direction = 'left' | 'right' | 'up' | 'down';
type UseScrollReturnType = [ScrollState, (direction: Direction, scrollDistance: number) => void];

export const useScroll = (ref: RefObject<HTMLElement>): UseScrollReturnType => {
  const [scrollState, setScrollState] = useRafState<ScrollState>({
    scrollLeft: 0,
    scrollTop: 0,
    isScrollLeftEnd: true,
    isScrollTopEnd: true,
    hasVerticalScroll: false,
    hasHorizontalScroll: false,
  });
  const handleScroll = useCallback(
    (direction: Direction, scrollDistance: number) => {
      if (!ref.current) return;
      const isPositive = direction === 'right' || direction === 'down';
      const scrollTo = isPositive ? scrollDistance : scrollDistance * -1;
      const isVertical = direction === 'up' || direction === 'down';
      const scrollToOptions: ScrollToOptions = isVertical ? { top: scrollTo } : { left: scrollTo };

      ref.current.scrollBy({ ...scrollToOptions, behavior: 'smooth' });
    },
    [ref],
  );

  useLayoutEffect(() => {
    const refCurrentCopy = ref.current;
    if (refCurrentCopy) {
      setScrollState(prevState => ({
        ...prevState,
        isScrollLeftEnd:
          refCurrentCopy.scrollWidth === refCurrentCopy.clientWidth + refCurrentCopy.scrollLeft,
        isScrollTopEnd:
          refCurrentCopy.scrollHeight === refCurrentCopy.clientHeight + refCurrentCopy.scrollTop,
        hasVerticalScroll: refCurrentCopy.scrollHeight !== refCurrentCopy.clientHeight,
        hasHorizontalScroll: refCurrentCopy.scrollWidth !== refCurrentCopy.clientWidth,
      }));
    }
  }, [ref, setScrollState]);

  useEffect(() => {
    const refCurrentCopy = ref.current;
    const handler = () => {
      if (refCurrentCopy) {
        setScrollState({
          scrollLeft: refCurrentCopy.scrollLeft,
          scrollTop: refCurrentCopy.scrollTop,
          isScrollLeftEnd:
            refCurrentCopy.scrollWidth === refCurrentCopy.clientWidth + refCurrentCopy.scrollLeft,
          isScrollTopEnd:
            refCurrentCopy.scrollHeight === refCurrentCopy.clientHeight + refCurrentCopy.scrollTop,
          hasVerticalScroll: refCurrentCopy.scrollHeight !== refCurrentCopy.clientHeight,
          hasHorizontalScroll: refCurrentCopy.scrollWidth !== refCurrentCopy.clientWidth,
        });
      }
    };

    if (refCurrentCopy) {
      refCurrentCopy.addEventListener('scroll', handler, {
        capture: false,
        passive: true,
      });
    }

    return () => {
      if (refCurrentCopy) {
        refCurrentCopy.removeEventListener('scroll', handler);
      }
    };
  }, [ref, setScrollState]);

  return [scrollState, handleScroll];
};
