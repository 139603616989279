import { useContext, useEffect } from 'react';
import { Layout } from '../../../components/layout/AuthLayout';
import { Title } from '../../../components/ui/Title';
import { AUTH } from '../../../helpers/constants/Routes';
import { AuthContext } from '../../../store/repository/AuthContext';
import { RedirectContext } from '../../../store/repository/RedirectContext';

export const LogoutPage: React.VFC = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const { logoutMessage, redirectHandler, setLogoutMessage } = useContext(RedirectContext);

  useEffect(() => {
    (async () => {
      if (isAuthenticated()) {
        await logout()
          .then(() => {
            redirectHandler(AUTH.LOGIN);
            if (!logoutMessage) setLogoutMessage('ログアウトしました。');
          })
          .catch(() => {
            redirectHandler(AUTH.LOGIN);
            if (!logoutMessage) setLogoutMessage('ログアウトしました。');
          });
      } else {
        if (!logoutMessage) setLogoutMessage('ログアウトしました。');
        redirectHandler(AUTH.LOGIN);
      }
    })();
  }, []);

  return (
    <>
      <Title title="ログアウト" />
      <Layout loading>{null}</Layout>
    </>
  );
};
