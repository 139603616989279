import type { AxiosResponse } from 'axios';
import { type UseQueryOptions, type UseQueryResult, useQuery } from 'react-query';
import { ERROR } from '../../helpers/constants/ErrorMessages';
import { QUERY } from '../../helpers/constants/api';
import { convertToDate } from '../../helpers/convertToDate';
import { useApiResponseHandler } from '../../helpers/hooks/useApiResponseHandler';
import type { AxiosApiError, FetchUserResponse } from '../../types';
import type { ConvertDate } from '../../utilityTypes';
import { mgmtAxios } from './share/axios';

type FnData = FetchUserResponse;
type Data = ConvertDate<FetchUserResponse['user']>;
type UseFetchUser = (
  userId: number,
  options?: UseQueryOptions<FnData, AxiosApiError, Data, (string | number)[]>,
) => UseQueryResult<Data, AxiosApiError>;

export const useFetchUser: UseFetchUser = (userId, options) => {
  const { handleError } = useApiResponseHandler();
  const queryFn = async () => {
    const res: AxiosResponse<FnData> = await mgmtAxios.get(`/users/${userId}`);

    return res.data;
  };

  return useQuery([QUERY.USERS, 'detail', userId], queryFn, {
    onError: (err: AxiosApiError) => {
      handleError(ERROR.FETCH_USER, err.response?.data ?? undefined);
    },
    select: res => ({
      ...res.user,
      createdAt: convertToDate(res.user.createdAt),
      updatedAt: convertToDate(res.user.updatedAt),
    }),
    ...options,
  });
};
