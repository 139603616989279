import { type DrawerProps, Drawer as MuiDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { DRAWER_WIDTH } from '../../../helpers/constants/layout';

export type DrawerType = 'elastic' | 'temporary' | 'permanent';

interface StyleProps {
  drawerShrinkWidth: number;
}
interface Props extends StyleProps {
  drawerType: DrawerType;
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    border: 0,
  },
  elasticDrawerExpanded: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  elasticDrawerShrunken: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: ({ drawerShrinkWidth }: StyleProps) => drawerShrinkWidth,
  },
}));

export const ResponsiveDrawer: React.FC<Props & DrawerProps> = ({
  drawerType,
  drawerShrinkWidth,
  open,
  ...drawerProps
}) => {
  const classes = useStyles({ drawerShrinkWidth });
  const variant = drawerType === 'temporary' ? 'temporary' : 'permanent';

  return (
    <MuiDrawer
      variant={variant}
      classes={{
        root: clsx(
          classes.drawer,
          drawerType === 'elastic' &&
            (open ? classes.elasticDrawerExpanded : classes.elasticDrawerShrunken),
        ),
        paper: clsx(
          classes.drawerPaper,
          drawerType === 'elastic' &&
            (open ? classes.elasticDrawerExpanded : classes.elasticDrawerShrunken),
        ),
      }}
      open={variant === 'permanent' ? true : open}
      {...drawerProps}
    />
  );
};
