import { AUTH, MGMT } from '../helpers/constants/Routes';
import { ConfirmForgotPasswordPage } from '../pages/auth/ConfirmForgotPassword/ConfirmForgotPasswordPage';
import { ForgotPasswordPage } from '../pages/auth/ForgotPassword/ForgotPasswordPage';
import { LoginPage } from '../pages/auth/Login/LoginPage';
import { LogoutPage } from '../pages/auth/Logout/LogoutPage';
import { OfficeSelectPage } from '../pages/auth/OfficeSelect/OfficeSelectPage';
import { AdminMgmtUserPage } from '../pages/mgmt/adminMgmtUser/AdminMgmtUserPage';
import { AuthErrorPage } from '../pages/mgmt/authError/AuthErrorPage';
import { OfficesPage } from '../pages/mgmt/offices/OfficesPage';
import { UserCreatePage } from '../pages/mgmt/userCreate/UserCreatePage';
import { UserEditPage } from '../pages/mgmt/userEdit/UserEditPage';
import { UserLogsPage } from '../pages/mgmt/userLogs/UserLogsPage';
import { UsersPage } from '../pages/mgmt/users/UsersPage';
import { UsersOfficeEditPage } from '../pages/mgmt/usersOfficeEdit/UsersOfficeEditPage';
import { MgmtRedirectToUsers } from './MgmtRedirectToUsers';

const mgmtRoutes = [
  {
    name: MGMT.AUTH_ERROR,
    path: `/${MGMT.AUTH_ERROR}`,
    component: AuthErrorPage,
    protected: true,
    exact: true,
  },
  {
    name: MGMT.REDIRECT_TO_USERS,
    path: `/${MGMT.REDIRECT_TO_USERS}`,
    component: MgmtRedirectToUsers,
    protected: true,
    exact: true,
  },
  {
    name: MGMT.USERS,
    path: `/${MGMT.USERS}`,
    component: UsersPage,
    protected: true,
    exact: true,
  },
  {
    name: MGMT.USER_CREATE,
    path: `/${MGMT.USER_CREATE}`,
    component: UserCreatePage,
    protected: true,
  },
  {
    name: MGMT.USER_EDIT,
    path: `/${MGMT.USER_EDIT}/:userId`,
    component: UserEditPage,
    protected: true,
  },
  {
    name: MGMT.USER_OFFICE_EDIT,
    path: `/${MGMT.USER_OFFICE_EDIT}`,
    component: UsersOfficeEditPage,
    protected: true,
  },
  {
    name: MGMT.OFFICES,
    path: `/${MGMT.OFFICES}`,
    component: OfficesPage,
    protected: true,
  },
  {
    name: MGMT.USER_LOGS,
    path: `/${MGMT.USER_LOGS}`,
    component: UserLogsPage,
    protected: true,
  },
  {
    name: MGMT.ADMIN_MGMT_USERS,
    path: `/${MGMT.ADMIN_MGMT_USERS}`,
    component: AdminMgmtUserPage,
    protected: true,
  },
];

const authRoutes = [
  {
    name: AUTH.LOGIN,
    path: `/${AUTH.LOGIN}/:redirect?`,
    component: LoginPage,
    protected: false,
  },
  {
    name: AUTH.LOGOUT,
    path: `/${AUTH.LOGOUT}/:redirect?`,
    component: LogoutPage,
    protected: false,
  },
  {
    name: AUTH.OFFICE_LIST,
    path: `/${AUTH.OFFICE_LIST}/:redirect?`,
    component: OfficeSelectPage,
    protected: true,
  },
  {
    name: AUTH.FORGOT_PASSWORD,
    path: `/${AUTH.FORGOT_PASSWORD}/:redirect?`,
    component: ForgotPasswordPage,
    protected: false,
  },
  {
    name: AUTH.CONFIRM_FORGOT_PASSWORD,
    path: `/${AUTH.CONFIRM_FORGOT_PASSWORD}/:redirect?`,
    component: ConfirmForgotPasswordPage,
    protected: false,
  },
  {
    name: 'Default',
    path: '/',
    component: LoginPage,
    protected: false,
  },
];

export const routes = [...mgmtRoutes, ...authRoutes];
