import {
  Divider,
  Table as MuiTable,
  Paper,
  type TableCellProps,
  TableContainer,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { HeaderGroup, Row, TableProps } from 'react-table';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
import { TablePagination } from './TablePagination';

interface Props<TTableData extends Record<string, unknown>> {
  headerGroups: HeaderGroup<TTableData>[];
  rows: Row<TTableData>[];
  prepareRow: (row: Row<TTableData>) => void;
  selectedRowIds?: Record<string, boolean>;
  pageIndex?: number;
  totalCount: number;
  handleChangePageIndex?: (_: unknown, newPageIndex: number) => void;
  tableProps: TableProps;
  tableCellProps?: TableCellProps;
  isLoading?: boolean;
  noDataMessage?: string;
}

const TABLE_FOOTER_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
  tableContainerWrapper: {
    position: 'relative',
    height: '100%',
  },
  tableContainer: {
    position: 'absolute',
    width: '100%',
    height: ({ isShowFooter }: { isShowFooter: boolean }) =>
      isShowFooter ? `calc(100% - ${TABLE_FOOTER_HEIGHT}px)` : '100%',
    backgroundColor: theme.palette.common.white,
    overflowY: 'auto',
    scrollSnapType: 'y',
  },
  tableFooter: {
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    height: TABLE_FOOTER_HEIGHT,
    // borderの重なりを相殺する、borderCollapse: 'collapse'の代用
    boxShadow: `0px -1px 0 1px ${theme.palette.common.white}`,
  },
  selectionAndPagination: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
  },
  pagination: {
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

/**
 * TableDataのkeyは、Checkboxの場合は'selection'を、EditButtonの場合は'edit'を設定する
 */
export const Table = <TTableData extends Record<string, unknown>>({
  headerGroups,
  rows,
  prepareRow,
  selectedRowIds,
  pageIndex,
  totalCount,
  handleChangePageIndex,
  tableProps,
  tableCellProps,
  isLoading,
  noDataMessage,
}: Props<TTableData>): React.ReactElement => {
  const isShowFooter = pageIndex !== undefined;
  const classes = useStyles({ isShowFooter });
  const selectedItemCount = selectedRowIds
    ? Object.values(selectedRowIds).filter(bool => bool).length
    : 0;

  return (
    <div className={classes.tableContainerWrapper}>
      <TableContainer className={classes.tableContainer} component={Paper} elevation={0}>
        <MuiTable stickyHeader {...tableProps}>
          <TableHead headerGroups={headerGroups} />
          <TableBody
            rows={rows}
            prepareRow={prepareRow}
            isLoading={isLoading}
            tableCellProps={tableCellProps}
            noDataMessage={rows.length === 0 ? noDataMessage : undefined}
          />
        </MuiTable>
      </TableContainer>
      {isShowFooter && handleChangePageIndex && (
        <div className={classes.tableFooter}>
          <Divider />
          <div className={classes.selectionAndPagination}>
            <Typography variant="body2">
              {selectedItemCount > 0 && `${selectedItemCount}件選択中`}
            </Typography>
            <TablePagination
              totalCount={totalCount}
              pageIndex={pageIndex}
              handleChangePageIndex={handleChangePageIndex}
            />
          </div>
        </div>
      )}
    </div>
  );
};
