type OmitOptionalPropertiesReturnType<T> = Pick<
  T,
  { [Key in keyof T]: T[Key] extends undefined ? never : Key }[keyof T]
>;

export const omitOptionalProperties = <TObj extends Record<string, any>>(
  obj: TObj,
): OmitOptionalPropertiesReturnType<TObj> => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  const newObj: OmitOptionalPropertiesReturnType<TObj> = obj;

  return newObj;
};
