import { makeStyles } from '@material-ui/core/styles';
import type { TableInstance } from 'react-table';
import { Button } from '../../../components/ui/Button/Button';
import { PageTitle } from '../../../components/ui/PageTitle/PageTitle';
import { SearchTextField } from '../../../components/ui/SearchTextField/SearchTextField';
import { Stack } from '../../../components/ui/Stack/Stack';
import { Table } from '../../../components/ui/Table/Table';
import { MGMT } from '../../../helpers/constants/Routes';
import { SEARCH_TEXT_FIELD_WIDTH } from '../../../helpers/constants/layout';
import { USER_TYPES } from '../../../helpers/constants/user';
import { useDeleteConfirm } from '../../../helpers/hooks/useDeleteConfirm';
import { useRouter } from '../../../helpers/hooks/useRouter';
import { convertSelectedRowIds } from '../../../helpers/utils/convertSelectedRowIds';
import type { UsersHandlers, UsersState } from '../../../store/api/useFetchUsers';
import type { FetchManagerResponse, UsersTableData } from '../../../types';
import { OfficeFilter } from '../shared/OfficeFilter';

interface Props {
  loginUser: FetchManagerResponse['user'];
  usersTable: TableInstance<UsersTableData>;
  usersState: UsersState;
  usersHandlers: UsersHandlers;
  handleDelete: () => void;
  totalCount: number;
  status: {
    isLoading: boolean;
    isDeleting: boolean;
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridAutoRows: 'auto 1fr',
    height: '100%',
  },
  searchAndFilterWrapper: {
    overflow: 'hidden',
  },
  input: {
    backgroundColor: theme.palette.common.white,
  },
  searchAndButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: theme.spacing(3), // windowの幅が狭いとき
  },
  searchTextField: {
    flexShrink: 1,
    flexBasis: SEARCH_TEXT_FIELD_WIDTH,
  },
  buttonsWrapper: {
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    columnGap: theme.spacing(1),
  },
  indeterminateCheckbox: {
    color: theme.palette.primary.main,
  },
}));

export const UsersTemplate: React.VFC<Props> = ({
  loginUser,
  usersTable,
  usersState,
  usersHandlers,
  handleDelete,
  totalCount,
  status,
}) => {
  const classes = useStyles();
  const { history } = useRouter<{ userIds: number[]; pageIndex: number }>();
  const {
    headerGroups,
    rows,
    prepareRow,
    getTableProps,
    state: { selectedRowIds },
  } = usersTable;
  const tableProps = getTableProps();
  const selectedUserIds = convertSelectedRowIds.toArray(selectedRowIds);

  // delete
  const [DeleteConfirmDialog, handleOpen] = useDeleteConfirm();
  const alertMessage =
    selectedUserIds.length > 10
      ? '10人以上同時にアカウントを削除することはできないため、選択したユーザーを10人以下にしてください'
      : undefined;

  return (
    <>
      <div className={classes.root}>
        <Stack className={classes.searchAndFilterWrapper} spacing={2} mb={3}>
          <PageTitle paragraph>ユーザー一覧</PageTitle>
          <div className={classes.searchAndButtonsWrapper}>
            <SearchTextField
              className={classes.searchTextField}
              value={usersState.keyword}
              onChange={usersHandlers.handleInputKeyword}
              handleClear={usersHandlers.handleClearKeyword}
            />
            {selectedUserIds.length > 0 && (
              <div className={classes.buttonsWrapper}>
                {loginUser.userType === USER_TYPES.GROUPMANAGER && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      history.push(`/${MGMT.USER_OFFICE_EDIT}`, {
                        userIds: selectedUserIds,
                        pageIndex: usersState.pageIndex,
                      })
                    }
                  >
                    所属クリニック編集
                  </Button>
                )}
                <Button variant="text" color="danger" onClick={() => handleOpen(alertMessage)}>
                  アカウント削除
                </Button>
              </div>
            )}
          </div>
          {loginUser.userType === USER_TYPES.GROUPMANAGER && (
            <OfficeFilter
              offices={loginUser.offices}
              officeIds={usersState.officeIds}
              toggleOfficeId={usersHandlers.handleToggleOfficeId}
              clearOfficeIds={usersHandlers.handleClearOfficeIds}
            />
          )}
        </Stack>
        <Table
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          selectedRowIds={selectedRowIds}
          pageIndex={usersState.pageIndex}
          totalCount={totalCount}
          handleChangePageIndex={usersHandlers.handleChangePageIndex}
          tableProps={tableProps}
          isLoading={status.isLoading}
          noDataMessage="条件に一致するユーザーは見つかりませんでした。"
        />
      </div>
      <DeleteConfirmDialog
        title="選択したユーザーを削除しますか？"
        message="ユーザーを削除すると、ユーザーに関する情報が全て削除され元に戻せなくなります。"
        handleDelete={handleDelete}
      />
    </>
  );
};
