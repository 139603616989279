import type { FetchUsersResponse, UsersTableData } from '../../types';

export const convertUsersForUsersTable = (data: FetchUsersResponse): UsersTableData[] =>
  data.users.map(user => ({
    id: user.id,
    username: {
      kana: user.kana,
      name: user.name,
    },
    // \u00A0はノーブレークスペース
    office: user.offices.map(office => office.name).join(',\u00A0\u00A0'),
    email: user.email,
  }));
