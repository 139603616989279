import type { AdminMgmtUsersTableData, UserLogsTableData, UsersTableData } from '../../types';
import { formatDate } from '../formatDate';

export const initialUserLogsTableData: UserLogsTableData[] = [
  {
    userInformation: { name: '', email: '' },
    officeName: '',
    ipAddress: '',
    activity: 'Login',
    createdAt: formatDate(new Date(), 'dateAndTime'),
  },
];

export const initialUsersTableData: UsersTableData[] = [
  {
    id: -1,
    username: {
      kana: '',
      name: '',
    },
    office: '',
    email: '',
  },
];

export const initialAdminMgmtUsersTableData: AdminMgmtUsersTableData[] = [
  {
    userId: -1,
    userType: undefined,
    managerName: '',
    officeName: '',
    email: '',
  },
];
