export const USER_TYPES = {
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  GROUPMANAGER: 'GroupManager',
  ACCOUNTANTS: 'Accountants',
  DEMO: 'Demo',
  NORMAL: 'Normal',
} as const;

export type UserType = (typeof USER_TYPES)[keyof typeof USER_TYPES];
