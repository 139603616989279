import styled from '@emotion/styled';

type Flex = {
  direction?: string;
  flex?: number;
  grow?: number;
  shrink?: string;
  basis?: string;
  wrap?: string;
  justify?: string;
  align?: string;
  aligns?: string;
  width?: string;
  height?: string;
};
export const Flex = styled.div<Flex>`
  align-content: ${props => props.align};
  align-items: ${props => props.aligns};
  display: flex;
  flex: ${props => props.flex};
  flex-basis: ${props => props.basis};
  flex-direction: ${props => props.direction};
  flex-grow: ${props => props.grow};
  flex-shrink: ${props => props.shrink};
  flex-wrap: ${props => props.wrap};
  height: ${props => props.height};
  justify-content: ${props => props.justify};
  width: ${props => props.width};
`;
