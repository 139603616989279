import {
  type ButtonTypeMap,
  Button as MuiButton,
  type ButtonProps as MuiButtonProps,
  alpha,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export type ButtonProps<
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
> = {
  color?: MuiButtonProps<D, P>['color'] | 'danger';
} & Omit<MuiButtonProps<D, P>, 'color'>;

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, theme.palette.action.hoverOpacity),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  outlined: {
    color: theme.palette.error.main,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: alpha(theme.palette.error.main, theme.palette.action.hoverOpacity),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  contained: {
    backgroundColor: theme.palette.error.dark,
    '@media (hover: none)': {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

export const Button = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>({
  children,
  ...buttonProps
}: ButtonProps<D, P>): React.ReactElement => {
  const classes = useStyles();
  const { color, ...restButtonProps } = buttonProps;

  if (color === 'danger') {
    return (
      <MuiButton
        classes={{
          text: clsx(classes.text, buttonProps?.classes?.text),
          outlined: clsx(classes.outlined, buttonProps?.classes?.outlined),
          contained: clsx(classes.contained, buttonProps?.classes?.contained),
        }}
        {...restButtonProps}
      >
        {children}
      </MuiButton>
    );
  }

  return <MuiButton {...buttonProps}>{children}</MuiButton>;
};
