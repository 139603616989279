import { Box, Button, Grid, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Layout } from '../../../components/layout/AuthLayout';
import { AUTH } from '../../../helpers/constants/Routes';

interface Props {
  success: boolean;
  query: string;
  onPasswordSubmit: () => void;
  confirmationCode: string;
  onConfirmationCodeChange: (newValue: string) => void;
  password: string;
  onPasswordChange: (newValue: string) => void;
  confirmPassword: string;
  onConfirmPasswordChange: (newValue: string) => void;
  errorMessage: string;
}

const useStyles = makeStyles({
  passwordTextField: {
    marginBottom: '3vh',
  },
  inputLabel: {
    color: '#000',
  },
});

export const ConfirmForgotPasswordTemplate: React.VFC<Props> = ({
  success,
  query,
  onPasswordSubmit,
  confirmationCode,
  onConfirmationCodeChange,
  password,
  onPasswordChange,
  confirmPassword,
  onConfirmPasswordChange,
  errorMessage,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const baseTextFieldProps = {
    className: classes.passwordTextField,
    size: 'small',
    InputLabelProp: {
      className: classes.inputLabel,
    },
    inputProps: {
      autocomplete: 'new-password',
    },
    fullWidth: true,
    variant: 'outlined',
  } as const;

  if (success) {
    return (
      <>
        <Box my="1.5rem">
          <Typography color="textSecondary" variant="body1">
            パスワードを変更しました。新しいパスワードでログインしてください。
          </Typography>
        </Box>
        <Box my="4vh">
          <Link
            onClick={() => {
              if (query) {
                history.push(`/${AUTH.LOGIN}/${query}`);
              } else {
                history.push(`/${AUTH.LOGIN}`);
              }
            }}
            color="primary"
            variant="body1"
            style={{ cursor: 'pointer' }}
          >
            ログイン画面へ
          </Link>
        </Box>
      </>
    );
  }

  return (
    <Layout loading={false} up="11.5vh">
      <Box my="0.5rem" display="flex" justifyContent="center">
        <Typography color="textSecondary" variant="body1" style={{ maxWidth: '90%' }}>
          メールで送信された6桁の確認コードを入力してください。
        </Typography>
      </Box>
      <Box my="0.5rem" display="flex" justifyContent="center">
        <Typography color="textSecondary" variant="body1" style={{ maxWidth: '90%' }}>
          8〜12文字の半角英数字で、新しいパスワードを設定してください。
        </Typography>
      </Box>
      <Box my="3vh">
        <Typography color="error">{errorMessage || null}</Typography>
      </Box>
      <Grid container justifyContent="center" direction="column">
        <form
          onSubmit={e => {
            e.preventDefault();
            onPasswordSubmit();
          }}
        >
          <Grid container justifyContent="center">
            <Box width="300px">
              <TextField
                {...baseTextFieldProps}
                label="確認コード"
                type="text"
                value={confirmationCode}
                onChange={e => onConfirmationCodeChange(e.target.value)}
              />
              <TextField
                {...baseTextFieldProps}
                label="新しいパスワード"
                type="password"
                value={password}
                onChange={e => onPasswordChange(e.target.value)}
              />
              <TextField
                {...baseTextFieldProps}
                label="新しいパスワード（確認）"
                value={confirmPassword}
                type="password"
                onChange={e => onConfirmPasswordChange(e.target.value)}
              />
            </Box>
          </Grid>
          <Box mb="3vh">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              style={{ width: '42%', borderRadius: '20px', padding: '12px' }}
            >
              <Typography variant="body1">パスワードを設定</Typography>
            </Button>
            <Box my="4vh">
              <Link
                onClick={() => {
                  if (query) {
                    history.push(`/${AUTH.LOGIN}/${query}`);
                  } else {
                    history.push(`/${AUTH.LOGIN}`);
                  }
                }}
                color="primary"
                variant="body1"
                style={{ cursor: 'pointer' }}
              >
                キャンセル
              </Link>
            </Box>
          </Box>
        </form>
      </Grid>
    </Layout>
  );
};
