import { Button, type ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { SvgIconComponent } from '@material-ui/icons';
import clsx from 'clsx';

const MUI_LARGE_BUTTON_HEIGHT = 56;

interface Props {
  isExpanded: boolean;
  Icon: SvgIconComponent;
  onClick: () => void;
  buttonProps?: Exclude<ButtonProps, 'onClick' | 'size'>;
}

const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: MUI_LARGE_BUTTON_HEIGHT,
    width: MUI_LARGE_BUTTON_HEIGHT,
    minWidth: 'auto',
    fontWeight: 500,
    borderRadius: MUI_LARGE_BUTTON_HEIGHT / 2,
  },
  expandedButton: {
    width: 'auto',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  startIcon: {
    marginLeft: -14,
  },
}));

export const ExpandableButton: React.FC<Props> = ({
  children,
  isExpanded,
  Icon,
  onClick,
  buttonProps,
}) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.button, isExpanded && classes.expandedButton)}
      classes={{ startIcon: classes.startIcon }}
      variant="contained"
      color="secondary"
      size="large"
      startIcon={isExpanded ? <Icon style={{ fontSize: 40 }} /> : null}
      onClick={onClick}
      {...buttonProps}
    >
      {isExpanded ? children : <Icon style={{ fontSize: 40 }} />}
    </Button>
  );
};
