import { Box, Grid, Typography } from '@material-ui/core';
import { useCallback, useContext, useReducer } from 'react';
import { Loader } from '../../../components/ui';
import { Title } from '../../../components/ui/Title';
import { ErrorMessages } from '../../../helpers/utils/ApiErrors';
import { ConfirmForgotPasswordUser } from '../../../store/api/ApiService';
import { ConfirmForgotPasswordValidator } from '../../../store/models/Validators';
import { RedirectContext } from '../../../store/repository/RedirectContext';
import { ConfirmForgotPasswordTemplate } from './ConfirmForgotPasswordTemplate';

interface State {
  confirmationCode: string;
  password: string;
  confirmPassword: string;
  success: boolean;
  errorMessage: string;
  loading: boolean;
}

type Action =
  | {
      type: 'inputConfirmationCode';
      payload: { confirmationCode: string };
    }
  | {
      type: 'inputPassword';
      payload: { password: string };
    }
  | {
      type: 'inputConfirmPassword';
      payload: { confirmPassword: string };
    }
  | {
      type: 'submitStart';
    }
  | {
      type: 'validateSuccess';
    }
  | {
      type: 'validateFailure';
      payload: { errorMessage: string };
    }
  | {
      type: 'requestSuccess';
    }
  | {
      type: 'requestFailure';
      payload: { errorMessage: string };
    };

const initialState = {
  confirmationCode: '',
  password: '',
  confirmPassword: '',
  success: false,
  errorMessage: '',
  loading: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // ---------- input ----------
    case 'inputConfirmationCode':
      return {
        ...state,
        confirmationCode: action.payload.confirmationCode,
      };
    case 'inputPassword':
      return {
        ...state,
        password: action.payload.password,
      };
    case 'inputConfirmPassword':
      return {
        ...state,
        confirmPassword: action.payload.confirmPassword,
      };
    // ---------- submit ----------
    case 'submitStart':
      return {
        ...state,
        loading: true,
      };
    case 'validateSuccess':
      return {
        ...state,
        errorMessage: '',
      };
    case 'validateFailure':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case 'requestSuccess':
      return {
        ...state,
        password: '',
        confirmPassword: '',
        success: true,
        errorMessage: '',
        loading: false,
      };
    case 'requestFailure':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };

    default:
      return state;
  }
};

export const ConfirmForgotPasswordPage: React.VFC = () => {
  const [
    { confirmationCode, password, confirmPassword, success, errorMessage, loading },
    dispatch,
  ] = useReducer<React.Reducer<State, Action>>(reducer, initialState);
  const { query } = useContext(RedirectContext);

  const handleConfirmForgotPasswordUser = async () => {
    await ConfirmForgotPasswordUser({ password, confirmationCode })
      .then(() => {
        dispatch({ type: 'requestSuccess' });
      })
      .catch((err: Error) => {
        dispatch({ type: 'requestFailure', payload: { errorMessage: ErrorMessages(err.message) } });
      });
  };
  const handlePasswordSubmit = () => {
    dispatch({ type: 'submitStart' });

    const validationError = ConfirmForgotPasswordValidator({
      confirmationCode,
      password,
      confirmPassword,
    });

    if (validationError) {
      dispatch({ type: 'validateFailure', payload: { errorMessage: validationError } });

      return;
    }

    dispatch({ type: 'validateSuccess' });
    void handleConfirmForgotPasswordUser();
  };

  const handleConfirmationCodeChange = useCallback((newValue: string) => {
    dispatch({
      type: 'inputConfirmationCode',
      payload: { confirmationCode: newValue },
    });
  }, []);

  const handlePasswordChange = useCallback((newValue: string) => {
    dispatch({
      type: 'inputPassword',
      payload: { password: newValue },
    });
  }, []);

  const handleConfirmPasswordChange = useCallback((newValue: string) => {
    dispatch({
      type: 'inputConfirmPassword',
      payload: { confirmPassword: newValue },
    });
  }, []);

  return (
    <>
      <Title title="パスワードを忘れた方" />
      <Box mt="1em" mb="1rem">
        <Grid container justifyContent="center">
          <Box>
            <Box my="2vh">
              <Typography color="textSecondary" variant="h4">
                パスワードの再設定
              </Typography>
            </Box>
            {loading ? (
              <Box mt="0vh" mb="10vh">
                <Loader />
              </Box>
            ) : (
              <ConfirmForgotPasswordTemplate
                success={success}
                query={query}
                onPasswordSubmit={handlePasswordSubmit}
                confirmationCode={confirmationCode}
                onConfirmationCodeChange={handleConfirmationCodeChange}
                password={password}
                onPasswordChange={handlePasswordChange}
                confirmPassword={confirmPassword}
                onConfirmPasswordChange={handleConfirmPasswordChange}
                errorMessage={errorMessage}
              />
            )}
          </Box>
        </Grid>
      </Box>
    </>
  );
};
