import styled from '@emotion/styled';
import { Link, NavLink } from 'react-router-dom';
import { ds } from '../theme/authTheme/ds';

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    border-bottom: 1px solid ${ds.brand('light')};
    color: ${ds.brand('light')};
  }

  &.secondary {
    color: ${ds.brand('white')};
  }

  &.no-hover {
    &:hover {
      border: none;
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  border-bottom: 1px solid transparent;
  color: inherit;
  text-decoration: none;
  text-indent: 12px;

  &:hover {
    border-bottom: 1px solid ${ds.brand('main')};
  }

  &.secondary {
    color: ${ds.brand('white')};

    &:hover {
      border: none;
    }
  }
`;

export const StyledLinkOutSideRouter = styled.a`
  color: inherit;
  text-decoration: none;
`;
