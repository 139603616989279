import type { AlertProps } from '@material-ui/lab';
import { produce } from 'immer';
import { createReducerContext } from '../../helpers/factory/createReducerContext';

interface State {
  isOpen: boolean;
  severity: AlertProps['severity'] | undefined;
  message: string;
}

interface Close {
  type: 'close';
}

interface Open {
  type: 'open';
  payload: {
    severity?: AlertProps['severity'];
    message: string;
  };
}

type Action = Close | Open;

const initialState: State = {
  isOpen: false,
  severity: undefined,
  message: '',
};

export const reducer = produce((draft: State, action: Action): void => {
  switch (action.type) {
    case 'close':
      draft.isOpen = initialState.isOpen;
      draft.severity = initialState.severity;
      draft.message = initialState.message;

      return;
    case 'open':
      draft.isOpen = true;
      draft.severity = action.payload.severity;
      draft.message = action.payload.message;

      return;
    default:
      throw new Error('Invalid action passed to snackbar dispatch');
  }
});

export const [useSnackbar, SnackbarProvider] = createReducerContext<typeof reducer>(
  reducer,
  initialState,
);
