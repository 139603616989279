import styled from '@emotion/styled';
import { Box, makeStyles } from '@material-ui/core';
import type { FC } from 'react';
import logo from '../../assets/logo.png';
import { ds } from '../theme/authTheme/ds';
import { Loader, Text } from '../ui';

const useStyles = makeStyles({
  loaderWrapper: {
    minHeight: '50.5vh',
  },
});

type LayoutProps = {
  children: React.ReactNode;
  loading: boolean;
  up?: string;
};

export const Layout: FC<LayoutProps> = ({ children, loading, up }) => {
  const classes = useStyles();

  return (
    <Box m="0 1vw" p="5vh 0" style={{ textAlign: 'center' }}>
      <ApotoolLogo src={logo} alt="apotool-logo" style={{ margin: '2em auto auto auto' }} />
      {loading ? (
        <Box mt="25vh" className={classes.loaderWrapper}>
          <Loader />
        </Box>
      ) : (
        <Box style={{ minHeight: up ? `calc(75.5vh - ${up})` : '75.5vh' }}>{children}</Box>
      )}
      <Text fs="0.75rem">© Stransa Co., Ltd. All rights reserved.</Text>
    </Box>
  );
};

const ApotoolLogo = styled.img`
  @media (max-width: ${ds.bp('sm')}) {
    width: 70%;
  }
`;
