import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

type StepProp = {
  theme?: { main: string };
};
export const Step = styled.li<StepProp>`
  border: 2px solid ${ds.brand('main')};
  border-radius: 100%;
  color: ${ds.brand('main')};
  font-size: 14px;
  height: 25px;
  line-height: 22px;
  list-style: none;
  position: relative;
  text-align: center;
  width: 25px;

  &.completed {
    background: ${ds.brand('main')};

    &::after {
      color: ${ds.brand('white')};
      content: '✔';
      display: block;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &.theme {
    border: 2px solid ${props => props.theme.main};
    color: ${props => props.theme.main};

    &.completed {
      background: ${props => props.theme.main};
    }
  }
`;
