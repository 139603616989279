import {
  type UseMutationOptions,
  type UseMutationResult,
  useMutation,
  useQueryClient,
} from 'react-query';
import { ERROR } from '../../helpers/constants/ErrorMessages';
import { QUERY } from '../../helpers/constants/api';
import { useApiResponseHandler } from '../../helpers/hooks/useApiResponseHandler';
import type { AxiosApiError, SelectAccountVariables } from '../../types';
import { mgmtAxios } from './share/axios';

type Variables = SelectAccountVariables;
type Data = unknown;
type UseSelectAccount = (
  options?: UseMutationOptions<Data, AxiosApiError, Variables>,
) => UseMutationResult<Data, AxiosApiError, Variables>;

export const useSelectAccount: UseSelectAccount = options => {
  const { handleError, handleSuccess } = useApiResponseHandler();
  const queryClient = useQueryClient();
  const mutationFn = (variables: Variables) =>
    mgmtAxios.post(`/admin/mgmt-users/${variables.userId}`);

  return useMutation(mutationFn, {
    onError: (err: AxiosApiError) => {
      handleError(ERROR.SELECT_ACCOUNT, err.response?.data ?? undefined);
    },
    onSettled: (_, err) => {
      if (!err) {
        handleSuccess('アカウントの選択に成功しました');
      }

      return Promise.all([
        queryClient.invalidateQueries([QUERY.USERS]),
        queryClient.invalidateQueries([QUERY.OFFICES]),
        queryClient.invalidateQueries([QUERY.USER_LOGS]),
        queryClient.invalidateQueries([QUERY.MANAGER]),
        queryClient.invalidateQueries([QUERY.MGMT_USERS]),
      ]);
    },
    ...options,
  });
};
