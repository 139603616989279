import {
  type UseMutationOptions,
  type UseMutationResult,
  useMutation,
  useQueryClient,
} from 'react-query';
import { ERROR } from '../../helpers/constants/ErrorMessages';
import { QUERY } from '../../helpers/constants/api';
import { useApiResponseHandler } from '../../helpers/hooks/useApiResponseHandler';
import { omitOptionalProperties } from '../../helpers/omitOptionalProperties';
import type { AxiosApiError, CreateUserVariables } from '../../types';
import { mgmtAxios } from './share/axios';

type Variables = CreateUserVariables;
type Data = unknown;
type UseCreateUser = (
  options?: UseMutationOptions<Data, AxiosApiError, Variables>,
) => UseMutationResult<Data, AxiosApiError, Variables>;

export const useCreateUser: UseCreateUser = options => {
  const { handleError, handleSuccess } = useApiResponseHandler();
  const queryClient = useQueryClient();
  const mutationFn = (variables: Variables) =>
    mgmtAxios.post('/users', omitOptionalProperties(variables));

  return useMutation(mutationFn, {
    onError: (err: AxiosApiError) => {
      handleError(ERROR.CREATE_USER, err.response?.data ?? undefined);
    },
    onSettled: (_, err) => {
      if (!err) {
        handleSuccess('新しくユーザーを登録しました');
      }

      return queryClient.invalidateQueries([QUERY.USERS]);
    },
    ...options,
  });
};
