import { Typography } from '@material-ui/core';

interface Props {
  ruby: string;
  text: string;
}

export const TextWithRuby: React.VFC<Props> = ({ ruby, text }) => (
  <div>
    <Typography component="ruby" variant="caption" color="textSecondary" noWrap>
      {ruby}
    </Typography>
    <Typography variant="body2" noWrap>
      {text}
    </Typography>
  </div>
);
