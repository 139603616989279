import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

export const useLocalStorage = <T,>(
  key: string,
  initialValue?: T,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] => {
  const deserializer = JSON.parse;
  const serializer = JSON.stringify;

  const initializer = useRef((initialKey: string) => {
    try {
      const localStorageValue = localStorage.getItem(initialKey);
      if (localStorageValue !== null) {
        return deserializer(localStorageValue);
      }
      if (initialValue) {
        localStorage.setItem(initialKey, serializer(initialValue));
      }

      return initialValue;
    } catch {
      return initialValue;
    }
  });

  const [state, setState] = useState<T | undefined>(() => initializer.current(key));

  useLayoutEffect(() => setState(initializer.current(key)), [key]);

  const set: Dispatch<SetStateAction<T | undefined>> = useCallback(
    valueOrFunction => {
      try {
        const value =
          typeof valueOrFunction === 'function'
            ? (valueOrFunction as (arg: T | undefined) => T | undefined)(state)
            : valueOrFunction;
        localStorage.setItem(key, serializer(value));
        setState(value);
      } catch {
        if (process.env.NODE_ENV !== 'production') {
          throw new Error('localStorageへの書き込みに失敗しました');
        }
      }
    },
    [key, serializer, state],
  );

  const remove = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setState(undefined);
    } catch {
      if (process.env.NODE_ENV !== 'production') {
        throw new Error('localStorageからの削除に失敗しました');
      }
    }
  }, [key, setState]);

  return [state, set, remove];
};
