import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';
import { Paper } from './Paper';

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${ds.z('backdrop')};

  &.hide {
    display: none;
  }
`;

export const Modal = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  width: 100vw;
  z-index: ${ds.z('modal')};
`;

export const ModalContents = styled(Paper)`
  bottom: auto;
  height: auto;
  left: 50%;
  margin-right: -50%;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  z-index: 12000;
`;
