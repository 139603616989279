import type { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const palette: PaletteOptions = {
  primary: {
    light: '#ddf2f3',
    main: '#00b4b4',
    dark: '#008484',
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#4eb6b1',
    main: '#008682',
    dark: '#005856',
    contrastText: '#ffffff',
  },
  success: {
    light: '#58e788',
    main: '#00b45a',
    dark: '#00832e',
    contrastText: '#ffffff',
  },
  error: {
    light: '#ff6548',
    main: '#e12c1d',
    dark: '#a70000',
    contrastText: '#ffffff',
  },
  divider: '#b8b8bb',
  background: {
    default: '#f5f5f5',
  },
};
