import { useEffect } from 'react';
import WebFont from 'webfontloader';
import { Dialog } from '../../components/ui/Dialog/Dialog';
import { Snackbar } from '../../components/ui/Snackbar/Snackbar';
import { useAlertDialog } from '../../store/repository/alertDialog';
import { useSnackbar } from '../../store/repository/snackbar';

const AUTO_HIDE_DURATION = 5000;

export const AppUtility: React.VFC = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Noto+Sans+JP:wght@400;500;700'],
      },
    });
  }, []);

  const [snackbarState, snackbarDispatch] = useSnackbar();
  const [alertDialogState, alertDialogDispatch] = useAlertDialog();

  return (
    <>
      <Snackbar
        open={snackbarState.isOpen}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={() => snackbarDispatch({ type: 'close' })}
        autoHideDuration={AUTO_HIDE_DURATION}
      />
      <Dialog
        open={alertDialogState.isOpen}
        onClose={() => alertDialogDispatch({ type: 'close' })}
        title={alertDialogState.title}
        message={alertDialogState.message}
        yesLabel="OK"
        yesButtonProps={{ onClick: () => alertDialogDispatch({ type: 'close' }) }}
        noButtonProps={{ style: { display: 'none' } }}
      />
    </>
  );
};
