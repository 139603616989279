import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { ROWS_PER_PAGE } from '../../../helpers/constants/misc';
import { calcPaginationFromTo } from '../../../helpers/utils/calcPaginationFromTo';

interface Props {
  totalCount: number;
  pageIndex: number;
  handleChangePageIndex: (_: unknown, newPageIndex: number) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(3),
  },
  pagination: {
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export const TablePagination: React.FC<Props> = ({
  totalCount,
  pageIndex, // 0 始まりの page の Index
  handleChangePageIndex,
}) => {
  const classes = useStyles();
  const pageCount = Math.ceil(totalCount / ROWS_PER_PAGE);
  const page = pageIndex + 1;
  const pageToPageIndex = (p: number) => p - 1;
  const { from, to } = calcPaginationFromTo(page, totalCount);
  const tablePaginationLabel = `${from}-${to}/${totalCount}件中`;

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" variant="subtitle2">
        {tablePaginationLabel}
      </Typography>
      <Pagination
        className={classes.pagination}
        shape="rounded"
        count={pageCount}
        page={page}
        onChange={(_, newPage) => handleChangePageIndex(undefined, pageToPageIndex(newPage))}
      />
    </div>
  );
};
