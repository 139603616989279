import { useCallback, useEffect } from 'react';
import { useIsMutating } from 'react-query';
import {
  DeleteConfirm,
  type DeleteConfirmProps,
} from '../../components/ui/DeleteConfirm/DeleteConfirm';
import { useAlertDialog } from '../../store/repository/alertDialog';
import { useToggle } from './useToggle';

export type HookedDeleteConfirmProps = Omit<DeleteConfirmProps, 'open' | 'onClose' | 'isDeleting'>;
type HookedDialogType = React.VFC<HookedDeleteConfirmProps>;
type UseDialogReturnType = [
  HookedDialogType,
  (alertMessage?: string | undefined) => void,
  () => void,
];

export const useDeleteConfirm = (autoClose = true): UseDialogReturnType => {
  const isMutating = Boolean(useIsMutating());

  const [_, alertDispatch] = useAlertDialog();
  const [isOpen, handleIsOpen] = useToggle(false);
  const handleOpen = useCallback(
    (alertMessage?: string | undefined) => {
      if (alertMessage) {
        return alertDispatch({ type: 'open', payload: { title: alertMessage, message: '' } });
      }

      return handleIsOpen(true);
    },
    [alertDispatch, handleIsOpen],
  );
  const handleClose = useCallback(() => {
    handleIsOpen(false);
  }, [handleIsOpen]);
  const HookedDialog: HookedDialogType = useCallback(
    deleteConfirmProps => (
      <DeleteConfirm
        open={isOpen}
        onClose={handleClose}
        isDeleting={isMutating}
        {...deleteConfirmProps}
      />
    ),
    [isOpen, handleClose, isMutating],
  );

  useEffect(() => {
    if (autoClose && !isMutating) {
      handleClose();
    }
  }, [isMutating]);

  return [HookedDialog, handleOpen, handleClose];
};
