import { List, ListItem, ListItemIcon, ListItemText, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { RadioButtonListItem } from 'types';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export interface RadioButtonListProps {
  list: RadioButtonListItem[];
  selectedValue: string | undefined;
  handleSelect: (value: string) => void;
}

export const RadioButtonList: React.FC<RadioButtonListProps> = ({
  list,
  selectedValue,
  handleSelect,
}) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {list.map(({ label, value, helperText }) => {
        const listItemTextId = `radio-button-list-label-${value}`;

        return (
          <ListItem key={value} dense button onClick={() => handleSelect(value)}>
            <ListItemIcon>
              <Radio edge="start" color="primary" checked={value === selectedValue} disableRipple />
            </ListItemIcon>
            <ListItemText id={listItemTextId} primary={label} secondary={helperText || null} />
          </ListItem>
        );
      })}
    </List>
  );
};
