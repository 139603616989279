export const SetAuthenticationCookie: (value: string, isLogout?: boolean) => void = (
  value,
  isLogout = false,
) => {
  const expiresSnippet = isLogout ? '; expires=Thu, 01 Jan 1970 00:00:01 GMT; max-age=-1;' : '';
  document.cookie = window.location.hostname.includes('localhost')
    ? `${process.env.REACT_APP_COOKIE_AUTHENTICATED ?? ''}=${value}; path=/${expiresSnippet}`
    : `${process.env.REACT_APP_COOKIE_AUTHENTICATED ?? ''}=${value}; domain=${
        process.env.REACT_APP_COOKIE_DOMAIN ?? ''
      }; path=/; Secure${expiresSnippet}`;
};
