import { Snackbar as MuiSnackbar, type SnackbarProps } from '@material-ui/core';
import { Alert, type AlertProps } from '@material-ui/lab';

interface Props {
  severity?: AlertProps['severity'];
}

export const Snackbar: React.VFC<Props & SnackbarProps> = ({
  severity,
  message,
  ...snackbarProps
}) => {
  const anchorOrigin: SnackbarProps['anchorOrigin'] = {
    vertical: 'bottom',
    horizontal: 'left',
  };

  if (severity) {
    return (
      <MuiSnackbar anchorOrigin={anchorOrigin} {...snackbarProps}>
        <Alert severity={severity}>{message}</Alert>
      </MuiSnackbar>
    );
  }

  return <MuiSnackbar anchorOrigin={anchorOrigin} message={message} {...snackbarProps} />;
};
