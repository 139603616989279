import { Global, css } from '@emotion/core';
import emotionReset from 'emotion-reset';

export const ResetCSS: React.FC = () => (
  <Global
    styles={css`
      ${emotionReset}
      code,
      kbd,
      pre,
      samp,
      button,
      input,
      label,
      optgroup,
      select,
      textarea,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          'Helvetica Neue', sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
      }

      html {
        box-sizing: border-box;
      }

      li {
        list-style: none;
      }
    `}
  />
);
