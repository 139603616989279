import type { AxiosResponse } from 'axios';
import {
  type UseMutationOptions,
  type UseMutationResult,
  useMutation,
  useQueryClient,
} from 'react-query';
import { ERROR } from '../../helpers/constants/ErrorMessages';
import { QUERY } from '../../helpers/constants/api';
import { useApiResponseHandler } from '../../helpers/hooks/useApiResponseHandler';
import type { AxiosApiError, EditUsersOfficeResponse, EditUsersOfficeVariables } from '../../types';
import { useAlertDialog } from '../repository/alertDialog';
import { mgmtAxios } from './share/axios';

type Variables = EditUsersOfficeVariables;
type Data = EditUsersOfficeResponse;
type UseEditUsersOffice = (
  options?: UseMutationOptions<Data, AxiosApiError, Variables>,
) => UseMutationResult<Data, AxiosApiError, Variables>;

export const useEditUsersOffice: UseEditUsersOffice = options => {
  const queryClient = useQueryClient();
  const { handleError, handleSuccess } = useApiResponseHandler();
  const mutationFn = async (variables: Variables) => {
    const res: AxiosResponse<Data> = await mgmtAxios.post('/users/offices', {
      editMethod: variables.editMethod,
      officeIds: variables.officeIds,
      userIds: variables.userIds,
    });

    return res.data;
  };

  // ---------- alertDialog ----------
  const [_, alertDialogDispatch] = useAlertDialog();

  return useMutation(mutationFn, {
    onError: (err: AxiosApiError) =>
      handleError(ERROR.EDIT_USERS_OFFICE, err.response?.data ?? undefined),
    onSettled: (res, err) => {
      if (res?.failedUsers && res.failedUsers.length > 0) {
        alertDialogDispatch({
          type: 'open',
          payload: {
            title:
              '以下のユーザーの所属クリニックは変更できませんでした。ユーザーの所属クリニックは１クリニック以上の登録が必要です。',
            message: res.failedUsers.map(user => user.name).join(',\u00A0\u00A0'),
          },
        });
      } else if (!err) {
        handleSuccess('所属クリニックを変更しました');
      }

      return queryClient.invalidateQueries([QUERY.USERS]);
    },
    ...options,
  });
};
