import {
  TableBody as MuiTableBody,
  TableCell,
  type TableCellProps,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import type { Row } from 'react-table';
import { isActionColumn } from '../../../helpers/utils/isActionColumn';

interface Props<TTableData extends Record<string, unknown>> {
  rows: Row<TTableData>[];
  prepareRow: (row: Row<TTableData>) => void;
  isLoading?: boolean;
  tableCellProps?: TableCellProps;
  noDataMessage?: string;
}

const useStyles = makeStyles(theme => ({
  tableBody: {
    height: '100%',
    position: 'relative',
  },
  noDataMessage: {
    height: 48,
    textAlign: 'center',
    verticalAlign: 'middle',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  searchIcon: {
    marginRight: theme.spacing(3),
    color: theme.palette.text.secondary,
    verticalAlign: 'text-bottom',
  },
  tableRowSelected: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  tableCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: '13px',
    paddingBottom: '13px',
    ...theme.mixins.ellipsis,
  },
  tableCellSmall: {
    padding: theme.spacing(1.5, 2),
  },
}));

export const TableBody = <TTableData extends Record<string, unknown>>({
  rows,
  prepareRow,
  isLoading,
  tableCellProps,
  noDataMessage = '条件に一致するデータは見つかりませんでした。',
}: Props<TTableData>): React.ReactElement => {
  const classes = useStyles();

  return (
    <MuiTableBody className={classes.tableBody}>
      {rows.length === 0 ? (
        <TableRow>
          <TableCell className={classes.noDataMessage} colSpan={100}>
            <Search className={classes.searchIcon} />
            {noDataMessage}
          </TableCell>
        </TableRow>
      ) : (
        rows.map(row => {
          prepareRow(row);

          return (
            <TableRow
              classes={{ selected: classes.tableRowSelected }}
              selected={row.isSelected}
              {...row.getRowProps()}
            >
              {row.cells.map(cell => (
                <TableCell
                  classes={{
                    root: classes.tableCell,
                    sizeSmall: classes.tableCellSmall,
                  }}
                  size="small"
                  padding={isActionColumn(cell.column.id) ? 'checkbox' : 'normal'}
                  style={{
                    maxWidth: isActionColumn(cell.column.id) ? 'auto' : cell.column.maxWidth,
                  }}
                  {...cell.getCellProps()}
                  {...tableCellProps}
                >
                  {isLoading ? <Skeleton /> : cell.render('Cell')}
                </TableCell>
              ))}
            </TableRow>
          );
        })
      )}
    </MuiTableBody>
  );
};
