import { isLocalDev } from 'helpers/constants/misc';
import { useCallback, useContext, useEffect, useReducer } from 'react';
import { Layout } from '../../../components/layout/AuthLayout';
import { Title } from '../../../components/ui/Title';
import { ERROR_CODE } from '../../../helpers/constants/ErrorMessages';
import { AUTH } from '../../../helpers/constants/Routes';
import { ErrorMessages } from '../../../helpers/utils/ApiErrors';
import { RefreshSession } from '../../../store/api/ApiService';
import type { Session } from '../../../store/models/Session';
import { LoginValidator } from '../../../store/models/Validators';
import { AuthContext } from '../../../store/repository/AuthContext';
import { RedirectContext } from '../../../store/repository/RedirectContext';
import { LoginTemplate } from './LoginTemplate';

interface State {
  email: string;
  password: string;
  errorMessage: string;
  loading: boolean;
}

type Action =
  | {
      type: 'inputEmail';
      payload: { email: string };
    }
  | {
      type: 'inputPassword';
      payload: { password: string };
    }
  | {
      type: 'submitStart';
    }
  | {
      type: 'validateSuccess';
    }
  | {
      type: 'validateFailure';
      payload: { errorMessage: string };
    }
  | {
      type: 'requestFailure';
      payload: { errorMessage: string };
    }
  | {
      type: 'requestFailureNotAccessibleByAccountant';
      payload: { errorMessage: string };
    }
  | {
      type: 'sessionInvalid';
      payload: { errorMessage: string };
    }
  | {
      type: 'notYetLoggedIn';
    };

const initialState: State = {
  email: '',
  password: '',
  errorMessage: '',
  loading: true,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // ---------- input ----------
    case 'inputEmail':
      return {
        ...state,
        email: action.payload.email,
      };
    case 'inputPassword':
      return {
        ...state,
        password: action.payload.password,
      };
    // ---------- submit ----------
    case 'submitStart':
      return {
        ...state,
        errorMessage: '',
      };
    case 'validateSuccess':
      return {
        ...state,
        loading: true,
      };
    case 'validateFailure':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    case 'requestFailure':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case 'requestFailureNotAccessibleByAccountant':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    case 'sessionInvalid':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case 'notYetLoggedIn':
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export const LoginPage: React.VFC = () => {
  const [{ email, password, errorMessage, loading }, dispatch] = useReducer<
    React.Reducer<State, Action>
  >(reducer, initialState);
  const { login, isAuthenticated } = useContext(AuthContext);
  const {
    query,
    session,
    logoutMessage,
    setLogoutMessage,
    setSession,
    redirectHandler,
    redirectToApp,
  } = useContext(RedirectContext);

  const handleLoginSubmit = async () => {
    dispatch({ type: 'submitStart' });

    const validationError = LoginValidator({ userId: email, userPassword: password });

    if (validationError) {
      dispatch({ type: 'validateFailure', payload: { errorMessage: validationError } });

      return;
    }

    dispatch({ type: 'validateSuccess' });

    const res = await login(email, password);
    // resがstringの場合はエラー
    if (typeof res === 'string') {
      // resが-1の場合は、インテリジェンスの画面にリダイレクトされます。
      // 詳しくは、src/store/repository/AuthContext.tsxのloginの関数を参照してください。
      if (res !== '-1') {
        dispatch({ type: 'requestFailure', payload: { errorMessage: res } });
      }
    } else {
      // 値をglobalStateにセットしたいので、ここはuseReducerのdispatchメソッドを使っていません。
      setLogoutMessage('');
      setSession(res);
      redirectHandler(AUTH.OFFICE_LIST);
    }
  };

  useEffect(() => {
    (async () => {
      if (await !isAuthenticated()) {
        dispatch({ type: 'notYetLoggedIn' });

        return;
      }

      if (session.sessionId) return;

      // すでにログインしている場合の処理
      const result: Session | void = await RefreshSession().catch(() => {
        dispatch({
          type: 'sessionInvalid',
          payload: { errorMessage: ErrorMessages(ERROR_CODE.SESSION_INVALID) },
        });
      });
      if (!result) return;

      // 医院が選択されていない場合、医院選択画面にリダイレクト

      setSession(result);
      setLogoutMessage('');
      if (isLocalDev || result.officeId === 0) {
        redirectHandler(AUTH.OFFICE_LIST);
      } else {
        // 医院が選択されている場合、アプリにリダイレクト
        redirectToApp(false, result);
      }
    })();
  }, [session, isAuthenticated, redirectToApp, setSession, redirectHandler, setLogoutMessage]);

  const handleEmailChange = useCallback((newValue: string) => {
    dispatch({ type: 'inputEmail', payload: { email: newValue } });
  }, []);

  const handlePasswordChange = useCallback((newValue: string) => {
    dispatch({ type: 'inputPassword', payload: { password: newValue } });
  }, []);

  return (
    <>
      <Title title="ログイン" />
      <Layout loading={loading} up="2vh">
        <LoginTemplate
          query={query}
          errorMessage={errorMessage}
          logoutMessage={logoutMessage}
          onLoginSubmit={handleLoginSubmit}
          email={email}
          onEmailChange={handleEmailChange}
          password={password}
          onPasswordChange={handlePasswordChange}
        />
      </Layout>
    </>
  );
};
