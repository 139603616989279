import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

type StyledBurger = {
  open: boolean;
};

export const StyledBurger = styled.button<StyledBurger>`
  background: transparent;
  border: none;
  cursor: pointer;
  display: none;
  flex-direction: column;
  height: 2rem;
  justify-content: space-around;
  padding: 0;
  position: absolute;
  right: 4%;
  top: 24%;
  width: 2rem;
  z-index: 10;

  @media (max-width: ${ds.bp('md')}) {
    display: flex;
  }

  &:focus {
    outline: none;
  }

  div {
    background: ${({ open }) => (open ? '#EFFFFA' : '#0D0C1D')};
    border-radius: 10px;
    height: 0.25rem;
    position: relative;
    transform-origin: 1px;
    transition: all 0.3s linear;
    width: 2rem;

    :first-of-type {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-of-type(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-of-type(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
