import { type Theme, createTheme } from '@material-ui/core/styles';
import { colorTheme } from './colorTheme';
import { typographyTheme } from './typographyTheme';

declare module '@material-ui/core/styles/createPalette' {
  // allow configuration using `createMuiTheme`
  interface Palette {
    hue: HuePaletteColor;
    accent: AccentColor;
  }
  interface PaletteOptions {
    hue?: HuePaletteColorOptions;
    accent?: AccentColorOptions;
  }
  interface HuePaletteColor {
    grey: string;
    blue: string;
    indigo: string;
    violet: string;
    fuschia: string;
    pink: string;
    red: string;
    orange: string;
    yellow: string;
    lime: string;
    green: string;
    teal: string;
  }
  interface AccentColor {
    light: string;
    main: string;
    dark: string;
    contraText: string;
  }
  interface HuePaletteColorOptions {
    grey?: string;
    blue?: string;
    indigo?: string;
    violet?: string;
    fuschia?: string;
    pink?: string;
    red?: string;
    orange?: string;
    yellow?: string;
    lime?: string;
    green?: string;
    teal?: string;
  }
  interface AccentColorOptions {
    light?: string;
    main?: string;
    dark?: string;
    contraText?: string;
  }
}

export const authTheme: Theme = createTheme({
  ...colorTheme,
  ...typographyTheme,
  overrides: {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
  },
});
