import { useCallback } from 'react';
import type { ApiError } from 'types';
import { useSnackbar } from '../../store/repository/snackbar';
import {
  CustomError,
  DEFECT_SEVERITY,
  ERROR,
  ERROR_CODE,
  errorCodeFeedbackMap,
} from '../constants/ErrorMessages';
import { MGMT } from '../constants/Routes';
import { useErrorHandler } from './useErrorHandler';
import { useRouter } from './useRouter';

interface ErrorHandler {
  handleError: (errorMessage: string, apiError: ApiError | undefined) => void;
  handleSuccess: (successMessage: string) => void;
}

export const useApiResponseHandler = (): ErrorHandler => {
  const { history } = useRouter();
  const [_, dispatch] = useSnackbar();
  const setError = useErrorHandler();

  const handleError = useCallback(
    (errorMessage: string, apiError: ApiError | undefined) => {
      if (apiError === undefined) {
        return setError(new CustomError(ERROR.ERROR_IS_UNDEFINED, DEFECT_SEVERITY.CRITICAL));
      }

      if (apiError.error.code === ERROR_CODE.UNAUTHORIZED) {
        return history.push(`/${MGMT.AUTH_ERROR}`, { isError: true });
      }

      if (apiError.error.code === ERROR_CODE.INTERNAL_SERVER_ERROR) {
        return setError(new CustomError(errorMessage, DEFECT_SEVERITY.CRITICAL));
      }

      const feedbackMessage = errorCodeFeedbackMap.get(apiError.error.code);

      if (!feedbackMessage) {
        return setError(new CustomError(errorMessage, DEFECT_SEVERITY.MAJOR));
      }

      return dispatch({
        type: 'open',
        payload: { message: feedbackMessage },
      });
    },
    [dispatch, history, setError],
  );

  const handleSuccess = useCallback(
    (successMessage: string) =>
      dispatch({
        type: 'open',
        payload: { message: successMessage },
      }),
    [dispatch],
  );

  return { handleSuccess, handleError };
};
