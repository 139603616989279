import type { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { TopErrorBoundary } from '../../components/ui/ErrorBoundary/TopErrorBoundary';
import { ProtectedRoute } from '../../routes/ProtectedRoute';
import { routes } from '../../routes/routes';
import { Providers } from '../../store/Providers';
import { AuthContextProvider } from '../../store/repository/AuthContext';
import { RedirectContextProvider } from '../../store/repository/RedirectContext';
import { AppUtility } from './AppUtility';

export const App: FC = () => {
  // ユーザーサービスが使われていない場合
  if (process.env.REACT_APP_USER_SERVICE_IN_USE === 'false') {
    window.location.href = `${process.env.REACT_APP_APOTOOL_URL as string}`;

    return null;
  }

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <RedirectContextProvider>
          <Providers>
            <TopErrorBoundary>
              <AppUtility />
              <Switch>
                {routes.map(route =>
                  route.protected ? (
                    <ProtectedRoute key={`${route.name}`} {...route} />
                  ) : (
                    <Route key={`${route.name}`} {...route} />
                  ),
                )}
              </Switch>
            </TopErrorBoundary>
          </Providers>
        </RedirectContextProvider>
      </BrowserRouter>
    </AuthContextProvider>
  );
};
