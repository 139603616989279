import {
  Box,
  Button,
  FormControl,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AUTH } from '../../../helpers/constants/Routes';

interface Props {
  query: string;
  errorMessage: string;
  logoutMessage: string;
  onLoginSubmit: () => Promise<void>;
  email: string;
  onEmailChange: (newValue: string) => void;
  password: string;
  onPasswordChange: (newValue: string) => void;
}

const useStyles = makeStyles({
  inputLabel: {
    color: '#000',
  },
});

export const LoginTemplate: React.VFC<Props> = ({
  query,
  errorMessage,
  logoutMessage,
  onLoginSubmit,
  email,
  onEmailChange,
  password,
  onPasswordChange,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box my="1em">
      <Grid container justifyContent="center">
        <form
          onSubmit={e => {
            e.preventDefault();
            void onLoginSubmit();
          }}
          autoComplete="on"
        >
          <Box mb="4vh" width="300px">
            <Box my="1rem">
              <Typography color="error">{errorMessage || null}</Typography>
            </Box>
            <Grid container direction="column">
              <Box mb="3vh" width="100%">
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    type="text"
                    autoFocus
                    value={email}
                    label="メールアドレス"
                    variant="outlined"
                    onChange={e => onEmailChange(e.target.value)}
                  />
                </FormControl>
              </Box>
              <FormControl>
                <TextField
                  size="small"
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  type="password"
                  value={password}
                  label="パスワード"
                  variant="outlined"
                  onChange={e => onPasswordChange(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Box my="1rem">
              <Typography>{logoutMessage}</Typography>
            </Box>
          </Box>
          <Box mb="4vh">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              style={{ width: '50%', borderRadius: '20px', padding: '12px' }}
            >
              <Typography variant="body1">ログイン</Typography>
            </Button>
          </Box>
          <div>
            <Link
              onClick={() => {
                if (query) {
                  history.push(`/${AUTH.FORGOT_PASSWORD}${query}`);
                } else {
                  history.push(`/${AUTH.FORGOT_PASSWORD}`);
                }
              }}
              color="primary"
              variant="body1"
              style={{ cursor: 'pointer' }}
            >
              パスワードを忘れた方
            </Link>
          </div>
        </form>
      </Grid>
    </Box>
  );
};
