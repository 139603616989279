export const convertSelectedRowIds = {
  toArray: (selectedRowIds: Record<string, boolean>): number[] =>
    Object.keys(selectedRowIds).map(id => Number.parseInt(id, 10)),
  toObject: (selectedRowIdsArray: number[]): Record<string, boolean> =>
    selectedRowIdsArray.reduce<Record<string, boolean>>(
      // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
      (prev, current) => ({ ...prev, [`${current}`]: true }),
      {},
    ),
};
