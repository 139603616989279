import { Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useMemo } from 'react';
import Div100vh from 'react-div-100vh';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { MGMT } from '../../../helpers/constants/Routes';
import { LAYOUT_MIN_HEIGHT } from '../../../helpers/constants/layout';
import { USER_TYPES } from '../../../helpers/constants/user';
import { useLocalStorage } from '../../../helpers/hooks/useLocalStorage';
import { useMediaQuery } from '../../../helpers/hooks/useMediaQuery';
import { useToggle } from '../../../helpers/hooks/useToggle';
import { useFetchManager } from '../../../store/api/useFetchManager';
import type { UsersTableState } from '../../../types';
import type { DrawerType } from '../../ui/ResponsiveDrawer/ResponsiveDrawer';
import { Footer } from './footer/Footer';
import { Header } from './header/Header';
import { SideNav } from './sideNav/SideNav';

interface Props {
  documentTitle: string;
  usersTableState?: UsersTableState | null;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minWidth: theme.breakpoints.values.sm,
    minHeight: LAYOUT_MIN_HEIGHT,
    overflowX: 'hidden',
  },
  main: {
    flexGrow: 1,
  },
  content: {
    position: 'relative',
    display: 'grid',
    gridAutoRows: 'auto 1fr',
    height: '100%',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    overflow: 'hidden',
  },
}));

export const MgmtLayout: React.FC<Props> = ({ children, documentTitle, usersTableState }) => {
  const classes = useStyles();
  const mediaQuery = useMediaQuery();
  const drawerType: DrawerType = useMemo(() => {
    if (mediaQuery === 'md' || mediaQuery === 'lg' || mediaQuery === 'xl') {
      return 'elastic';
    }

    return 'temporary';
  }, [mediaQuery]);
  const isGreaterThanLg = useMemo(() => mediaQuery === 'lg' || mediaQuery === 'xl', [mediaQuery]);
  const [isSideNavOpen, toggleIsSideNavOpen] = useToggle(false);
  const [isSideNavOpenLg, setIsSideNavOpenLg] = useLocalStorage('isSideNavOpenLg', isGreaterThanLg);
  const toggleIsSideNavOpenLg = useCallback(
    () => setIsSideNavOpenLg(prev => !prev),
    [setIsSideNavOpenLg],
  );
  const isSNOpen = isGreaterThanLg ? Boolean(isSideNavOpenLg) : isSideNavOpen;
  const toggleSNOpen = isGreaterThanLg ? toggleIsSideNavOpenLg : toggleIsSideNavOpen;

  // ---------- api(query) ----------
  const { data, isLoading, isFetching } = useFetchManager();
  const loginUser = {
    email: data?.email ?? '',
    name: data?.name ?? '',
    isAdmin: data?.isAdmin ?? false,
  };

  if (!isFetching && data?.isAdmin && data?.userType === USER_TYPES.ADMIN) {
    return <Redirect to={`/${MGMT.ADMIN_MGMT_USERS}`} />;
  }

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <Div100vh className={classes.root}>
        <Header
          drawerType={drawerType}
          toggleIsSideNavOpen={toggleSNOpen}
          loginUser={loginUser}
          isLoading={isLoading}
        />
        <SideNav
          drawerType={drawerType}
          isSideNavOpen={isSNOpen}
          toggleIsSideNavOpen={toggleSNOpen}
          isAdmin={data?.isAdmin ?? false}
          userType={data?.userType ?? undefined}
          isLoading={isLoading}
          usersTableState={usersTableState}
        />
        <main className={classes.main}>
          <div className={classes.content}>
            <Toolbar />
            {children}
          </div>
        </main>
        <Footer />
      </Div100vh>
    </>
  );
};
