import {
  DialogActions,
  DialogContentText,
  DialogTitle,
  CircularProgress as MuiCircularProgress,
  Dialog as MuiDialog,
  type DialogProps as MuiDialogProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import type { ButtonProps } from '../Button/Button';
import { YesOrNoButton } from '../YesOrNoButton/YesOrNoButton';
import { DialogContentWithDivider } from './DialogContentWithDivider';

export interface DialogProps extends MuiDialogProps {
  title: string;
  message?: string;
  scrollHeight?: number;
  yesLabel?: string;
  yesButtonProps: ButtonProps;
  noLabel?: string;
  noButtonProps?: ButtonProps;
  disableContentPadding?: boolean;
  isMutating?: boolean;
  onClose: () => void;
}

const DIALOG_PAPER_MARGIN_Y = 80;
const CIRCULAR_PROGRESS_SIZE = 56;

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    position: 'relative',
    maxHeight: `calc(100% - ${DIALOG_PAPER_MARGIN_Y * 2}px)`,
    marginTop: DIALOG_PAPER_MARGIN_Y,
    marginBottom: DIALOG_PAPER_MARGIN_Y,
  },
  dialogMutating: {
    '& .MuiDialog-paper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: CIRCULAR_PROGRESS_SIZE,
      height: CIRCULAR_PROGRESS_SIZE,
      borderRadius: CIRCULAR_PROGRESS_SIZE / 2,
    },
    '& .MuiDialogTitle-root': {
      display: 'none',
    },
    '& .MuiDialogContent-root': {
      display: 'none',
    },
    '& .MuiDialogActions-root': {
      display: 'none',
    },
  },
  dialogTitle: {
    paddingTop: theme.spacing(2.5),
  },
}));

export const Dialog: React.FC<DialogProps> = ({
  children,
  title,
  message,
  yesLabel,
  yesButtonProps,
  noLabel,
  noButtonProps,
  onClose,
  disableContentPadding = false,
  isMutating = false,
  ...dialogProps
}) => {
  const classes = useStyles();

  return (
    <MuiDialog
      className={clsx(isMutating && classes.dialogMutating)}
      classes={{ paper: clsx(!isMutating && classes.dialogPaper) }}
      fullWidth
      scroll="paper"
      onClose={onClose}
      {...dialogProps}
    >
      {isMutating && <MuiCircularProgress />}
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContentWithDivider disableContentPadding={disableContentPadding}>
        {message && (
          <DialogContentText variant="body2" color="textPrimary">
            {message}
          </DialogContentText>
        )}
        {children}
      </DialogContentWithDivider>
      <DialogActions>
        <YesOrNoButton
          yesLabel={yesLabel}
          yesButtonProps={{
            variant: 'text',
            color: 'primary',
            ...yesButtonProps,
          }}
          noLabel={noLabel}
          noButtonProps={{
            variant: 'text',
            color: 'primary',
            onClick: () => onClose(),
            ...noButtonProps,
          }}
        />
      </DialogActions>
    </MuiDialog>
  );
};
