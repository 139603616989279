import { useMemo } from 'react';
import { type CellProps, type Column, type TableOptions, useTable } from 'react-table';
import { MgmtLayout } from '../../../components/layout/mgmtLayout/MgmtLayout';
import { Tooltip } from '../../../components/ui/Tooltip/Tooltip';
import { useFetchOffices } from '../../../store/api/useFetchOffices';
import type { OfficesTableData } from '../../../types';
import { OfficesTemplate } from './OfficesTemplate';

const initialOfficesTableData: OfficesTableData[] = [
  {
    managerName: '',
    officeName: '',
    managerEmail: '',
    contractEmail: '',
  },
];

export const OfficesPage: React.FC = () => {
  // ---------- api(query) ----------
  const {
    query: { data, isLoading },
    pageIndexHooks,
  } = useFetchOffices();
  const offices = useMemo(
    () =>
      data?.offices.map(office => {
        const tableData: OfficesTableData = {
          managerName: office.managerName,
          officeName: office.name,
          managerEmail: office.managerEmail,
          contractEmail: office.contractEmail,
        };

        return tableData;
      }) ?? initialOfficesTableData,
    [data?.offices],
  );

  // ---------- table ----------
  const columns: Column<OfficesTableData>[] = useMemo(
    () => [
      {
        Header: '契約者名',
        accessor: 'managerName',
        maxWidth: 180,
      },
      {
        Header: 'クリニック名',
        accessor: 'officeName',
        maxWidth: 180,
      },
      {
        Header: 'メールアドレス',
        accessor: 'managerEmail',
        maxWidth: 180,
      },
      {
        Header: '請求書送付用メールアドレス',
        accessor: 'contractEmail',
        maxWidth: 180,
      },
    ],
    [],
  );
  const tableOptions: TableOptions<OfficesTableData> = {
    data: offices,
    columns,
    defaultColumn: {
      Cell: ({ value }: CellProps<OfficesTableData, React.ReactText>) => (
        <Tooltip variant="intrinsic" title={value} isWrappedInScroll>
          {value}
        </Tooltip>
      ),
    },
  };
  const officesTable = useTable<OfficesTableData>(tableOptions);

  return (
    <MgmtLayout documentTitle="クリニック一覧">
      <OfficesTemplate
        officesTable={officesTable}
        paging={{ pageIndex: pageIndexHooks[0], handleChangePageIndex: pageIndexHooks[1] }}
        totalCount={data?.totalCount ?? 0}
        isLoading={isLoading}
      />
    </MgmtLayout>
  );
};
