import { ERROR_CODE } from 'helpers/constants/ErrorMessages';
import React, { type FC, createContext } from 'react';
import { ErrorMessages } from '../../helpers/utils/ApiErrors';
import { CheckCookieExists } from '../../helpers/utils/CheckCookieExists';
import { SetAuthenticationCookie } from '../../helpers/utils/SetAuthenticationCookie';
import { LoginUser, LogoutUser } from '../api/ApiService';
import type { Session } from '../models/Session';

type AuthContextType = {
  login: (email: string, password: string) => Promise<string | Session>;
  logout: () => Promise<void>;
  isAuthenticated: () => boolean;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthContextProvider: FC = ({ children }) => {
  const login: (email: string, password: string) => Promise<any> = async (email, password) => {
    const response = await LoginUser({ userId: email, userPassword: password })
      .then((res: Session | void) => {
        if (!res) throw new Error('ログインする時、エラーが発生しました');
        SetAuthenticationCookie(res.ssoAuthenticated);

        return res;
      })
      .catch((err: Error) => {
        const { message } = err;
        if (message.includes(ERROR_CODE.NOT_ACCESSIBLE_BY_ACCOUNTANT)) {
          window.location.href = `${process.env.REACT_APP_APOTOOL_URL ?? ''}managements/`;

          return '-1';
        }

        return ErrorMessages(message);
      });

    return response;
  };

  const logout: () => Promise<void> = async () => {
    SetAuthenticationCookie('', true);
    await LogoutUser();
  };

  const isAuthenticated: () => boolean = () =>
    CheckCookieExists(`${process.env.REACT_APP_COOKIE_AUTHENTICATED ?? ''}`);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
