import * as yupDefault from 'yup';
import type { AnyObject } from 'yup/lib/object';
import { yupLocaleJP } from './yupLocaleJP';

export const katakana = (
  schema: yupDefault.StringSchema,
): yupDefault.StringSchema<string | undefined, AnyObject, string | undefined> =>
  schema.test('kana', 'カタカナで入力してください', value => {
    if (value === null || value === '' || value === undefined) return true;
    const regex = new RegExp(/^([ァ-ン]|ー|・|[\s　])+$/);

    return regex.test(value);
  });

export const deleteText = (
  schema: yupDefault.StringSchema,
): yupDefault.StringSchema<string | undefined, AnyObject, string | undefined> =>
  schema.test('deleteText', '削除するには「DELETE」と入力してください', value => {
    if (value === null || value === '' || value === undefined) return true;

    return value === 'DELETE';
  });

/**
 * バックエンドのバリデーションに合わせる
 * https://github.com/stransa-co-ltd/apotool-user-backend/blob/7dc397281cd504e06a203b9eff1acd07e85d1b00/api/domain/mgmtUser.go
 */

const customEmailRegex = /^[a-zA-Z0-9.!#$%&\'*+\/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/;

export const matchEmail = (
  schema: yupDefault.StringSchema,
): yupDefault.StringSchema<string | undefined, AnyObject, string | undefined> =>
  schema.test('matchEmail', '正しいメールアドレスを入力してください', value => {
    if (value === null || value === '' || value === undefined) return true;

    return customEmailRegex.test(value);
  });

yupDefault.addMethod<yupDefault.StringSchema>(
  yupDefault.string,
  'katakana',
  function validateKana() {
    return katakana(this);
  },
);
yupDefault.addMethod<yupDefault.StringSchema>(
  yupDefault.string,
  'deleteText',
  function validateDeleteText() {
    return deleteText(this);
  },
);
yupDefault.addMethod<yupDefault.StringSchema>(
  yupDefault.string,
  'matchEmail',
  function validateDeleteText() {
    return matchEmail(this);
  },
);
yupDefault.setLocale(yupLocaleJP);

export const yup = yupDefault;
export type SchemaOf<T> = yupDefault.SchemaOf<T>;
