import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

type StyledMenu = {
  open: boolean;
};
export const StyledMenu = styled.nav<StyledMenu>`
  background: ${ds.brand('lilDark')};
  border-bottom: 1px solid ${ds.brand('base')};
  color: ${ds.brand('base')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 0.5em 0 1.5em 0;
  position: absolute;
  text-align: left;
  top: 0;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateY(-100%)')};
  transition: transform 0.15s ease-in-out;
  width: 100%;

  img {
    height: 20px;
    margin-right: 1em;
    vertical-align: bottom;
    width: 20px;
  }

  a {
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem 0;
    text-align: center;
    transition: color 0.3s linear;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${ds.brand('dark')};
    }
  }
`;
