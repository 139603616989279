import type { FetchUserLogsResponse, UserLogsTableData } from '../../types';
import type { ArrayElement, ConvertDate } from '../../utilityTypes';
import { formatDate } from '../formatDate';

export const convertUserLogsForUserLogsTable = (
  userLogs: ConvertDate<ArrayElement<FetchUserLogsResponse['userActivityLogs']>>[],
  isUserInformationSeparatedTable: boolean,
): UserLogsTableData[] =>
  isUserInformationSeparatedTable
    ? userLogs.map(userLog => ({
        userName: userLog.userName,
        userEmail: userLog.userEmail,
        officeName: userLog.officeName,
        ipAddress: userLog.ipAddress,
        activity: userLog.activity,
        createdAt: formatDate(userLog.createdAt, 'dateAndTime'),
      }))
    : userLogs.map(userLog => ({
        userInformation: { name: userLog.userName, email: userLog.userEmail },
        officeName: userLog.officeName,
        ipAddress: userLog.ipAddress,
        activity: userLog.activity,
        createdAt: formatDate(userLog.createdAt, 'dateAndTime'),
      }));
