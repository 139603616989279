import { useCallback, useState } from 'react';

export type UsePageIndexReturnType = [number, (_: unknown, newPageIndex: number) => void];

export const usePageIndex = (): UsePageIndexReturnType => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const handleChangePageIndex = useCallback((_: unknown, newPageIndex: number) => {
    setPageIndex(newPageIndex);
  }, []);

  return [pageIndex, handleChangePageIndex];
};
