import { IconButton, Link, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { emphasize, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { useRouter } from '../../../../helpers/hooks/useRouter';
import type { DrawerType } from '../../../ui/ResponsiveDrawer/ResponsiveDrawer';
import type { SideNavItem } from './SideNavItems';

interface Props {
  NavItem: SideNavItem;
  drawerType: DrawerType;
  isSideNavOpen: boolean;
  handleClickNavItem: () => void;
  isLoading: boolean;
}

// padding(ListItem) + margin(ListItemText) + 16 * lineHeight
const NAV_LINK_ITEM_HEIGHT = 16 + 12 + 20;
const SHRINK_ICON_FONT_SIZE = 20;
const LIST_ITEM_PADDING_LEFT = 26;
const ICON_BUTTON_SIZE = 36;

const useStyles = makeStyles(theme => ({
  // Shrink
  iconWrapper: {
    paddingLeft: LIST_ITEM_PADDING_LEFT - theme.spacing(1),
  },
  iconButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(0.5, 0),
  },
  selectedIconButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: emphasize(theme.palette.primary.light, theme.palette.action.hoverOpacity),
    },
  },
  // Expanded
  listItem: {
    paddingLeft: LIST_ITEM_PADDING_LEFT,
    borderStartEndRadius: NAV_LINK_ITEM_HEIGHT / 2,
    borderEndEndRadius: NAV_LINK_ITEM_HEIGHT / 2,
  },
  listItemIcon: {
    minWidth: SHRINK_ICON_FONT_SIZE + 16,
  },
  listItemButton: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      '& span, svg': {
        color: theme.palette.primary.main,
      },
      '&:hover': {
        backgroundColor: emphasize(theme.palette.primary.light, theme.palette.action.hoverOpacity),
      },
    },
  },
}));

export const SideNavListItem: React.VFC<Props> = ({
  NavItem,
  drawerType,
  isSideNavOpen,
  handleClickNavItem,
  isLoading,
}) => {
  const classes = useStyles();
  const { location } = useRouter();
  const selected = !isLoading && NavItem.path === location.pathname;
  const isShrunken = drawerType === 'elastic' && !isSideNavOpen;

  if (isShrunken) {
    return (
      <li className={classes.iconWrapper}>
        {isLoading ? (
          <Skeleton variant="circle" width={ICON_BUTTON_SIZE} height={ICON_BUTTON_SIZE} />
        ) : (
          <Tooltip title={NavItem.name}>
            <IconButton
              color={selected ? 'primary' : 'default'}
              className={clsx(classes.iconButton, selected && classes.selectedIconButton)}
              onClick={handleClickNavItem}
            >
              <NavItem.Icon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </li>
    );
  }

  return (
    <ListItem
      className={classes.listItem}
      classes={{
        button: classes.listItemButton,
      }}
      button
      component={Link}
      selected={selected}
      onClick={handleClickNavItem}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <NavItem.Icon fontSize="small" />
      </ListItemIcon>
      <ListItemText
        primary={isLoading ? <Skeleton variant="text" /> : NavItem.name}
        primaryTypographyProps={{ variant: 'body2', color: 'textPrimary' }}
      />
    </ListItem>
  );
};
