import { ListItem, ListItemText, ListSubheader, List as MuiList } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import type { ListItem as ListItemType } from '../../../types';

interface Props {
  list: ListItemType[];
  subheader?: string;
  wrappedInDialog?: boolean;
}

const useStyles = makeStyles(theme => ({
  listWrappedInDialog: {
    margin: theme.spacing(0, 1),
  },
  listSubheader: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const List: React.VFC<Props> = ({ list, subheader, wrappedInDialog = false }) => {
  const classes = useStyles();

  return (
    <MuiList
      className={clsx(wrappedInDialog && classes.listWrappedInDialog)}
      subheader={
        subheader ? (
          <ListSubheader className={classes.listSubheader}>{subheader}</ListSubheader>
        ) : undefined
      }
    >
      {list.map(listItem => (
        <ListItem key={listItem.value}>
          <ListItemText primary={listItem.label} />
        </ListItem>
      ))}
    </MuiList>
  );
};
