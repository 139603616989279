const kanaMap: ReadonlyMap<string, string> = new Map([
  ['ｶﾞ', 'ガ'],
  ['ｷﾞ', 'ギ'],
  ['ｸﾞ', 'グ'],
  ['ｹﾞ', 'ゲ'],
  ['ｺﾞ', 'ゴ'],
  ['ｻﾞ', 'ザ'],
  ['ｼﾞ', 'ジ'],
  ['ｽﾞ', 'ズ'],
  ['ｾﾞ', 'ゼ'],
  ['ｿﾞ', 'ゾ'],
  ['ﾀﾞ', 'ダ'],
  ['ﾁﾞ', 'ヂ'],
  ['ﾂﾞ', 'ヅ'],
  ['ﾃﾞ', 'デ'],
  ['ﾄﾞ', 'ド'],
  ['ﾊﾞ', 'バ'],
  ['ﾋﾞ', 'ビ'],
  ['ﾌﾞ', 'ブ'],
  ['ﾍﾞ', 'ベ'],
  ['ﾎﾞ', 'ボ'],
  ['ﾊﾟ', 'パ'],
  ['ﾋﾟ', 'ピ'],
  ['ﾌﾟ', 'プ'],
  ['ﾍﾟ', 'ペ'],
  ['ﾎﾟ', 'ポ'],
  ['ｳﾞ', 'ヴ'],
  ['ﾜﾞ', 'ヷ'],
  ['ｦﾞ', 'ヺ'],
  ['ｱ', 'ア'],
  ['ｲ', 'イ'],
  ['ｳ', 'ウ'],
  ['ｴ', 'エ'],
  ['ｵ', 'オ'],
  ['ｶ', 'カ'],
  ['ｷ', 'キ'],
  ['ｸ', 'ク'],
  ['ｹ', 'ケ'],
  ['ｺ', 'コ'],
  ['ｻ', 'サ'],
  ['ｼ', 'シ'],
  ['ｽ', 'ス'],
  ['ｾ', 'セ'],
  ['ｿ', 'ソ'],
  ['ﾀ', 'タ'],
  ['ﾁ', 'チ'],
  ['ﾂ', 'ツ'],
  ['ﾃ', 'テ'],
  ['ﾄ', 'ト'],
  ['ﾅ', 'ナ'],
  ['ﾆ', 'ニ'],
  ['ﾇ', 'ヌ'],
  ['ﾈ', 'ネ'],
  ['ﾉ', 'ノ'],
  ['ﾊ', 'ハ'],
  ['ﾋ', 'ヒ'],
  ['ﾌ', 'フ'],
  ['ﾍ', 'ヘ'],
  ['ﾎ', 'ホ'],
  ['ﾏ', 'マ'],
  ['ﾐ', 'ミ'],
  ['ﾑ', 'ム'],
  ['ﾒ', 'メ'],
  ['ﾓ', 'モ'],
  ['ﾔ', 'ヤ'],
  ['ﾕ', 'ユ'],
  ['ﾖ', 'ヨ'],
  ['ﾗ', 'ラ'],
  ['ﾘ', 'リ'],
  ['ﾙ', 'ル'],
  ['ﾚ', 'レ'],
  ['ﾛ', 'ロ'],
  ['ﾜ', 'ワ'],
  ['ｦ', 'ヲ'],
  ['ﾝ', 'ン'],
  ['ｧ', 'ァ'],
  ['ｨ', 'ィ'],
  ['ｩ', 'ゥ'],
  ['ｪ', 'ェ'],
  ['ｫ', 'ォ'],
  ['ｯ', 'ッ'],
  ['ｬ', 'ャ'],
  ['ｭ', 'ュ'],
  ['ｮ', 'ョ'],
  ['ｰ', 'ー'],
]);

export const convertKanaToFullWidth = (character: string): string => {
  const shouldConvertCharacter = Array.from(kanaMap.keys());
  const regex = new RegExp(`(${shouldConvertCharacter.join('|')})`, 'g');
  const replaceCallback = (match: string): string => kanaMap.get(match)!;

  return character.replace(regex, replaceCallback).replace(/ﾞ/g, '゛').replace(/ﾟ/g, '゜');
};
