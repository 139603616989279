import { type IconButtonProps, IconButton as MuiIconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  colorInherit: {
    color: theme.palette.text.secondary,
  },
}));

export const IconButton: React.VFC<IconButtonProps> = ({ children, ...iconButtonProps }) => {
  const classes = useStyles();

  return (
    <MuiIconButton classes={{ colorInherit: classes.colorInherit }} {...iconButtonProps}>
      {children}
    </MuiIconButton>
  );
};
