import { Typography, type TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    lineHeight: 1.2,
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  gutterBottom: {
    marginBottom: theme.spacing(1.5),
  },
}));

export const PageTitle: React.VFC<TypographyProps> = ({ children, ...typographyProps }) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.root}
      classes={{ paragraph: classes.paragraph, gutterBottom: classes.gutterBottom }}
      variant="h4"
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};
