import { makeStyles } from '@material-ui/core/styles';
import type { TableInstance } from 'react-table';
import { PageTitle } from '../../../components/ui/PageTitle/PageTitle';
import { SearchTextField } from '../../../components/ui/SearchTextField/SearchTextField';
import { Stack } from '../../../components/ui/Stack/Stack';
import { Table } from '../../../components/ui/Table/Table';
import { DateRangePicker } from '../../../components/ui/dateRangePicker/DateRangePicker';
import { SEARCH_TEXT_FIELD_WIDTH } from '../../../helpers/constants/layout';
import { USER_TYPES } from '../../../helpers/constants/user';
import type { UserLogsHandlers, UserLogsState } from '../../../store/api/useFetchUserLogs';
import type { FetchManagerResponse, UserLogsTableData } from '../../../types';
import { OfficeFilter } from '../shared/OfficeFilter';

interface Props {
  loginUser: FetchManagerResponse['user'];
  userLogsTable: TableInstance<UserLogsTableData>;
  userLogsState: UserLogsState;
  userLogsHandlers: UserLogsHandlers;
  totalCount: number;
  isLoading: boolean;
  isUserInformationSeparatedTable: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridAutoRows: 'auto 1fr',
    height: '100%',
  },
  searchAndFilterWrapper: {
    overflow: 'hidden',
  },
  searchTextField: {
    width: SEARCH_TEXT_FIELD_WIDTH,
  },
  searchAndDatePickerWrapper: {
    display: 'flex',
    columnGap: theme.spacing(2),
  },
}));

export const UserLogsTemplate: React.VFC<Props> = ({
  loginUser,
  userLogsTable,
  userLogsState,
  userLogsHandlers,
  totalCount,
  isLoading,
  isUserInformationSeparatedTable,
}) => {
  const classes = useStyles();
  const { headerGroups, rows, prepareRow, getTableProps } = userLogsTable;
  const tableProps = getTableProps();

  return (
    <div className={classes.root}>
      <Stack className={classes.searchAndFilterWrapper} spacing={2} mb={3}>
        <PageTitle paragraph>ログイン履歴</PageTitle>
        <div className={classes.searchAndDatePickerWrapper}>
          <SearchTextField
            className={classes.searchTextField}
            value={userLogsState.keyword}
            onChange={userLogsHandlers.handleInputKeyword}
            handleClear={userLogsHandlers.handleClearKeyword}
          />
          <DateRangePicker
            dateRange={userLogsState.dateRange}
            handleChangeDateRange={userLogsHandlers.handleChangeDateRange}
          />
        </div>
        {loginUser.userType === USER_TYPES.GROUPMANAGER && (
          <OfficeFilter
            offices={loginUser.offices}
            officeIds={userLogsState.officeIds}
            toggleOfficeId={userLogsHandlers.handleToggleOfficeId}
            clearOfficeIds={userLogsHandlers.handleClearOfficeIds}
          />
        )}
      </Stack>
      <Table
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        totalCount={totalCount}
        pageIndex={userLogsState.pageIndex}
        handleChangePageIndex={userLogsHandlers.handleChangePageIndex}
        tableProps={tableProps}
        tableCellProps={{ size: isUserInformationSeparatedTable ? 'medium' : 'small' }}
        isLoading={isLoading}
        noDataMessage="条件に一致するログイン履歴は見つかりませんでした。"
      />
    </div>
  );
};
