import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { ds } from '../theme/authTheme/ds';

export const round = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

type Loader = {
  theme?: {
    main: string;
    light: string;
  };
};
export const Loader = styled.div<Loader>`
  animation: ${round} 0.5s infinite linear;
  border-bottom: 0.7em solid rgba(0, 153, 153, 0.2);
  border-left: 0.7em solid ${ds.brand('main')};
  border-radius: 50%;
  border-right: 0.7em solid rgba(0, 153, 153, 0.2);
  border-top: 0.7em solid rgba(0, 153, 153, 0.2);
  font-size: 10px;
  height: 4.5em;
  margin: 60px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 4.5em;

  &.theme {
    border-bottom: 0.7em solid ${props => props.theme.light};
    border-left: 1em solid ${props => props.theme.main};
    border-right: 0.7em solid ${props => props.theme.light};
    border-top: 0.7em solid ${props => props.theme.light};
  }

  ::after {
    border-radius: 50%;
    height: 10em;
    width: 10em;
  }
`;

export const SendingSpinner = styled(AiOutlineLoading3Quarters)`
  animation: ${round} 0.5s infinite linear;
`;
