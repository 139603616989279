import { endpoint, isLocalDev } from 'helpers/constants/misc';
import type * as ApiRequestTypes from '../models/Requests';
import type * as ApiResponseTypes from '../models/Responses';
import type { Session } from '../models/Session';

const baseUrl = isLocalDev ? '/api' : endpoint;

const processRes = async (res: Response) => {
  if (res.status === 200) {
    const parsedRes = await res.json().catch(() => -1);

    if (parsedRes === -1) throw new Error('データパルスできせん');

    return parsedRes.data;
  }
  const errText = await res.text();
  throw new Error(errText);
};

export const ApiCallGet: (path: string) => Promise<any> = path =>
  fetch(`${baseUrl}/${path}`, {
    credentials: 'include',
  }).then(async res => processRes(res));

export const ApiCallPost: (path: string, body: Record<string, unknown>) => Promise<any> = (
  path,
  body,
) =>
  fetch(`${baseUrl}/${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(body),
    credentials: 'include',
  }).then(async res => processRes(res));

export const LoginUser: (req: ApiRequestTypes.LoginRequestType) => Promise<Session> = req =>
  ApiCallPost('login', req);

export const LogoutUser: () => Promise<void> = () => ApiCallGet('logout');

export const RefreshSession: () => Promise<Session> = () => ApiCallGet('user');

export const ForgotPasswordUser: (req: ApiRequestTypes.ForgotPasswordRequestType) => Promise<void> =
  req => ApiCallPost('forgot-password', req);

export const ConfirmForgotPasswordUser: (
  req: ApiRequestTypes.ConfirmForgotPasswordRequestType,
) => Promise<void> = req => ApiCallPost('confirm-forgot-password', req);

export const GetOfficeList: (
  page: number,
  search: string,
) => Promise<ApiResponseTypes.OfficeListResponseType> = (page, search) =>
  ApiCallGet(`office/all?search=${search}&page=${page}`);

export const SelectOffice: (req: ApiRequestTypes.OfficeSelectRequestType) => Promise<Session> =
  req => ApiCallPost('office', req);
