import {
  type UseMutationOptions,
  type UseMutationResult,
  useMutation,
  useQueryClient,
} from 'react-query';
import { ERROR } from '../../helpers/constants/ErrorMessages';
import { QUERY } from '../../helpers/constants/api';
import { useApiResponseHandler } from '../../helpers/hooks/useApiResponseHandler';
import type { AxiosApiError, DeleteUsersVariables } from '../../types';
import { mgmtAxios } from './share/axios';

type Variables = DeleteUsersVariables;
type Data = unknown;
type UseDeleteUsers = (
  options?: UseMutationOptions<Data, AxiosApiError, Variables>,
) => UseMutationResult<Data, AxiosApiError, Variables>;

export const useDeleteUsers: UseDeleteUsers = options => {
  const { handleError, handleSuccess } = useApiResponseHandler();
  const queryClient = useQueryClient();
  const mutationFn = (variables: Variables) =>
    mgmtAxios.delete('/users', { params: { userId: variables.userIds } });

  return useMutation(mutationFn, {
    onError: (err: AxiosApiError) =>
      handleError(ERROR.DELETE_USER, err.response?.data ?? undefined),
    onSettled: (_, err) => {
      if (!err) {
        handleSuccess('選択したユーザーを削除しました');
      }

      return queryClient.invalidateQueries([QUERY.USERS]);
    },
    ...options,
  });
};
