import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FORM_GRID_NAMES,
  LIST_PADDING_TOP,
  SELECT_FILED_HEIGHT,
} from '../../../helpers/constants/layout';
import type { CheckboxListItem, RadioButtonListItem } from '../../../types';
import type { DiffEmpty } from '../../../utilityTypes';
import { CheckboxList } from '../SelectList/CheckboxList';
import { RadioButtonList } from '../SelectList/RadioButtonList';
import { FormLabel } from './FormLabel';

interface BaseProps {
  label: string;
  maxHeight?: React.CSSProperties['maxHeight'];
}
interface CheckboxProps {
  selectedValues: number[];
  toggleSelect: (value: number) => void;
  list: CheckboxListItem[];
}
interface RadioButtonProps {
  selectedValue: string | undefined;
  handleSelect: (value: string) => void;
  list: RadioButtonListItem[];
}
type AlternativeProps =
  | (RadioButtonProps & DiffEmpty<RadioButtonProps, CheckboxProps>)
  | (CheckboxProps & DiffEmpty<CheckboxProps, RadioButtonProps>);
type Props = BaseProps & AlternativeProps;

const useStyles = makeStyles(() => ({
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    gridColumnStart: FORM_GRID_NAMES.LABEL,
    height: SELECT_FILED_HEIGHT,
    marginTop: LIST_PADDING_TOP,
  },
  selectFieldWrapper: {
    gridColumnStart: FORM_GRID_NAMES.FORM,
    scrollSnapType: 'y proximity',
    overflowY: 'auto',
    maxHeight: ({ maxHeight }: Pick<Props, 'maxHeight'>) => maxHeight,
    scrollPaddingTop: `${LIST_PADDING_TOP}px`,
    '& > *': {
      scrollSnapAlign: 'start',
    },
  },
}));

export const FormLabelSelect: React.VFC<Props> = ({ label, maxHeight, ...rest }) => {
  const classes = useStyles({ maxHeight });

  return (
    <>
      <div className={classes.labelWrapper}>
        <FormLabel label={label} />
      </div>
      <Paper className={classes.selectFieldWrapper} elevation={0}>
        {rest.selectedValues !== undefined ? (
          <CheckboxList
            list={rest.list}
            checkedList={rest.selectedValues}
            toggleCheck={rest.toggleSelect}
          />
        ) : (
          <RadioButtonList
            list={rest.list}
            selectedValue={rest.selectedValue}
            handleSelect={rest.handleSelect}
          />
        )}
      </Paper>
    </>
  );
};
