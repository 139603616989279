import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Refresh } from '@material-ui/icons';
import Div100vh from 'react-div-100vh';
import type { CustomError } from '../../../helpers/constants/ErrorMessages';
import type { Empty } from '../../../utilityTypes';
import { ErrorBoundary } from './ErrorBoundary';

interface FallbackComponentProps {
  error: CustomError | (Error & Empty<CustomError>);
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textAndButtonWrapper: {
    width: 450,
    // 中心より気持ち上目に配置する
    marginBottom: theme.spacing(7),
    '@media (min-width:0px) and (orientation: landscape)': {
      marginBottom: theme.spacing(6),
    },
    '@media (min-width:600px)': {
      marginBottom: theme.spacing(8),
    },
  },
}));

export const FallbackComponent: React.VFC<FallbackComponentProps> = ({ error }) => {
  const classes = useStyles();
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Div100vh className={classes.root}>
      <div className={classes.textAndButtonWrapper}>
        <Typography variant="h3" gutterBottom>
          {error.defectSeverity ? error.message : '何らかのエラーが発生しました'}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          このページを更新してください。
        </Typography>
        <Button variant="contained" color="primary" onClick={handleRefresh}>
          <Refresh />
        </Button>
      </div>
    </Div100vh>
  );
};

export const TopErrorBoundary: React.FC = ({ children }) => (
  <ErrorBoundary fallbackRender={({ error }) => <FallbackComponent error={error} />}>
    {children}
  </ErrorBoundary>
);
