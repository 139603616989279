import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import type { CheckboxListItem } from '../../../types';

export interface CheckboxListProps {
  list: CheckboxListItem[];
  checkedList: number[];
  toggleCheck: (value: number) => void;
  wrappedInDialog?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  listItemWrappedInDialog: {
    paddingLeft: theme.spacing(3),
  },
}));

export const CheckboxList: React.FC<CheckboxListProps> = ({
  list,
  checkedList,
  toggleCheck,
  wrappedInDialog = false,
}) => {
  const classes = useStyles();

  return (
    <List className={classes.root} disablePadding={wrappedInDialog}>
      {list.map(({ label, value, helperText }) => {
        const listItemTextId = `checkbox-list-label-${value}`;

        return (
          <ListItem
            className={clsx(wrappedInDialog && classes.listItemWrappedInDialog)}
            key={value}
            dense
            button
            onClick={() => toggleCheck(value)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={checkedList.includes(value)}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText id={listItemTextId} primary={label} secondary={helperText || null} />
          </ListItem>
        );
      })}
    </List>
  );
};
