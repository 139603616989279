export const overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          width: '4px',
          height: '4px',
        },
        '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          borderRadius: '10px',
        },
      },
    },
  },
};
