import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Redirect } from 'react-router-dom';
import { MgmtLayout } from '../../../components/layout/mgmtLayout/MgmtLayout';
import { MGMT } from '../../../helpers/constants/Routes';
import { QUERY } from '../../../helpers/constants/api';
import {
  OFFICE_EDIT_METHODS,
  officeEditMethodMap,
} from '../../../helpers/constants/officeEditMethods';
import { useArray } from '../../../helpers/hooks/useArray';
import { useRouter } from '../../../helpers/hooks/useRouter';
import { useSetState } from '../../../helpers/hooks/useSetState';
import { useEditUsersOffice } from '../../../store/api/useEditUsersOffice';
import { useFetchManager } from '../../../store/api/useFetchManager';
import type { FetchUsersResponse, OfficeEditMethod, UsersTableState } from '../../../types';
import { UsersOfficeEditTemplate } from './UsersOfficeEditTemplate';

export const UsersOfficeEditPage: React.VFC = () => {
  const {
    history,
    location: { state },
  } = useRouter<UsersTableState | null>();
  const userIdsState = state?.userIds ?? undefined;

  // ---------- api(query) ----------
  const queryClient = useQueryClient();
  const paginatedUsersData = queryClient.getQueriesData<FetchUsersResponse>([QUERY.USERS, 'list']);
  const users =
    paginatedUsersData.length === 0
      ? undefined
      : paginatedUsersData.flatMap(usersData => usersData[1].users);
  const { data: loginUser } = useFetchManager({ enabled: false });

  // ---------- 編集内容の選択 ----------
  const editMethodList = Object.values(OFFICE_EDIT_METHODS).map(method => {
    const listItem = {
      label: officeEditMethodMap.get(method) ?? '',
      value: method,
    };
    if (method === OFFICE_EDIT_METHODS.DELETE) {
      return {
        ...listItem,
        helperText: '※ユーザーの所属クリニックは１クリニック以上の登録が必要です',
      };
    }

    return listItem;
  });
  const [selectedMethod, handleSelectMethod] = useSetState<OfficeEditMethod | undefined>(undefined);

  // ---------- クリニックの選択 ----------
  const officeList =
    loginUser?.offices.map(office => ({
      label: office.name,
      value: office.id,
      mutable: true,
    })) ?? [];
  const [selectedOfficeIds, { toggle: toggleSelectOfficeId }] = useArray<number>([]);

  // ---------- 編集するユーザー ----------
  const selectedUsersList = useMemo(() => {
    if (!users || !userIdsState) return [];

    return userIdsState.map(userId => ({
      label: users.find(user => user.id === userId)?.name ?? '',
      value: userId,
      mutable: true,
    }));
  }, [userIdsState, users]);

  // ---------- api(mutation) ----------
  const isSubmitAble = selectedMethod !== undefined && selectedOfficeIds.length > 0;
  const { mutate } = useEditUsersOffice({
    onSuccess: () => history.push(`/${MGMT.USERS}`),
  });
  const onSubmit = () => {
    mutate({
      editMethod: selectedMethod || OFFICE_EDIT_METHODS.ADD,
      officeIds: selectedOfficeIds,
      userIds: userIdsState ?? [],
    });
  };

  if (selectedUsersList.length === 0) {
    return <Redirect to={`/${MGMT.USERS}`} />;
  }

  return (
    <MgmtLayout documentTitle="所属クリニック編集">
      <UsersOfficeEditTemplate
        editMethod={{ editMethodList, selectedMethod, handleSelectMethod }}
        office={{
          officeList,
          selectedOfficeIds,
          toggleSelectOfficeId,
        }}
        selectedUserList={selectedUsersList}
        submit={{
          isSubmitAble,
          onSubmit,
        }}
      />
    </MgmtLayout>
  );
};
