import { TableHead as MuiTableHead, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { HeaderGroup } from 'react-table';
import { isActionColumn } from '../../../helpers/utils/isActionColumn';

interface Props<TTableData extends Record<string, unknown>> {
  headerGroups: HeaderGroup<TTableData>[];
}

const useStyles = makeStyles(theme => ({
  tableHeadCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    fontWeight: 700,
    lineHeight: 1.214,
    ...theme.mixins.ellipsis,
  },
  tableHeadCellPaddingCheckbox: {
    width: 48, // default
    padding: theme.spacing(1.5, 0.5, 1.5, 1),
  },
}));

export const TableHead = <TTableData extends Record<string, unknown>>({
  headerGroups,
}: Props<TTableData>): React.ReactElement => {
  const classes = useStyles();

  return (
    <MuiTableHead>
      {headerGroups.map(headerGroup => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <TableCell
              className={classes.tableHeadCell}
              classes={{
                paddingCheckbox: classes.tableHeadCellPaddingCheckbox,
              }}
              padding={isActionColumn(column.id) ? 'checkbox' : 'normal'}
              {...column.getHeaderProps()}
            >
              {column.render('Header')}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </MuiTableHead>
  );
};
