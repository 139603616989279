declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    ellipsis: {
      overflow: React.CSSProperties['overflow'];
      textOverflow: React.CSSProperties['textOverflow'];
      whiteSpace: React.CSSProperties['whiteSpace'];
    };
  }
  interface MixinsOptions {
    ellipsis?: {
      overflow?: React.CSSProperties['overflow'];
      textOverflow?: React.CSSProperties['textOverflow'];
      whiteSpace?: React.CSSProperties['whiteSpace'];
    };
  }
}

export const mixins = {
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
} as const;
