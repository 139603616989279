import type { AxiosResponse } from 'axios';
import { type UseQueryOptions, type UseQueryResult, useQuery } from 'react-query';
import { ERROR } from '../../helpers/constants/ErrorMessages';
import { QUERY } from '../../helpers/constants/api';
import { useApiResponseHandler } from '../../helpers/hooks/useApiResponseHandler';
import type { AxiosApiError, FetchAdminMgmtUsersResponse } from '../../types';
import { mgmtAxios } from './share/axios';

type Data = FetchAdminMgmtUsersResponse;
type UseFetchAdminMgmtUsers = (
  options?: UseQueryOptions<Data, AxiosApiError, Data, string[]>,
) => UseQueryResult<Data, AxiosApiError>;

export const useFetchAdminMgmtUsers: UseFetchAdminMgmtUsers = options => {
  const { handleError } = useApiResponseHandler();
  const queryFn = async () => {
    const res: AxiosResponse<Data> = await mgmtAxios.get('/admin/mgmt-users');

    return res.data;
  };
  const query = useQuery([QUERY.MGMT_USERS], queryFn, {
    onError: (err: AxiosApiError) => {
      handleError(ERROR.FETCH_MGMT_USERS, err.response?.data ?? undefined);
    },
    ...options,
  });

  return query;
};
