import type { AxiosResponse } from 'axios';
import {
  type UseMutationOptions,
  type UseMutationResult,
  useMutation,
  useQueryClient,
} from 'react-query';
import { ERROR } from '../../helpers/constants/ErrorMessages';
import { QUERY } from '../../helpers/constants/api';
import { useApiResponseHandler } from '../../helpers/hooks/useApiResponseHandler';
import { omitOptionalProperties } from '../../helpers/omitOptionalProperties';
import type { AxiosApiError, UpdateUserVariables } from '../../types';
import { mgmtAxios } from './share/axios';

type Variables = UpdateUserVariables;
type Data = unknown;
type UseUpdateUser = (
  options?: UseMutationOptions<Data, AxiosApiError, Variables>,
) => UseMutationResult<Data, AxiosApiError, Variables>;

export const useUpdateUser: UseUpdateUser = options => {
  const { handleError, handleSuccess } = useApiResponseHandler();
  const queryClient = useQueryClient();
  const mutationFn = async (variables: Variables) => {
    const res: AxiosResponse<Data> = await mgmtAxios.post(
      `/users/${variables.userId}`,
      omitOptionalProperties(variables),
    );

    return res.data;
  };

  return useMutation(mutationFn, {
    onError: (err: AxiosApiError) => {
      handleError(ERROR.UPDATE_USER, err.response?.data ?? undefined);
    },
    onSettled: (_, err) => {
      if (!err) {
        handleSuccess('ユーザー情報を変更しました');
      }

      return queryClient.invalidateQueries([QUERY.USERS]);
    },
    ...options,
  });
};
