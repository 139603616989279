import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '../components/theme/ThemeProvider';
import { combineProviders } from '../helpers/factory/combineProviders';
import { QueryProvider } from './api/share/QueryProvider';
import { AlertDialogProvider } from './repository/alertDialog';
import { SnackbarProvider } from './repository/snackbar';

const HelmetProviderFC: React.FC = ({ children }) => <HelmetProvider>{children}</HelmetProvider>;

export const Providers: React.FC = ({ children }) => {
  const providersArray = [
    QueryProvider,
    ThemeProvider,
    HelmetProviderFC,
    SnackbarProvider,
    AlertDialogProvider,
  ];
  const CombinedProviders = combineProviders(providersArray);

  return <CombinedProviders>{children}</CombinedProviders>;
};
