import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../components/ui/Button/Button';
import { FormLabelFieldsWrapper } from '../../../components/ui/Form/FormLabelFieldsWrapper';
import { FormLabelSelectWithDialog } from '../../../components/ui/Form/FormLabelSelectWithDialog';
import { FormLabelTextField } from '../../../components/ui/Form/FormLabelTextField';
import { PageTitle } from '../../../components/ui/PageTitle/PageTitle';
import { Timestamps } from '../../../components/ui/Timestamps/Timestamps';
import { YesOrNoButton } from '../../../components/ui/YesOrNoButton/YesOrNoButton';
import { MGMT } from '../../../helpers/constants/Routes';
import { useDeleteConfirm } from '../../../helpers/hooks/useDeleteConfirm';
import { useOfficeSelectMaxHeight } from '../../../helpers/hooks/useOfficeSelectMaxHeight';
import { useRouter } from '../../../helpers/hooks/useRouter';
import { SAVE_USER_FORM_MAX } from '../../../helpers/validator/useSaveUserForm';
import type { ListItem } from '../../../types';
import type { Timestamps as TimestampsType } from '../../../utilityTypes';

interface Props {
  isManager: boolean;
  officeList: ListItem[];
  timestamps: TimestampsType;
  submit: {
    isSubmitAble: boolean;
    onSubmit: () => void;
  };
  kanaInput: {
    kanaInputValue: string;
    handleConvertToKana: (e: React.FocusEvent<HTMLInputElement>) => void;
  };
  handleDelete: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridAutoRows: 'auto 1fr auto',
    height: '100%',
  },
  pageTitleAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  pageTitle: {
    flex: 1,
  },
  deleteWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const UserEditTemplate: React.FC<Props> = ({
  isManager,
  submit,
  kanaInput,
  officeList,
  timestamps,
  handleDelete,
}) => {
  const classes = useStyles();
  const { control } = useFormContext();
  const {
    history,
    location: { state },
  } = useRouter<{ userIds: number; pageIndex: number }>();
  const officeSelectMaxHeight = useOfficeSelectMaxHeight();

  // delete
  const [DeleteConfirmDialog, handleOpen] = useDeleteConfirm();

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.pageTitleAndButton} mb={4}>
          <PageTitle>編集</PageTitle>
          <YesOrNoButton
            yesLabel="登録"
            yesButtonProps={{
              variant: submit.isSubmitAble ? 'contained' : 'text',
              disabled: !submit.isSubmitAble,
              size: 'large',
              onClick: () => submit.onSubmit(),
            }}
            noButtonProps={{ size: 'large', onClick: () => history.push(`/${MGMT.USERS}`, state) }}
          />
        </Box>
        <Box mb={2}>
          <FormLabelFieldsWrapper>
            <FormLabelTextField control={control} label="名前" name="name" required />
            <FormLabelTextField
              control={control}
              label="フリガナ"
              name="kana"
              required
              formHelperText="カタカナで入力してください"
              filledTextFieldProps={{
                value: kanaInput.kanaInputValue,
                onBlur: kanaInput.handleConvertToKana,
              }}
            />
            <FormLabelSelectWithDialog
              control={control}
              label="所属クリニック"
              name="officeIds"
              required
              list={officeList}
              isSelectable={!isManager}
              maxHeight={officeSelectMaxHeight}
            />
            <FormLabelTextField control={control} label="メールアドレス" name="email" required />
            <FormLabelTextField
              control={control}
              label="メモ"
              name="memo"
              max={SAVE_USER_FORM_MAX.MEMO}
              filledTextFieldProps={{
                multiline: true,
                minRows: 4,
                placeholder: `${SAVE_USER_FORM_MAX.MEMO}文字以内で入力してください`,
              }}
            />
          </FormLabelFieldsWrapper>
        </Box>
        <div>
          <Box mb={2}>
            <Timestamps createdAt={timestamps.createdAt} updatedAt={timestamps.updatedAt} />
          </Box>
          <div className={classes.deleteWrapper}>
            <Button variant="text" color="danger" onClick={() => handleOpen()}>
              アカウント削除
            </Button>
          </div>
        </div>
      </div>
      <DeleteConfirmDialog
        title="このユーザーを削除しますか？"
        message="ユーザーを削除すると、ユーザーに関する情報が全て削除され元に戻せなくなります。"
        handleDelete={handleDelete}
      />
    </>
  );
};
