import * as ErrorMessages from '../../helpers/constants/ErrorMessages';
import type * as RequestTypes from './Requests';

export const LoginValidator: (input: RequestTypes.LoginRequestType) => string = (
  input: RequestTypes.LoginRequestType,
) => {
  if (input.userId === '' || input.userPassword === '') {
    return ErrorMessages.LOGIN_ERROR;
  }

  return '';
};

export const ForgotPasswordValidator: (input: RequestTypes.ForgotPasswordRequestType) => string = (
  input: RequestTypes.ForgotPasswordRequestType,
) => {
  if (input.userEmail === '') {
    return ErrorMessages.FORGOT_PASSWORD_ERROR;
  }

  return '';
};

export const ConfirmForgotPasswordValidator: (
  input: RequestTypes.ConfirmForgotPasswordRequestType,
) => string = (input: RequestTypes.ConfirmForgotPasswordRequestType) => {
  if (input.confirmationCode === '') {
    return ErrorMessages.CONFIRM_FORGOT_PASSWORD_ERROR.noConfirmationCode;
  }
  // パスワードと確認用パスワードが一致しているかの確認
  if (input.password !== input.confirmPassword) {
    return ErrorMessages.CONFIRM_FORGOT_PASSWORD_ERROR.notMatchPassword;
  }
  // パスワードポリシーをクリアしているかの確認
  if (
    input.password.length < 8 ||
    input.password.length > 12 ||
    !/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/i.exec(input.password)
  ) {
    return ErrorMessages.CONFIRM_FORGOT_PASSWORD_ERROR.notFullFillPasswordPolicy;
  }

  return '';
};
