import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  dateAndTime: string;
}

const useStyles = makeStyles(theme => ({
  dateAndTime: {
    display: 'inline',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

export const DateAndTime: React.VFC<Props> = ({ dateAndTime }) => {
  const classes = useStyles();
  const [date, time] = dateAndTime.split(' ');

  return (
    <div>
      <Typography className={classes.dateAndTime} variant="body2">
        {/* 改行しない場合は時刻との間に半角スペースを入れる */}
        {`${date} `}
      </Typography>
      <Typography className={classes.dateAndTime} variant="body2">
        {time}
      </Typography>
    </div>
  );
};
