import { makeStyles } from '@material-ui/core/styles';
import { Button, type ButtonProps } from '../Button/Button';

interface Props {
  yesLabel?: string;
  yesButtonProps: ButtonProps;
  noLabel?: string;
  noButtonProps: ButtonProps;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    columnGap: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
}));

export const YesOrNoButton: React.VFC<Props> = ({
  yesLabel = '保存',
  yesButtonProps,
  noLabel = 'キャンセル',
  noButtonProps,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button variant="text" color="primary" {...noButtonProps}>
        {noLabel}
      </Button>
      <Button variant="contained" color="primary" {...yesButtonProps}>
        {yesLabel}
      </Button>
    </div>
  );
};
