import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import Div100vh from 'react-div-100vh';
import { Helmet } from 'react-helmet-async';
import { USER_TYPES } from '../../../helpers/constants/user';
import { useRouter } from '../../../helpers/hooks/useRouter';
import { useFetchManager } from '../../../store/api/useFetchManager';
import { IconButton } from '../../ui/IconButton/IconButton';

interface Props {
  documentTitle: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minWidth: theme.breakpoints.values.sm,
    overflowX: 'hidden',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  main: {
    flexGrow: 1,
  },
  content: {
    display: 'grid',
    gridAutoRows: 'auto 1fr',
    height: '100%',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    overflow: 'hidden',
  },
  iconButton: {
    marginRight: theme.spacing(2),
  },
}));

export const MgmtAdminLayout: React.FC<Props> = ({ children, documentTitle }) => {
  const classes = useStyles();
  const { history } = useRouter<{ userIds: number[]; pageIndex: number }>();

  // ---------- api(query) ----------
  const { data } = useFetchManager();
  const isEnableGoBack = data && data.userType !== USER_TYPES.ADMIN;

  return (
    <>
      <Helmet>
        <title>{`${documentTitle} | Apotool & Box for Dentist`}</title>
      </Helmet>
      <Div100vh className={classes.root}>
        <AppBar className={classes.appBar} position="fixed" elevation={1}>
          <Toolbar>
            {isEnableGoBack && (
              <IconButton
                className={classes.iconButton}
                color="inherit"
                onClick={() => history.go(-1)}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Typography variant="h5">管理ユーザー選択画面(Admin専用)</Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.main}>
          <div className={classes.content}>
            <Toolbar />
            {children}
          </div>
        </main>
      </Div100vh>
    </>
  );
};
