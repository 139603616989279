import React, { type FC, createContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type { RedirectAppType } from '../models/RedirectUrl';
import type { Session } from '../models/Session';

const redirectAppUrlList: RedirectAppType = {
  interview: process.env.REACT_APP_INTERVIEW_URL as string,
  regi: `${process.env.REACT_APP_APOTOOL_URL as string}regi/`,
  medbox: process.env.REACT_APP_MEDBOX_URL as string,
};

const redirectAppDomainList: RedirectAppType = {
  interview: process.env.REACT_APP_INTERVIEW_APP_NAME as string,
  regi: process.env.REACT_APP_APOTOOL_DOMAIN as string,
  medbox: process.env.REACT_APP_MEDBOX_APP_NAME as string,
};

const redirectAppNameList: RedirectAppType = {
  interview: 'web問診票',
  regi: 'お会計さん',
  medbox: 'Medical Box',
};

type RedirectContextType = {
  query: string;
  appQuery: string;
  session: Session;
  redirectQuery: string;
  statusQuery: string;
  redirectAppNameList: RedirectAppType;
  logoutMessage: string;
  setLogoutMessage: (s: string) => void;
  setSession: (s: Session) => void;
  redirectToApp: (toApotool?: boolean, ses?: Session) => void;
  redirectHandler: (route: string, noQuery?: boolean) => void;
};

export const RedirectContext = createContext<RedirectContextType>({} as RedirectContextType);

export const RedirectContextProvider: FC = ({ children }) => {
  const { pathname, search: query } = useLocation();
  const [session, setSession] = useState<Session>({} as Session);
  const [logoutMessage, setLogoutMessage] = useState<string>('');
  const history = useHistory();

  const queryExtract = (name: string) => {
    const cutName = `${name}=`;
    const cutIndex = query.indexOf(cutName);
    let cutQuery = cutIndex !== -1 ? query.slice(cutIndex + cutName.length) : '';

    const ampersandIndex = cutQuery.indexOf('&');
    cutQuery = ampersandIndex !== -1 ? cutQuery.substring(0, ampersandIndex) : cutQuery;

    return cutQuery;
  };

  const appQuery = queryExtract('app');
  const redirectQuery = queryExtract('redirect'); // encodeされてる
  const statusQuery = queryExtract('status');

  const isSameDomain = () =>
    redirectAppDomainList[appQuery] &&
    decodeURIComponent(redirectQuery).includes(redirectAppDomainList[appQuery]);

  const redirectToApp: (toApotool?: boolean, ses?: Session) => void = (
    toApotool = false,
    ses = session,
  ) => {
    const sessionQuery = `sessionId=${encodeURIComponent(
      ses?.sessionId,
    )}&signature=${encodeURIComponent(ses?.signature)}`;

    // クエリがない or toApotoolの場合はApotoolにリダイレクト
    if ((!query && !redirectQuery) || toApotool) {
      window.location.href = `${process.env.REACT_APP_APOTOOL_URL as string}login/?${sessionQuery}`;

      return;
    }

    // queryがある場合は各アプリにリダイレクト
    // redirectQueryがある場合はドメインがあってるかをチェック
    if ((redirectQuery && isSameDomain()) || (!appQuery && redirectQuery)) {
      if (decodeURIComponent(redirectQuery).includes('/select')) {
        window.location.href = `${decodeURIComponent(redirectQuery)}/?${sessionQuery}`;
      } else {
        window.location.href = `${decodeURIComponent(redirectQuery)}`;
      }

      return;
    }

    // queryの値がlistにない場合、Apotoolにリダイレクト
    if (appQuery === 'regi') {
      window.location.href = `${redirectAppUrlList[appQuery]}login/?${sessionQuery}`;
    } else if (!redirectAppUrlList[appQuery]) {
      window.location.href = `${process.env.REACT_APP_APOTOOL_URL as string}login/?${sessionQuery}`;
    } else {
      window.location.href = `${redirectAppUrlList[appQuery]}`;
    }
  };

  const redirectHandler: (route: string, noQuery?: boolean) => void = (route, noQuery = false) => {
    history.push(`/${route}${noQuery ? '' : query}`);
  };

  useEffect(() => {
    // appQueryがあるが、その中身が予期せぬ物だった場合
    if (appQuery && redirectQuery && !isSameDomain()) {
      window.location.href = `${pathname}`;
    }
  }, []);

  return (
    <RedirectContext.Provider
      value={{
        query,
        appQuery,
        redirectQuery,
        statusQuery,
        redirectAppNameList,
        session,
        logoutMessage,
        setLogoutMessage,
        setSession,
        redirectToApp,
        redirectHandler,
      }}
    >
      {children}
    </RedirectContext.Provider>
  );
};
