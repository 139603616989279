import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

export const ToggleSwitch = styled.div`
  height: 42px;
  position: relative;
  width: 75px;
`;

export const Toggle = styled.input`
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;

  &:checked {
    + label {
      background-color: ${ds.brand('main')};

      &::after {
        left: 40px;
      }
    }
  }
`;

export const Switch = styled.label`
  background: ${ds.color('grey', '4')};
  border-radius: 46px;
  box-sizing: border-box;
  display: inline-block;
  height: 42px;
  position: relative;
  transition: 0.4s;
  width: 75px;

  &::after {
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    content: '';
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    transition: 0.4s;
    width: 42px;
    z-index: 2;
  }
`;
