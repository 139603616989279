export const LOGIN_ERROR = 'ログイン情報を入力してください';
export const FORGOT_PASSWORD_ERROR = 'メールアドレスを入力してください';
export const CONFIRM_FORGOT_PASSWORD_ERROR = {
  noConfirmationCode: '確認コードを入力してください',
  notMatchPassword: 'パスワードが一致していません',
  notFullFillPasswordPolicy: 'パスワードは半角英数字8~12文字で設定してください',
};

export const ERROR = {
  // query & mutation
  FETCH_MANAGER: 'ログイン中のユーザ情報取得に失敗しました',
  FETCH_USERS: 'ユーザー一覧の情報取得に失敗しました',
  FETCH_USER: 'ユーザーの情報取得に失敗しました',
  CREATE_USER: 'ユーザーの新規作成に失敗しました',
  UPDATE_USER: 'ユーザーの更新に失敗しました',
  DELETE_USER: 'ユーザーの削除に失敗しました',
  EDIT_USERS_OFFICE: '所属クリニックの変更に失敗しました',
  FETCH_OFFICES: 'クリニックの情報取得に失敗しました',
  FETCH_USER_LOGS: 'ログイン履歴の情報取得に失敗しました',
  SELECT_ACCOUNT: 'アカウントの選択に失敗しました',
  FETCH_MGMT_USERS: '管理ユーザー一覧の情報取得に失敗しました',
  // others
  ERROR_IS_UNDEFINED: '何らかのエラーが発生しました',
  INTERNAL_SERVER_ERROR: 'サーバーエラーが発生しました',
} as const;

export type ErrorType = (typeof ERROR)[keyof typeof ERROR];

export const ERROR_CODE = {
  // 共通
  INVALID_PARAMETER: 'InvalidParameter',
  INTERNAL_SERVER_ERROR: 'InternalServerError',
  UNAUTHORIZED: 'Unauthorized',

  // ログイン・認証画面
  INVALID_CONFIRM_CODE: 'InvalidConfirmCode',
  LIMIT_EXCEEDED: 'LimitExceeded',
  INVALID_PASSWORD: 'InvalidPassword',
  NOT_ACCESSIBLE_BY_ACCOUNTANT: 'NotAccessibleByAccountant',
  USER_NOT_MIGRATED: 'UserNotMigrated',

  // ユーザー管理画面
  INVALID_OFFICE_SELECT: 'InvalidOfficeSelect',
  EMAIL_ALREADY_EXISTS: 'EmailAlreadyExists',
  USER_NOT_FOUND: 'UserNotFound',

  // 以下はAPIが返すエラーコードではなく、フロント側で定義しているエラーコード
  SESSION_INVALID: 'SessionInvalid',
} as const;

export type ErrorCode = (typeof ERROR_CODE)[keyof typeof ERROR_CODE];

/**
 * InvalidParameterの場合は、個別に設定したエラーメッセージを用いる
 * Unauthorizedの場合は、ログイン中のユーザーの情報取得のエンドポイント以外は何もしない
 * InternalSeverErrorの場合は、重要度が高いのでErrorBoundaryを用いる
 */
export const errorCodeFeedbackMap: ReadonlyMap<ErrorCode, string> = new Map([
  [ERROR_CODE.INVALID_OFFICE_SELECT, '指定されたクリニックは選択できません'],
  [ERROR_CODE.EMAIL_ALREADY_EXISTS, '同じメールアドレスを持つユーザーがすでに存在しています'],
  [
    ERROR_CODE.USER_NOT_FOUND,
    '指定されたユーザーは見つかりません。すでに削除されている可能性があります',
  ],
]);

export const DEFECT_SEVERITY = {
  CRITICAL: 'CRITICAL', // 機能が全く使えない
  MAJOR: 'MAJOR', // 主要な機能が使えない
  MINOR: 'MINOR', // 補助的な機能が使えない
  TRIVIAL: 'TRIVIAL', // 機能は使える
} as const;

export type DefectSeverity = (typeof DEFECT_SEVERITY)[keyof typeof DEFECT_SEVERITY];

export class CustomError extends Error {
  readonly defectSeverity: DefectSeverity;

  constructor(message: string, defectSeverity: DefectSeverity) {
    super();
    this.message = message;
    this.defectSeverity = defectSeverity;
  }
}
