import { makeStyles } from '@material-ui/core/styles';
import { FORM_GRID_NAMES } from '../../../helpers/constants/layout';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: `[${FORM_GRID_NAMES.LABEL}] auto [${FORM_GRID_NAMES.FORM}] 1fr`,
    gridAutoRows: 'min-content',
    gap: theme.spacing(3),
  },
}));

export const FormLabelFieldsWrapper: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};
