import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

type Paper = {
  width?: string;
  height?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  align?: string;
  theme?: { bgcolor: string };
};

export const Paper = styled.div<Paper>`
  background-color: ${ds.brand('white')};
  border: none;
  height: ${props => props.height || '100%'};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.ml};
  margin-right: ${props => props.mr};
  margin-top: ${props => props.mt};
  padding-bottom: ${props => props.pb};
  padding-left: ${props => props.pl};
  padding-right: ${props => props.pr};
  padding-top: ${props => props.pt};
  text-align: ${props => props.align};
  width: ${props => props.width || '100%'};

  &.shadow {
    box-shadow: ${ds.get('shadow.1')};
  }

  &.card {
    border-radius: ${ds.get('radius')};
  }

  &.modal {
    position: fixed;
    z-index: ${ds.z('modal')};
  }

  &.draggable {
    :hover {
      box-shadow: ${ds.get('shadow.2')};
      cursor: grab;
    }
  }

  &.no-border {
    border: none;
  }

  &.theme {
    background-color: ${props => props.theme.bgcolor || ds.brand('white')};
  }

  &.bd-round {
    border-radius: 0.5em;
  }

  &.amber-shadow {
    box-shadow: 6px 4px 6px -2px ${ds.color('amber', '700')};
  }
`;
