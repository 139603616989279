export const AUTH = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  OFFICE_LIST: 'office',
  FORGOT_PASSWORD: 'forgot-password',
  CONFIRM_FORGOT_PASSWORD: 'confirm-forgot-password',
} as const;

export const MGMT = {
  AUTH_ERROR: 'mgmt/auth-error',
  REDIRECT_TO_USERS: 'mgmt',
  USERS: 'mgmt/users',
  USER_CREATE: 'mgmt/user/create',
  USER_EDIT: 'mgmt/user/edit',
  USER_OFFICE_EDIT: 'mgmt/users/office-edit',
  OFFICES: 'mgmt/offices',
  USER_LOGS: 'mgmt/user-logs',
  ADMIN_MGMT_USERS: 'mgmt/admin/mgmt-users',
} as const;
