import { useCallback, useState } from 'react';
import type { CustomError } from 'types';

type ErrorHandler = (newError: CustomError | Error) => void;

export const useErrorHandler = (givenError?: CustomError | Error): ErrorHandler => {
  const [error, setError] = useState<unknown>(null);
  if (givenError != null) throw givenError;
  if (error != null) throw error;

  const handleSetError = useCallback((newError: CustomError | Error) => setError(newError), []);

  return handleSetError;
};
