import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

type Label = {
  fw?: string;
  fs?: string;
};

export const Label = styled.label<Label>`
  font-size: ${props => props.fs || '0.875rem'};
  font-weight: ${props => props.fw || '400'};
  letter-spacing: 0.01071em;

  > span {
    color: ${ds.brand('danger')};
    font-size: 20px;
    font-weight: bold;
    margin-left: 4px;
  }
`;
type Input = {
  width?: string;
  mdwidth?: string;
  smwidth?: string;
  focuscolor?: string;
};

export const Input = styled.input<Input>`
  font-family: inherit;
  font-size: 1em;
  height: 2em;
  padding-left: 0.5em;
  width: ${props => props.width || '100%'};

  &.outlined {
    background-image: -webkit-linear-gradient(
      left,
      ${ds.color('grey', '1')},
      ${ds.color('grey', '2')}
    );
    border: none;
    border-radius: ${ds.get('radius')};
    box-shadow: inset 0 1px 4px 0 ${ds.color('grey', '7')};
    height: 3em;
    outline: none;
    text-indent: 2px;
    transition: border-bottom 0.2s ease;

    &:focus {
      box-shadow: inset 0 2px 4px 0 ${props => props.focuscolor || ds.color('amber', '900')};
    }
  }

  &.underlined {
    border: none;
    border-bottom: 1px solid ${ds.color('grey', '3')};
    outline: none;
    transition: border-bottom 0.2s ease;

    &:focus {
      border-bottom: 1px solid ${props => props.focuscolor || ds.color('amber', '900')};
    }
  }

  &.disabled {
    background-color: ${ds.brand('muted')};
  }

  &.md {
    @media (min-width: ${ds.bp('sm')}) and (max-width: ${ds.bp('md')}) {
      width: ${props => props.mdwidth || '100%'};
    }
  }

  &.sm {
    @media (max-width: ${ds.bp('sm')}) {
      width: ${props => props.smwidth || '100%'};
    }
  }

  &.no-border {
    border: none;
  }
`;
type TextArea = {
  focuscolor?: string;
};

export const TextArea = styled.textarea<TextArea>`
  font-size: 1em;
  height: 150px;
  max-width: 1500px; /* 改行対応 */
  overflow-wrap: break-word;
  padding: 8px;
  width: 100%;
  word-break: break-all;

  &.outlined {
    background-image: -webkit-linear-gradient(
      left,
      ${ds.color('grey', '1')},
      ${ds.color('grey', '2')}
    );
    border: none;
    border-radius: ${ds.get('radius')};
    box-shadow: inset 0 1px 4px 0 ${ds.color('grey', '7')};
    outline: none;
    text-indent: 2px;

    &:focus {
      box-shadow: inset 0 2px 4px 0 ${props => props.focuscolor || ds.color('amber', '900')};
      transition: 0.1s;
    }
  }

  &.underlined {
    border-bottom: 1px solid ${ds.color('grey', '4')};
  }

  &.disabled {
    background-color: ${ds.brand('muted')};
  }
`;

type TextFieldProps = {
  width?: string;
  as?: string;
};

// 医院側の編集画面では、患者入力欄をTestArea&Inputで、それ以外をTextFieldで実装している

export const TextField = styled(Input)<TextFieldProps>`
  border: none;
  border-bottom: 1px solid ${ds.color('grey', '4')};
  cursor: pointer;
  outline: none;
  text-decoration: none;
  width: ${props => props.width || '50%'};

  &:hover {
    cursor: text;
  }

  &:focus {
    border-bottom: 1px solid ${ds.brand('main')};
    cursor: text;
  }

  &.default {
    border-bottom: none;
  }

  &.textarea {
    background-color: white;
    border: 1px solid ${ds.color('grey', '4')};
    border-radius: 4px;
    box-sizing: border-box;
    height: 120px;
    padding: 0.5em;

    &:hover {
      cursor: text;
    }

    &:focus {
      border: 1px solid ${ds.brand('main')};
      cursor: text;
    }
  }
`;
