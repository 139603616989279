import { Box, Typography } from '@material-ui/core';
import { DateAndTime } from 'components/ui/DateAndTime/DateAndTime';
import { Tooltip } from 'components/ui/Tooltip/Tooltip';
import { UserActivityLabel } from 'components/ui/UserActivityLabel/UserActivityLabel';
import type { CellProps } from 'react-table';
import type { UserActivity, UserLogsTableData } from 'types';

const DateColumn = {
  Header: '日付',
  accessor: 'createdAt',
  Cell: ({ value: createdAt }: CellProps<UserLogsTableData, string>): React.ReactElement => (
    <Tooltip variant="intrinsic" title={createdAt} isWrappedInScroll>
      <DateAndTime dateAndTime={createdAt} />
    </Tooltip>
  ),
  maxWidth: 130,
};

const officeNameColumn = {
  Header: 'クリニック名',
  accessor: 'officeName',
  maxWidth: 200,
};

const ipAddressColumn = {
  Header: 'IPアドレス',
  accessor: 'ipAddress',
  maxWidth: 130,
};

const ActivityColumn = {
  Header: '',
  accessor: 'activity',
  Cell: ({
    value: userActivity,
  }: CellProps<UserLogsTableData, UserActivity>): React.ReactElement => (
    <Box display="flex" justifyContent="flex-end">
      <UserActivityLabel userActivity={userActivity} />
    </Box>
  ),
  maxWidth: 90,
};

export const ColumnsUserInformationCombined = [
  DateColumn,
  {
    Header: 'ユーザー情報',
    accessor: 'userInformation',
    Cell: ({
      value: userInformation,
    }: CellProps<UserLogsTableData, { name: string; email: string }>): React.ReactElement => (
      <Tooltip variant="standard" title={userInformation.email} isWrappedInScroll>
        <div>
          <Typography variant="body2">{userInformation.name}</Typography>
          <Typography variant="body2" noWrap>
            {userInformation.email}
          </Typography>
        </div>
      </Tooltip>
    ),
    maxWidth: 200,
  },
  officeNameColumn,
  ipAddressColumn,
  ActivityColumn,
];

export const ColumnsUserInformationSeparated = [
  DateColumn,
  {
    Header: 'ユーザー名',
    accessor: 'userName',
    maxWidth: 200,
  },
  {
    Header: 'メールアドレス',
    accessor: 'userEmail',
    maxWidth: 200,
  },
  officeNameColumn,
  ipAddressColumn,
  ActivityColumn,
];
