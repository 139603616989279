import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

type Margin = {
  p?: string;
  pt?: string;
  pb?: string;
  pr?: string;
  pl?: string;

  rp?: string;
  rpt?: string;
  rpb?: string;
  rpr?: string;
  rpl?: string;

  m?: string;
  mt?: string;
  mb?: string;
  mr?: string;
  ml?: string;

  mx?: string;

  rm?: string;
  rmt?: string;
  rmb?: string;
  rmr?: string;
  rml?: string;

  // breakpointを指定する場合は最優先になる
  mdrm?: string;
  mdml?: string;
  mdmr?: string;
  mdmt?: string;
  mdmb?: string;
};
export const Margin = styled.div<Margin>`
  margin: ${props => props.m};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.mx};
  margin-left: ${props => props.ml};
  margin-right: ${props => props.mx};
  margin-right: ${props => props.mr};
  margin-top: ${props => props.mt};
  padding: ${props => props.p};
  padding-bottom: ${props => props.pb};
  padding-left: ${props => props.pl};
  padding-right: ${props => props.pr};
  padding-top: ${props => props.pt};

  &.xs {
    @media (max-width: ${ds.bp('xs')}) {
      margin: ${props => props.rm};
      margin-bottom: ${props => props.rmb};
      margin-left: ${props => props.rml};
      margin-right: ${props => props.rmr};
      margin-top: ${props => props.rmt};
      padding: ${props => props.rp};
      padding-bottom: ${props => props.rpb};
      padding-left: ${props => props.rpl};
      padding-right: ${props => props.rpr};
      padding-top: ${props => props.rpt};
    }
  }

  &.sm {
    @media (max-width: ${ds.bp('sm')}) {
      margin: ${props => props.rm};
      margin-bottom: ${props => props.rmb};
      margin-left: ${props => props.rml};
      margin-right: ${props => props.rmr};
      margin-top: ${props => props.rmt};
      padding: ${props => props.rp};
      padding-bottom: ${props => props.rpb};
      padding-left: ${props => props.rpl};
      padding-right: ${props => props.rpr};
      padding-top: ${props => props.rpt};
    }
  }

  &.md {
    @media (min-width: ${ds.bp('sm')}) and (max-width: ${ds.bp('md')}) {
      margin: ${props => props.mdrm};
      margin-bottom: ${props => props.mdmb || props.rmb};
      margin-left: ${props => props.mdml || props.rml};
      margin-right: ${props => props.mdmr || props.rmr};
      margin-top: ${props => props.mdmt || props.rmt};
      padding: ${props => props.rp};
      padding-bottom: ${props => props.rpb};
      padding-left: ${props => props.rpl};
      padding-right: ${props => props.rpr};
      padding-top: ${props => props.rpt};
    }
  }

  &.lg {
    @media (max-width: ${ds.bp('lg')}) {
      margin: ${props => props.rm};
      margin-bottom: ${props => props.rmb};
      margin-left: ${props => props.rml};
      margin-right: ${props => props.rmr};
      margin-top: ${props => props.rmt};
      padding: ${props => props.rp};
      padding-bottom: ${props => props.rpb};
      padding-left: ${props => props.rpl};
      padding-right: ${props => props.rpr};
      padding-top: ${props => props.rpt};
    }
  }

  &.xl {
    @media (max-width: ${ds.bp('xl')}) {
      margin: ${props => props.rm};
      margin-bottom: ${props => props.rmb};
      margin-left: ${props => props.rml};
      margin-right: ${props => props.rmr};
      margin-top: ${props => props.rmt};
      padding: ${props => props.rp};
      padding-bottom: ${props => props.rpb};
      padding-left: ${props => props.rpl};
      padding-right: ${props => props.rpr};
      padding-top: ${props => props.rpt};
    }
  }
`;
