import {
  Add,
  AddBox,
  ExitToApp,
  Help,
  History,
  People,
  Person,
  type SvgIconComponent,
} from '@material-ui/icons';
import { AUTH, MGMT } from '../../../../helpers/constants/Routes';
import { USER_TYPES } from '../../../../helpers/constants/user';
import type { UserType } from '../../../../types';

type SideNavItemKey =
  | typeof MGMT.USER_CREATE
  | typeof MGMT.USERS
  | typeof MGMT.OFFICES
  | typeof MGMT.USER_LOGS
  | 'manual'
  | typeof AUTH.LOGOUT
  | typeof MGMT.ADMIN_MGMT_USERS;

export interface SideNavItem {
  key: SideNavItemKey;
  name: string;
  path: string;
  Icon: SvgIconComponent;
}

interface SideNavItemWithIsAccessible extends SideNavItem {
  isAccessible: boolean;
}

export const generateSideNavItems = (
  isAdmin: boolean,
  userType: UserType | undefined,
): SideNavItem[] => {
  const SideNavItems: SideNavItemWithIsAccessible[] = [
    {
      key: MGMT.USER_CREATE,
      name: '新規登録',
      path: `/${MGMT.USER_CREATE}`,
      Icon: Add,
      isAccessible: true,
    },
    {
      key: MGMT.USERS,
      name: 'ユーザー一覧',
      path: `/${MGMT.USERS}`,
      Icon: People,
      isAccessible: true,
    },
    {
      key: MGMT.OFFICES,
      name: 'クリニック一覧',
      path: `/${MGMT.OFFICES}`,
      Icon: AddBox,
      isAccessible: userType !== USER_TYPES.MANAGER,
    },
    {
      key: MGMT.USER_LOGS,
      name: 'ログイン履歴',
      path: `/${MGMT.USER_LOGS}`,
      Icon: History,
      isAccessible: true,
    },
    {
      key: 'manual',
      name: 'マニュアル',
      path: 'https://teachme.jp/41703/f/roomCtQK06Fl',
      Icon: Help,
      isAccessible: true,
    },
    {
      key: AUTH.LOGOUT,
      name: 'ログアウト',
      path: `/${AUTH.LOGOUT}`,
      Icon: ExitToApp,
      isAccessible: true,
    },
    {
      key: MGMT.ADMIN_MGMT_USERS,
      name: 'アカウント切り替え',
      path: `/${MGMT.ADMIN_MGMT_USERS}`,
      Icon: Person,
      isAccessible: isAdmin,
    },
  ];

  return SideNavItems.filter(SNItem => SNItem.isAccessible).map(SNI => ({
    key: SNI.key,
    name: SNI.name,
    path: SNI.path,
    Icon: SNI.Icon,
  }));
};
