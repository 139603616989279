import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { use100vh } from 'react-div-100vh';
import { FormLabelFieldsWrapper } from '../../../components/ui/Form/FormLabelFieldsWrapper';
import { FormLabelList } from '../../../components/ui/Form/FormLabelList';
import { FormLabelSelect } from '../../../components/ui/Form/FormLabelSelect';
import { List } from '../../../components/ui/List/List';
import { PageTitle } from '../../../components/ui/PageTitle/PageTitle';
import { YesOrNoButton } from '../../../components/ui/YesOrNoButton/YesOrNoButton';
import { MGMT } from '../../../helpers/constants/Routes';
import { officeEditMethodMapForSubHeader } from '../../../helpers/constants/officeEditMethods';
import { useDialog } from '../../../helpers/hooks/useDialog';
import { useRouter } from '../../../helpers/hooks/useRouter';
import type {
  CheckboxListItem,
  ListItem as ListItemType,
  OfficeEditMethod,
  RadioButtonListItem,
} from '../../../types';

interface Props {
  editMethod: {
    editMethodList: RadioButtonListItem[];
    selectedMethod: OfficeEditMethod | undefined;
    handleSelectMethod: (method: OfficeEditMethod) => void;
  };
  office: {
    officeList: CheckboxListItem[];
    selectedOfficeIds: number[];
    toggleSelectOfficeId: (officeId: number) => void;
  };
  selectedUserList: ListItemType[];
  submit: {
    isSubmitAble: boolean;
    onSubmit: () => void;
  };
}

const HEIGHT_OTHER_THAN_OFFICE_LIST_AND_USER_LIST = 412;

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridAutoRows: 'auto 1fr',
    height: '100%',
  },
  pageTitleAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  formWrapper: {
    display: 'grid',
    gridAutoRows: 'auto 1fr',
    height: '100%',
  },
}));

export const UsersOfficeEditTemplate: React.VFC<Props> = ({
  editMethod,
  office,
  selectedUserList,
  submit,
}) => {
  const classes = useStyles();
  const {
    history,
    location: { state },
  } = useRouter<{ userIds: number; pageIndex: number }>();
  const [ConfirmDialog, handleOpen] = useDialog();
  const selectedOfficeList = office.officeList.filter(listItem =>
    office.selectedOfficeIds.includes(listItem.value),
  );

  // 高さ調節
  const windowHeight = use100vh() ?? 0;
  const officeListAndUserListMaxHeight = `calc(${windowHeight}px - ${HEIGHT_OTHER_THAN_OFFICE_LIST_AND_USER_LIST}px)`;
  const eachListMaxHeight = `calc(${officeListAndUserListMaxHeight} / 2)`;

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.pageTitleAndButton} mb={4}>
          <PageTitle>所属クリニック編集</PageTitle>
          <YesOrNoButton
            yesLabel="登録"
            yesButtonProps={{
              variant: submit.isSubmitAble ? 'contained' : 'text',
              disabled: !submit.isSubmitAble,
              size: 'large',
              onClick: () => handleOpen(),
            }}
            noButtonProps={{
              size: 'large',
              onClick: () => {
                history.push(`/${MGMT.USERS}`, state);
              },
            }}
          />
        </Box>
        <FormLabelFieldsWrapper>
          <FormLabelSelect
            label="編集内容の選択"
            list={editMethod.editMethodList}
            selectedValue={editMethod.selectedMethod}
            handleSelect={value => editMethod.handleSelectMethod(value as OfficeEditMethod)}
          />
          <FormLabelSelect
            label="所属クリニックの選択"
            list={office.officeList}
            selectedValues={office.selectedOfficeIds}
            toggleSelect={office.toggleSelectOfficeId}
            maxHeight={eachListMaxHeight}
          />
          <FormLabelList
            label="選択中のユーザー"
            list={selectedUserList}
            maxHeight={eachListMaxHeight}
          />
        </FormLabelFieldsWrapper>
      </div>
      <ConfirmDialog
        title="選択したユーザーの所属するクリニックを変更してもよろしいですか？"
        yesButtonProps={{ onClick: () => submit.onSubmit() }}
        disableContentPadding
      >
        <List
          list={selectedOfficeList}
          subheader={
            editMethod.selectedMethod
              ? (officeEditMethodMapForSubHeader.get(editMethod.selectedMethod) ?? '')
              : ''
          }
          wrappedInDialog
        />
      </ConfirmDialog>
    </>
  );
};
