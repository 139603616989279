import { ROWS_PER_PAGE } from 'helpers/constants/misc';

export const calcPaginationFromTo = (
  page: number,
  totalCount: number,
): { from: number; to: number } => {
  const from = ROWS_PER_PAGE * (page - 1) + 1;
  const currentPageMaxItemsCount = ROWS_PER_PAGE * page;
  const to = currentPageMaxItemsCount > totalCount ? totalCount : currentPageMaxItemsCount;

  return { from, to };
};
