import {
  type FilledTextFieldProps,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useMemo } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { FORM_GRID_NAMES, TEXT_FIELD_HEIGHT } from '../../../helpers/constants/layout';
import { FormLabel } from './FormLabel';

interface Props<TFieldValues extends FieldValues> extends UseControllerProps<TFieldValues> {
  label: string;
  required?: boolean;
  formHelperText?: string;
  filledTextFieldProps?: Omit<FilledTextFieldProps, 'variant'>;
  max?: number;
}

const useStyles = makeStyles(theme => ({
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    gridColumnStart: FORM_GRID_NAMES.LABEL,
    height: TEXT_FIELD_HEIGHT,
  },
  textFieldWrapper: {
    gridColumnStart: FORM_GRID_NAMES.FORM,
  },
  filledInput: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `${theme.palette.common.white} !important`,
    '&:after': {
      borderColor: 'transparent',
    },
    '&:before': {
      borderColor: 'transparent',
    },
    '&:hover:not(.disabled):before': {
      borderColor: 'transparent',
    },
  },
  input: {
    paddingTop: 12,
    paddingBottom: 13,
    borderRadius: 0,
  },
  multiline: {
    paddingTop: 2,
    paddingBottom: 13,
  },
  multilineDisplayCount: {
    paddingBottom: 23,
    '& > .MuiInputAdornment-root': {
      position: 'absolute',
      padding: 0,
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
  },
}));

export const FormLabelTextField = <TFieldValues extends FieldValues>({
  label,
  required = false,
  formHelperText,
  filledTextFieldProps,
  name,
  max,
  ...controllerProps
}: Props<TFieldValues>): React.ReactElement => {
  const classes = useStyles();
  const {
    field,
    formState: { errors },
  } = useController<TFieldValues>({
    name,
    ...controllerProps,
  });
  const overwriteHelperText = useMemo(() => {
    if (errors[name]) return (errors[name] as { message: string }).message;
    if (formHelperText) return formHelperText;

    return null;
  }, [errors, name, formHelperText]);

  return (
    <>
      <div className={classes.labelWrapper}>
        <FormLabel required={required} label={label} />
      </div>
      <div className={classes.textFieldWrapper}>
        <TextField
          variant="filled"
          error={Boolean(errors[name])}
          hiddenLabel
          fullWidth
          InputProps={{
            classes: {
              root: classes.filledInput,
              input: classes.input,
              multiline: clsx(classes.multiline, max && classes.multilineDisplayCount),
            },
            endAdornment: max ? (
              <InputAdornment position="end">
                <Typography
                  variant="body2"
                  color={(field.value as string).length > max ? 'error' : 'textSecondary'}
                >{`${(field.value as string).length}/ ${max}`}</Typography>
              </InputAdornment>
            ) : null,
          }}
          helperText={overwriteHelperText}
          {...field}
          {...filledTextFieldProps}
        />
      </div>
    </>
  );
};
