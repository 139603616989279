export const OFFICE_EDIT_METHODS = {
  OVERWRITE: 'Overwrite',
  ADD: 'Add',
  DELETE: 'Delete',
} as const;

export type OfficeEditMethod = (typeof OFFICE_EDIT_METHODS)[keyof typeof OFFICE_EDIT_METHODS];

export const officeEditMethodMap: ReadonlyMap<OfficeEditMethod, string> = new Map([
  [OFFICE_EDIT_METHODS.OVERWRITE, '所属クリニックの上書き'],
  [OFFICE_EDIT_METHODS.ADD, '所属クリニックの追加'],
  [OFFICE_EDIT_METHODS.DELETE, '所属クリニックの削除'],
]);

export const officeEditMethodMapForSubHeader: ReadonlyMap<OfficeEditMethod, string> = new Map([
  [OFFICE_EDIT_METHODS.OVERWRITE, '上書きする所属クリニック'],
  [OFFICE_EDIT_METHODS.ADD, '追加する所属クリニック'],
  [OFFICE_EDIT_METHODS.DELETE, '削除する所属クリニック'],
]);
