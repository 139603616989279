import { useCallback, useEffect, useRef, useState } from 'react';

const useUnmount = (fn: () => void): void => {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  // biome-ignore lint/correctness/noVoidTypeReturn: <explanation>
  return useEffect(() => () => fnRef.current(), []);
};

export const useRafState = <TState,>(
  initialState: TState | (() => TState),
): [TState, React.Dispatch<React.SetStateAction<TState>>] => {
  const frame = useRef(0);
  const [state, setState] = useState(initialState);

  const setRafState = useCallback((value: TState | ((prevState: TState) => TState)) => {
    cancelAnimationFrame(frame.current);

    frame.current = requestAnimationFrame(() => {
      setState(value);
    });
  }, []);

  useUnmount(() => {
    cancelAnimationFrame(frame.current);
  });

  return [state, setRafState];
};
