import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

// TODO: emを使う

type Heading = {
  color?: string;
  proximity?: string;
  lh?: string;
  ls?: string;
  fw?: string;
  as?: HTMLAllCollection;
  theme?: { main?: string; fontFamily?: string };
  align?: string;
};
export const Heading = styled.h1<Heading>`
  color: ${props => props.color || ds.brand('black')};
  font-weight: ${props => props.fw};
  letter-spacing: ${props => props.ls};
  margin-bottom: ${props => props.proximity};
  text-align: ${props => props.align};

  &.underline {
    border-bottom: 1px solid ${props => props.color};
  }

  &.primary {
    color: ${ds.brand('main')};
  }

  &.h1 {
    font-size: 42.667px;
    font-weight: ${props => props.fw || '700'};
    line-height: ${props => props.lh || '64px'};
  }

  &.h2 {
    font-size: 25.6px;
    font-weight: ${props => props.fw || '600'};
    line-height: ${props => props.lh || '48px'};
  }

  &.h3 {
    font-size: 18.286px;
    font-weight: ${props => props.fw || '500'};
    line-height: ${props => props.lh || '40px'};
  }

  &.h4 {
    font-size: 16.286px;
    font-weight: ${props => props.fw || '400'};
    line-height: ${props => props.lh || '32px'};
  }

  &.h5 {
    font-size: 14.286px;
    font-weight: ${props => props.fw || '300'};
    line-height: ${props => props.lh || '20px'};
  }

  &.theme {
    color: ${props => props.theme.main};
    font-family: ${props => props.theme.fontFamily};
  }

  &.sm2 {
    @media (max-width: ${ds.bp('sm')}) {
      font-size: 25.6px;
      line-height: ${props => props.lh || '28px'};
    }
  }

  &.sm3 {
    @media (max-width: ${ds.bp('sm')}) {
      font-size: 18.286px;
      line-height: ${props => props.lh || '24px'};
    }
  }

  &.sm4 {
    @media (max-width: ${ds.bp('sm')}) {
      font-size: 16px;
      line-height: ${props => props.lh || '24px'};
    }
  }
`;

type Text = {
  color?: string;
  lh?: string;
  ls?: string;
  fw?: string;
  fs?: string;
  proximity?: string;
  theme?: { main?: string; fontFamily?: string };
};
export const Text = styled.p<Text>`
  color: ${props => props.color || ds.brand('black')};
  font-size: ${props => props.fs || '16px'};
  font-weight: ${props => props.fw};
  letter-spacing: ${props => props.ls};
  line-height: ${props => props.lh || '24px'};
  margin-bottom: ${props => props.proximity};

  &.primary {
    color: ${ds.brand('main')};
  }

  &.label {
    font-size: 14.222px;
    line-height: 20px;
  }

  &.caption {
    font-size: 12.8px;
    line-height: 16px;
  }

  &.theme {
    color: ${props => props.theme.main};
    font-family: ${props => props.theme.fontFamily};
  }
`;
