import {
  HEIGHT_OTHER_THAN_OFFICE_SELECT,
  SELECTED_LIST_MIN_HEIGHT,
} from 'helpers/constants/layout';
import { use100vh } from 'react-div-100vh';

/**
 * 以下のコードは暫定的な対応です。
 * 権限管理対応のフェーズでこのコードは削除してください。
 */
export const useOfficeSelectMaxHeight = (): number => {
  const windowHeight = use100vh() ?? 0;
  const availableHeight = windowHeight - HEIGHT_OTHER_THAN_OFFICE_SELECT;
  const officeSelectMaxHeight =
    availableHeight < SELECTED_LIST_MIN_HEIGHT ? SELECTED_LIST_MIN_HEIGHT : availableHeight;

  return officeSelectMaxHeight;
};
