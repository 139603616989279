import { InputAdornment, TextField, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EventNote } from '@material-ui/icons';
import jQuery from 'jquery';
import moment, { type Moment } from 'moment';
import { useEffect, useRef } from 'react';
import './dateRangePickerBase';
import './dateRangePickerBase.css';
import { TEXT_FIELD_PADDING_SM } from '../../../helpers/constants/layout';
import { formatDate } from '../../../helpers/formatDate';
import type { DateRange } from '../../../helpers/hooks/useDateRange';

interface Props {
  dateRange: DateRange;
  handleChangeDateRange: (newDateRange: DateRange) => void;
}

interface DateRangePicker {
  startDate: Moment;
  endDate: Moment;
  setStartDate: (date: Moment) => void;
  setEndDate: (date: Moment) => void;
  off: (type: string) => DateRangePicker;
  on: (type: string, callback: (ev: unknown, picker: DateRangePicker) => void) => DateRangePicker;
}

interface jQueryWrappedInDateRangePicker {
  daterangepicker: (options: Record<string, unknown>) => DateRangePicker;
}

const useStyles = makeStyles(theme => ({
  datePlaceholder: {
    position: 'relative',
  },
  input: {
    backgroundColor: theme.palette.common.white,
  },
  inputInput: {
    padding: TEXT_FIELD_PADDING_SM,
    paddingLeft: 0,
  },
  eventIcon: {
    color: theme.palette.text.secondary,
  },
  clickArea: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
  },
}));

const MONTH_NAMES = Array.from({ length: 12 }, (_, index) => `${index + 1}月`);

export const DateRangePicker: React.VFC<Props> = ({ dateRange, handleChangeDateRange }) => {
  const classes = useStyles();

  // DateRangePickerのカレンダーの横幅がウィンドウからはみ出ると、
  // レイアウトが崩れるため以下のようにしている
  const isLarge = useMediaQuery('(min-width: 800px)', { noSsr: true });

  const inputRef = useRef<HTMLInputElement>(null);

  const toDateString = (newDateRange: DateRange): string => {
    const [startDate, endDate] = newDateRange;
    const startDateString = startDate ? formatDate(startDate, 'date') : '';
    const endDateString = endDate ? formatDate(endDate, 'date') : '';

    if (startDate && endDate) {
      return `${startDateString} - ${endDateString}`;
    }

    if (startDate) {
      return `${startDateString}以降`;
    }

    if (endDate) {
      return `${endDateString}以前`;
    }

    return '';
  };

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const $ = jQuery as unknown as (el: HTMLInputElement) => jQueryWrappedInDateRangePicker;

    $(inputRef.current)
      .daterangepicker({
        locale: {
          applyLabel: '期間を決定',
          fromLabel: '以降',
          toLabel: '以前',
          cancelLabel: 'クリア',
          format: 'YYYY/MM/DD',
          daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
          monthNames: MONTH_NAMES,
        },
        // 年月のプルダウンを表示
        showDropdowns: true,
        // カレンダーを表示
        alwaysShowCalendars: true,
        // 日付範囲の開始と終了に合わせてカレンダーを表示
        linkedCalendars: false,
        // カスタムラベルを非表示
        showCustomRangeLabel: false,
        // inputへの自動更新をやめる（以下でapply.daterangepickerイベントを処理する）
        autoUpdateInput: false,
        // ウィジェットの表示位置
        opens: isLarge ? 'center' : 'right',
        drops: 'down',
      })
      // 「期間を決定」ボタンクリックイベント処理
      .off('apply.daterangepicker')
      .on('apply.daterangepicker', (_, picker) => {
        const start = picker.startDate.toDate();
        const end = picker.endDate.toDate();
        handleChangeDateRange([start, end]);
      })
      // 「以降」ボタンクリックイベント処理
      .off('from.daterangepicker')
      .on('from.daterangepicker', (_, picker) => {
        const start = picker.startDate.toDate();
        handleChangeDateRange([start, null]);
      })
      // 「以前」ボタンクリックイベント処理
      .off('to.daterangepicker')
      .on('to.daterangepicker', (_, picker) => {
        const end = picker.endDate.toDate();
        handleChangeDateRange([null, end]);
      })
      // クリアボタンクリックイベント処理
      .off('cancel.daterangepicker')
      .on('cancel.daterangepicker', (_, picker) => {
        picker.setStartDate(moment());
        picker.setEndDate(moment());
        handleChangeDateRange([null, null]);
      });
  }, [handleChangeDateRange, isLarge]);

  return (
    <div className={classes.datePlaceholder}>
      <TextField
        ref={inputRef}
        value={toDateString(dateRange)}
        placeholder="期間を指定"
        size="small"
        variant="outlined"
        InputProps={{
          className: classes.input,
          classes: { input: classes.inputInput },
          startAdornment: (
            <InputAdornment position="start">
              <EventNote className={classes.eventIcon} />
            </InputAdornment>
          ),
          // タッチデバイスのキーボードが表示されないようにする
          readOnly: true,
        }}
      />
    </div>
  );
};
