import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

// TODO:コンポーネントに依存するスタイルはあくまでコンポーネントの中に書きたい

export const StyledTable = styled.table`
  background-color: #eee;
  border: 3px solid ${ds.color('grey', '3')};
  border-collapse: collapse;
  border-radius: ${ds.get('radius')};
  border-spacing: 0;
  color: ${ds.brand('black')};
  font-size: 1em;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;

  a {
    color: ${ds.brand('dark')};
    text-decoration: underline;

    :hover {
      color: ${ds.brand('main')};
      text-decoration: none;
    }
  }

  thead tr {
    background-color: ${ds.color('grey', '2')};
    font-weight: bold;
  }

  tbody tr:hover {
    background-color: ${ds.color('grey', '3')} !important;
  }

  tbody tr:nth-of-type(even) {
    background-color: ${ds.color('grey', '1')};
  }

  tbody tr:nth-of-type(odd) {
    background-color: ${ds.brand('base')};
  }

  th {
    border: 1px solid ${ds.color('grey', '3')};
    padding: 1.8em 1em;
    text-align: center;
  }

  td {
    border: 1px solid ${ds.color('grey', '3')};
    max-height: 50px;
    padding: 1em 1em;
    text-align: center;
    vertical-align: middle;

    &.left {
      text-align: left;
    }
  }
`;
