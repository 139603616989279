import { Box, List, Toolbar } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { AUTH, MGMT } from '../../../../helpers/constants/Routes';
import { DRAWER_WIDTH } from '../../../../helpers/constants/layout';
import { useDialog } from '../../../../helpers/hooks/useDialog';
import { useRouter } from '../../../../helpers/hooks/useRouter';
import type { UserType, UsersTableState } from '../../../../types';
import { ExpandableButton } from '../../../ui/ExpandableButton/ExpandableButton';
import { type DrawerType, ResponsiveDrawer } from '../../../ui/ResponsiveDrawer/ResponsiveDrawer';
import { type SideNavItem, generateSideNavItems } from './SideNavItems';
import { SideNavListItem } from './SideNavListItem';

interface Props {
  drawerType: DrawerType;
  isSideNavOpen: boolean;
  toggleIsSideNavOpen: () => void;
  isAdmin: boolean;
  userType: UserType | undefined;
  isLoading: boolean;
  usersTableState?: UsersTableState | null;
}

const useStyles = makeStyles(theme => ({
  list: {
    width: DRAWER_WIDTH,
    paddingRight: theme.spacing(2),
  },
}));

export const SideNav: React.VFC<Props> = ({
  drawerType,
  isSideNavOpen,
  toggleIsSideNavOpen,
  isAdmin,
  userType,
  isLoading,
  usersTableState,
}) => {
  const classes = useStyles();
  const { history } = useRouter();
  const theme = useTheme();
  // width(Fab) + marginX(Fab) + border(Drawer)
  const DRAWER_SHRINK_WIDTH = 56 + theme.spacing(2) + 1;
  const SideNavItems = useMemo(() => generateSideNavItems(isAdmin, userType), [isAdmin, userType]);
  const [ConfirmLogOutDialog, handleConfirmLogOutDialog] = useDialog();
  const handleClickNavItem = useCallback(
    (NavItem: SideNavItem) => {
      switch (NavItem.key) {
        case AUTH.LOGOUT:
          return handleConfirmLogOutDialog();
        case 'manual':
          return window.open(NavItem.path, '_blank', 'noreferrer');
        case MGMT.USER_CREATE:
          if (usersTableState) return history.push(NavItem.path, usersTableState);

          return history.push(NavItem.path);
        default:
          return history.push(NavItem.path);
      }
    },
    [handleConfirmLogOutDialog, history, usersTableState],
  );

  return (
    <ResponsiveDrawer
      drawerType={drawerType}
      drawerShrinkWidth={DRAWER_SHRINK_WIDTH}
      elevation={0}
      open={isSideNavOpen}
      onClose={toggleIsSideNavOpen}
    >
      <Toolbar />
      <Box mt={3} mb={2} mx={1}>
        <ExpandableButton
          Icon={SideNavItems[0].Icon}
          isExpanded={isSideNavOpen}
          onClick={() => handleClickNavItem(SideNavItems[0])}
        >
          {SideNavItems[0].name}
        </ExpandableButton>
      </Box>
      <List className={clsx(isSideNavOpen && classes.list)} component="nav">
        {SideNavItems.slice(1, isLoading ? 2 : SideNavItems.length).map(NavItem => (
          <SideNavListItem
            key={NavItem.name}
            NavItem={NavItem}
            drawerType={drawerType}
            isSideNavOpen={isSideNavOpen}
            handleClickNavItem={() => handleClickNavItem(NavItem)}
            isLoading={isLoading}
          />
        ))}
      </List>
      <ConfirmLogOutDialog
        title="ログアウトしますか？"
        yesLabel="ログアウト"
        yesButtonProps={{ onClick: () => history.push(`/${AUTH.LOGOUT}`) }}
      />
    </ResponsiveDrawer>
  );
};
