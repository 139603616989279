import React, { type FC } from 'react';
import Helmet from 'react-helmet';

type TitleProps = {
  title: string;
};

export const Title: FC<TitleProps> = ({ title }) => (
  <Helmet>
    <title>{`${title} | Apotool & Box for Dentist`}</title>
  </Helmet>
);
