import React, { type FC, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AUTH } from '../helpers/constants/Routes';
import { AuthContext } from '../store/repository/AuthContext';

export const ProtectedRoute: FC = props => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated() ? (
    <Route {...props} />
  ) : (
    <Route render={() => <Redirect to={`/${AUTH.LOGIN}`} />} />
  );
};
