import type { AxiosResponse } from 'axios';
import { useEffect, useMemo } from 'react';
import { type UseQueryOptions, type UseQueryResult, useQuery } from 'react-query';
import { ERROR } from '../../helpers/constants/ErrorMessages';
import { QUERY } from '../../helpers/constants/api';
import { ROWS_PER_PAGE } from '../../helpers/constants/misc';
import { useApiResponseHandler } from '../../helpers/hooks/useApiResponseHandler';
import { useArray } from '../../helpers/hooks/useArray';
import { useDebounceInput } from '../../helpers/hooks/useDebounceInput';
import { usePageIndex } from '../../helpers/hooks/usePageIndex';
import { omitOptionalProperties } from '../../helpers/omitOptionalProperties';
import type { AxiosApiError, FetchUsersResponse } from '../../types';
import { mgmtAxios } from './share/axios';

export interface UsersState {
  pageIndex: number;
  officeIds: number[];
  keyword: string;
}
export interface UsersHandlers {
  handleChangePageIndex: (_: unknown, pageIndex: number) => void;
  handleToggleOfficeId: (officeId: number) => void;
  handleClearOfficeIds: () => void;
  handleInputKeyword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearKeyword: () => void;
}

type Data = FetchUsersResponse;
interface Params {
  offset: number;
  keyword?: string;
  officeId?: number[];
}
type UseFetchUsers = (
  options?: UseQueryOptions<Data, AxiosApiError, Data, (string | Params)[]>,
) => {
  query: UseQueryResult<Data, AxiosApiError>;
  usersState: UsersState;
  usersHandlers: UsersHandlers;
};

export const useFetchUsers: UseFetchUsers = options => {
  const { handleError } = useApiResponseHandler();
  const [pageIndex, handleChangePageIndex] = usePageIndex();
  const [officeIds, { toggle: handleToggleOfficeId, clear: handleClearOfficeIds }] =
    useArray<number>([]);
  const [{ originalInput: keyword, debounceInput }, handleInputKeyword, handleClearKeyword] =
    useDebounceInput();

  // ---------- Request Parameters ----------
  const requestParams: Params = useMemo(
    () =>
      omitOptionalProperties({
        offset: pageIndex * ROWS_PER_PAGE,
        keyword: debounceInput === '' ? undefined : debounceInput,
        officeId: officeIds.length === 0 ? undefined : officeIds,
      }),
    [debounceInput, officeIds, pageIndex],
  );

  const queryFn = async () => {
    const res: AxiosResponse<Data> = await mgmtAxios.get('/users', {
      params: requestParams,
    });

    return res.data;
  };
  const query = useQuery([QUERY.USERS, 'list', requestParams], queryFn, {
    onError: (err: AxiosApiError) => {
      handleError(ERROR.FETCH_USERS, err.response?.data ?? undefined);
    },
    keepPreviousData: true,
    staleTime: 5000,
    ...options,
  });

  useEffect(() => {
    // ページが２ページ以降の状態で絞り込みをかけると、dataのlengthよりもoffsetが大きくなり何も表示されなくなるため、
    // 絞り込みの条件を変更したタイミングで最初のページに戻す
    handleChangePageIndex(undefined, 0);
  }, [handleChangePageIndex, officeIds, debounceInput]);

  const usersState: UsersState = {
    pageIndex,
    officeIds,
    keyword,
  };
  const usersHandlers: UsersHandlers = {
    handleChangePageIndex,
    handleToggleOfficeId,
    handleClearOfficeIds,
    handleInputKeyword,
    handleClearKeyword,
  };

  return {
    query,
    usersState,
    usersHandlers,
  };
};
