import { Tooltip as MuiTooltip, type TooltipProps as MuiTooltipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface BaseProps {
  isWrappedInScroll?: boolean;
}
type VariantProps =
  | ({
      variant: 'intrinsic';
      hasEllipsis?: boolean;
      children: React.ReactNode;
    } & Omit<MuiTooltipProps, 'children'>)
  | ({
      variant: 'span';
      children: React.ReactNode;
    } & Omit<MuiTooltipProps, 'children'>)
  | ({
      variant: 'standard';
      // MuiTooltipのpropsのchildrenはReact.ReactElement型の縛りがある
    } & MuiTooltipProps);

export type TooltipProps = BaseProps & VariantProps;

const useStyles = makeStyles(theme => ({
  adjustTooltipPosition: {
    width: 'fit-content',
    maxWidth: '100%',
  },
  ellipsis: {
    ...theme.mixins.ellipsis,
  },
}));

/**
 * intrinsicは親要素のstyleに依らず、子要素のコンテンツに依ってtooltipの位置を決めたいときに使ってください
 * spanはtooltipの子要素がdisabledであってもtooltipを表示したいケースなどで使ってください
 * 詳しくはMuiのドキュメントを参照: https://v4.mui.com/components/tooltips/#tooltip
 *
 * また、tooltipのホバーリスナーがscrollの挙動にバグを引き起こすケースが存在します
 * overflow:autoまたはscrollの要素ないではTooltipにisWrappedInScroll:trueを渡すことで、このバグを回避できます
 */
export const Tooltip: React.VFC<TooltipProps> = ({ isWrappedInScroll = false, ...props }) => {
  const classes = useStyles();
  const propsToPreventScrollBug: Partial<MuiTooltipProps> = isWrappedInScroll
    ? {
        PopperProps: {
          popperOptions: {
            positionFixed: true,
          },
        },
      }
    : {};

  // 判別可能なUnion型
  if (props.variant === 'intrinsic') {
    const { children, hasEllipsis = true, ...tooltipProps } = props;

    return (
      <MuiTooltip {...tooltipProps} {...propsToPreventScrollBug}>
        <div className={clsx(classes.adjustTooltipPosition, hasEllipsis && classes.ellipsis)}>
          {children}
        </div>
      </MuiTooltip>
    );
  }

  if (props.variant === 'span') {
    const { children, ...tooltipProps } = props;

    return (
      <MuiTooltip {...tooltipProps} {...propsToPreventScrollBug}>
        <span>{children}</span>
      </MuiTooltip>
    );
  }

  const { children, ...tooltipProps } = props;

  return (
    <MuiTooltip {...tooltipProps} {...propsToPreventScrollBug}>
      {children}
    </MuiTooltip>
  );
};
