import { useCallback, useState } from 'react';
import { type Primitive, isPrimitive } from '../typeGuards';

type IdObject = { id: number | string };

export type UseArrayReturnType<TArrayItem> = [
  TArrayItem[],
  {
    setNewArray: (arrayItems: TArrayItem[], withClear?: boolean) => void;
    add: (arrayItem: TArrayItem, withClear?: boolean) => void;
    unshift: (arrayItem: TArrayItem) => void;
    remove: (arrayItem: TArrayItem) => void;
    removeById: (id: number | string) => void;
    toggle: (arrayItem: TArrayItem, withClear?: boolean) => void;
    clear: () => void;
    reset: () => void;
  },
];

export const useArray = <TArrayItem extends Primitive | IdObject>(
  initialState: TArrayItem[] = [],
): UseArrayReturnType<TArrayItem> => {
  const [value, setValue] = useState<TArrayItem[]>(initialState);
  const setNewArray = useCallback((arrayItems: TArrayItem[]) => {
    setValue(arrayItems);
  }, []);
  const add = useCallback(
    (arrayItem: TArrayItem) => setValue(prevValue => [...prevValue, arrayItem]),
    [],
  );
  const unshift = useCallback(
    (arrayItem: TArrayItem) => setValue(prevValue => [arrayItem, ...prevValue]),
    [],
  );
  const remove = useCallback((arrayItem: TArrayItem) => {
    setValue(prevValue => prevValue.filter(item => item !== arrayItem));
  }, []);
  const removeById = useCallback((id: number | string) => {
    setValue(prevValue =>
      prevValue.filter(item => {
        if (isPrimitive(item)) {
          throw new Error('stateの値にidが含まれていません');
        }

        return item.id !== id;
      }),
    );
  }, []);
  const toggle = useCallback(
    (arrayItem: TArrayItem) => {
      if (!value.includes(arrayItem)) {
        return add(arrayItem);
      }

      if (isPrimitive(arrayItem)) {
        return remove(arrayItem);
      }

      return removeById(arrayItem.id);
    },
    [add, remove, removeById, value],
  );
  const clear = useCallback(() => {
    setValue([]);
  }, []);
  const reset = useCallback(() => {
    setValue(initialState);
  }, [initialState]);

  return [value, { setNewArray, add, unshift, remove, removeById, toggle, clear, reset }];
};
