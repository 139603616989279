import type { AdminMgmtUsersTableData, FetchAdminMgmtUsersResponse } from '../../types';
import { USER_TYPES } from '../constants/user';

export const convertMgmtUsersForMgmtUsersTable = (
  mgmtUsers: FetchAdminMgmtUsersResponse['mgmtUsers'],
): AdminMgmtUsersTableData[] =>
  mgmtUsers.map(mgmtUser => ({
    userId: mgmtUser.id,
    userType: mgmtUser.userType === USER_TYPES.MANAGER ? '管理' : 'グループ管理',
    managerName: mgmtUser.name,
    // \u00A0はノーブレークスペース
    officeName: mgmtUser.offices.map(office => office.name).join(',\u00A0\u00A0'),
    email: mgmtUser.email,
  }));
