import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useOfficeSelectMaxHeight } from 'helpers/hooks/useOfficeSelectMaxHeight';
import { useFormContext } from 'react-hook-form';
import { FormLabelFieldsWrapper } from '../../../components/ui/Form/FormLabelFieldsWrapper';
import { FormLabelSelectWithDialog } from '../../../components/ui/Form/FormLabelSelectWithDialog';
import { FormLabelTextField } from '../../../components/ui/Form/FormLabelTextField';
import { PageTitle } from '../../../components/ui/PageTitle/PageTitle';
import { YesOrNoButton } from '../../../components/ui/YesOrNoButton/YesOrNoButton';
import { MGMT } from '../../../helpers/constants/Routes';
import { useEnsureGoBack } from '../../../helpers/hooks/useEnsureGoBack';
import { useRouter } from '../../../helpers/hooks/useRouter';
import { SAVE_USER_FORM_MAX } from '../../../helpers/validator/useSaveUserForm';
import type { ListItem, UsersTableState } from '../../../types';

interface Props {
  isManager: boolean;
  officeList: ListItem[];
  submit: {
    isSubmitAble: boolean;
    onSubmit: () => void;
  };
  kanaInput: {
    kanaInputValue: string;
    handleConvertToKana: (e: React.FocusEvent<HTMLInputElement>) => void;
  };
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridAutoRows: 'auto 1fr',
    height: '100%',
  },
  pageTitleAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
}));

export const UserCreateTemplate: React.FC<Props> = ({
  isManager,
  submit,
  kanaInput,
  officeList,
}) => {
  const classes = useStyles();
  const { control } = useFormContext();
  const {
    location: { state },
  } = useRouter<UsersTableState | null>();
  const handleGoBack = useEnsureGoBack(`/${MGMT.USERS}`, state);
  const officeSelectMaxHeight = useOfficeSelectMaxHeight();

  return (
    <div className={classes.root}>
      <Box className={classes.pageTitleAndButton} mb={4}>
        <div>
          <PageTitle gutterBottom>新規登録</PageTitle>
          <Typography variant="body2">
            ユーザー情報を入力し、登録ボタンを押すとメールアドレス宛にメールが送信されます。
            送信されたメールより本人確認を行ってください。
          </Typography>
        </div>
        <YesOrNoButton
          yesLabel="登録"
          yesButtonProps={{
            variant: submit.isSubmitAble ? 'contained' : 'text',
            disabled: !submit.isSubmitAble,
            size: 'large',
            onClick: () => submit.onSubmit(),
          }}
          noButtonProps={{
            size: 'large',
            onClick: handleGoBack,
          }}
        />
      </Box>
      <FormLabelFieldsWrapper>
        <FormLabelTextField control={control} label="名前" name="name" required />
        <FormLabelTextField
          control={control}
          label="フリガナ"
          name="kana"
          required
          formHelperText="カタカナで入力してください"
          filledTextFieldProps={{
            value: kanaInput.kanaInputValue,
            onBlur: kanaInput.handleConvertToKana,
          }}
        />
        <FormLabelSelectWithDialog
          control={control}
          label="所属クリニック"
          name="officeIds"
          required
          list={officeList}
          isSelectable={!isManager}
          maxHeight={officeSelectMaxHeight}
        />
        <FormLabelTextField control={control} label="メールアドレス" name="email" required />
        <FormLabelTextField
          control={control}
          label="メモ"
          name="memo"
          max={SAVE_USER_FORM_MAX.MEMO}
          filledTextFieldProps={{
            multiline: true,
            rows: 4,
            placeholder: `${SAVE_USER_FORM_MAX.MEMO}文字以内で入力してください`,
          }}
        />
      </FormLabelFieldsWrapper>
    </div>
  );
};
