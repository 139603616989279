export const DRAWER_WIDTH = 256;

export const TEXT_FIELD_HEIGHT = 44;

export const SELECT_FILED_HEIGHT = 50;

export const LIST_PADDING_TOP = 8;

export const LIST_ITEM_HEIGHT = 40;

export const HEIGHT_OTHER_THAN_OFFICE_SELECT = 675;

export const SEARCH_TEXT_FIELD_WIDTH = 360;

export const FORM_GRID_NAMES = {
  LABEL: 'label',
  FORM: 'form',
};

// TextFieldのsmallよりは大きく、mediumよりは小さいpadding
export const TEXT_FIELD_PADDING_SM = '14.5px 14px';

export const SELECTED_LIST_MIN_HEIGHT = 56;

export const LAYOUT_MIN_HEIGHT = HEIGHT_OTHER_THAN_OFFICE_SELECT + SELECTED_LIST_MIN_HEIGHT;
