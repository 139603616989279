import type { LocationState } from 'history';
import { useCallback } from 'react';
import { useRouter } from './useRouter';

/**
 * history.back()は、新しいタブで直接URLを叩いたケースなどでは、backするhistoryがなく、遷移が発火しません。
 * locationのkeyがundefinedになる場合、それはbackするhistoryがないことを意味します。
 * 詳しく下記のリンクを参照してください。
 * https://github.com/remix-run/history/issues/582
 */
export const useEnsureGoBack = (pushToIfNoHistory: string, state?: LocationState): (() => void) => {
  const {
    history,
    location: { key },
  } = useRouter();

  return useCallback(() => {
    if (state) {
      return history.push(pushToIfNoHistory, state);
    }
    if (key) {
      return history.go(-1);
    }

    return history.push(pushToIfNoHistory);
  }, [history, key, pushToIfNoHistory, state]);
};
