import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate } from '../../../helpers/formatDate';
import type { Timestamps as TimestampsType } from '../../../utilityTypes';

const useStyles = makeStyles(theme => ({
  timestampLabel: {
    marginRight: theme.spacing(1),
  },
  createdAt: {
    marginRight: theme.spacing(2),
  },
}));

export const Timestamps: React.VFC<TimestampsType> = ({ createdAt, updatedAt }) => {
  const classes = useStyles();

  return (
    <Typography component="p" variant="caption" align="right" color="textSecondary">
      <Typography className={classes.timestampLabel} component="span" variant="caption">
        作成日時
      </Typography>
      <Typography className={classes.createdAt} component="span" variant="caption">
        {formatDate(createdAt, 'dateAndTime')}
      </Typography>
      <Typography className={classes.timestampLabel} component="span" variant="caption">
        最終更新日時
      </Typography>
      <Typography component="span" variant="caption">
        {formatDate(updatedAt, 'dateAndTime')}
      </Typography>
    </Typography>
  );
};
