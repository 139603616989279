import { Redirect } from 'react-router-dom';
import { AUTH, MGMT } from '../../../helpers/constants/Routes';
import { useRouter } from '../../../helpers/hooks/useRouter';
import { AuthErrorTemplate } from './AuthErrorTemplate';

export const AuthErrorPage: React.VFC = () => {
  const { history, location } = useRouter<{ isError: true } | null>();
  const handleGoToApotool = () => {
    window.location.href = `${process.env.REACT_APP_APOTOOL_URL as string}`;
  };
  const handleLogout = () => {
    // 遷移先で自動的にログアウトされた後、ログイン画面に遷移する
    history.push(`/${AUTH.LOGOUT}`);
  };

  // 認証エラーがない(直接URLを叩いた場合など)ときはリダイレクト
  if (!location.state?.isError) {
    return <Redirect to={MGMT.USERS} />;
  }

  return <AuthErrorTemplate handleGoToApotool={handleGoToApotool} handleLogout={handleLogout} />;
};
