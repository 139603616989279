import { useMemo } from 'react';
import { type CellProps, type Column, useTable } from 'react-table';
import { MgmtLayout } from '../../../components/layout/mgmtLayout/MgmtLayout';
import { Tooltip } from '../../../components/ui/Tooltip/Tooltip';
import { initialUserLogsTableData } from '../../../helpers/constants/initialTableData';
import { useMediaQuery } from '../../../helpers/hooks/useMediaQuery';
import { convertUserLogsForUserLogsTable } from '../../../helpers/utils/convertUserLogsForUserLogsTable';
import { useFetchManager } from '../../../store/api/useFetchManager';
import { useFetchUserLogs } from '../../../store/api/useFetchUserLogs';
import type { UserLogsTableData } from '../../../types';
import { UserLogsTemplate } from './UserLogsTemplate';
import {
  ColumnsUserInformationCombined,
  ColumnsUserInformationSeparated,
} from './UserLogsTemplateTable';

export const UserLogsPage: React.VFC = () => {
  const mediaQuery = useMediaQuery();
  const isUserInformationSeparatedTable = mediaQuery === 'xl';

  // ---------- api(query) ----------
  const {
    query: { data, isLoading },
    userLogsState,
    userLogsHandlers,
  } = useFetchUserLogs();
  const { data: loginUser } = useFetchManager({ enabled: false });

  // ---------- table ----------
  const userLogsData: UserLogsTableData[] = useMemo(() => {
    if (data) {
      return convertUserLogsForUserLogsTable(
        data.userActivityLogs,
        isUserInformationSeparatedTable,
      );
    }

    return initialUserLogsTableData;
  }, [data, isUserInformationSeparatedTable]);
  const columns: Column<UserLogsTableData>[] = useMemo(
    () =>
      isUserInformationSeparatedTable
        ? ColumnsUserInformationSeparated
        : ColumnsUserInformationCombined,
    [isUserInformationSeparatedTable],
  );
  const tableOptions = {
    data: userLogsData,
    columns,
    defaultColumn: {
      Cell: ({ value }: CellProps<UserLogsTableData, React.ReactText>) => (
        <Tooltip variant="intrinsic" title={value} isWrappedInScroll>
          {value}
        </Tooltip>
      ),
    },
  };
  const userLogsTable = useTable<UserLogsTableData>(tableOptions);

  return (
    <MgmtLayout documentTitle="ログイン履歴">
      {loginUser && (
        <UserLogsTemplate
          userLogsTable={userLogsTable}
          userLogsState={userLogsState}
          userLogsHandlers={userLogsHandlers}
          loginUser={loginUser}
          totalCount={data?.totalCount ?? 0}
          isLoading={isLoading}
          isUserInformationSeparatedTable={isUserInformationSeparatedTable}
        />
      )}
    </MgmtLayout>
  );
};
