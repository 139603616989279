import { produce } from 'immer';
import { createReducerContext } from '../../helpers/factory/createReducerContext';

interface DialogState {
  isOpen: boolean;
  title: string;
  message: string;
}

interface Close {
  type: 'close';
}

interface Open {
  type: 'open';
  payload: {
    title: string;
    message: string;
  };
}

type Action = Close | Open;

const initialState: DialogState = {
  isOpen: false,
  title: '',
  message: '',
};

export const reducer = produce((draft: DialogState, action: Action): void => {
  switch (action.type) {
    case 'close':
      draft.isOpen = initialState.isOpen;
      draft.title = initialState.title;
      draft.message = initialState.message;

      return;
    case 'open':
      draft.isOpen = true;
      draft.title = action.payload.title;
      draft.message = action.payload.message;

      return;
    default:
      throw new Error('actionの値が正しくありません');
  }
});

export const [useAlertDialog, AlertDialogProvider] = createReducerContext<typeof reducer>(
  reducer,
  initialState,
);
