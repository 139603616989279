import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { IconButton } from '../../../ui/IconButton/IconButton';
import type { DrawerType } from '../../../ui/ResponsiveDrawer/ResponsiveDrawer';
import { UserInformation } from '../../../ui/UserInformation/UserInformation';

interface Props {
  drawerType: DrawerType;
  toggleIsSideNavOpen: () => void;
  loginUser: {
    email: string;
    name: string;
    isAdmin: boolean;
  };
  isLoading: boolean;
}

const useStyles = makeStyles(theme => ({
  appBar: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    minWidth: theme.breakpoints.values.sm,
    overflowX: 'hidden',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 3),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export const Header: React.FC<Props> = ({
  drawerType,
  toggleIsSideNavOpen,
  loginUser,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} position="fixed" elevation={1}>
      <Toolbar className={classes.toolbar}>
        {drawerType !== 'permanent' && (
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleIsSideNavOpen}
          >
            <Menu />
          </IconButton>
        )}
        <Typography variant="h3" className={classes.title}>
          ユーザー管理
        </Typography>
        {isLoading ? (
          <Skeleton width={200} />
        ) : (
          <UserInformation
            email={loginUser.email}
            name={loginUser.name}
            isAdmin={loginUser.isAdmin}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};
