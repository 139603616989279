import {
  type CircularProgressProps,
  CircularProgress as MuiCircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  circularProgressWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
  },
}));

export const CircularProgress: React.FC<CircularProgressProps> = circularProgressProps => {
  const classes = useStyles();

  return (
    <div className={classes.circularProgressWrapper}>
      <MuiCircularProgress {...circularProgressProps} />
    </div>
  );
};
