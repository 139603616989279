import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface Props {
  required?: boolean;
  label: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  label: {
    display: 'inline-flex',
    padding: theme.spacing(0.2, 0.5),
    borderRadius: theme.shape.borderRadius,
  },
  required: {
    backgroundColor: theme.palette.error.main,
  },
  optional: {
    backgroundColor: theme.palette.success.main,
  },
  labelText: {
    color: theme.palette.common.white,
  },
}));

export const FormLabel: React.FC<Props> = ({ required, label }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {required !== undefined && (
        <div className={clsx(classes.label, required ? classes.required : classes.optional)}>
          <Typography className={classes.labelText} variant="caption">
            {required ? '必須' : '任意'}
          </Typography>
        </div>
      )}
      <Typography component="label" variant="body1">
        {label}
      </Typography>
    </div>
  );
};
