import { Chip as MuiChip, type ChipProps as MuiChipProps, emphasize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Done } from '@material-ui/icons';

export interface ChipProps extends MuiChipProps {
  isSelected?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    '&:focus': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
    '&:hover': {
      backgroundColor: `${emphasize(
        theme.palette.primary.light,
        theme.palette.action.hoverOpacity,
      )} !important`,
    },
  },
}));

export const Chip: React.VFC<ChipProps> = ({ isSelected = false, ...muiChipProps }) => {
  const classes = useStyles();

  return (
    <MuiChip
      classes={{
        root: classes.root,
        colorPrimary: classes.primary,
        clickableColorPrimary: classes.primary,
        outlinedPrimary: classes.primary,
      }}
      variant="outlined"
      color={isSelected ? 'primary' : 'default'}
      icon={isSelected ? <Done /> : undefined}
      clickable
      {...muiChipProps}
    />
  );
};
