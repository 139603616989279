import { Box, Button, FormControl, Grid, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { AUTH } from '../../../helpers/constants/Routes';

interface Props {
  query: string;
  onForgotPasswordSubmit: () => void;
  email: string;
  onEmailChange: (newValue: string) => void;
}

const useStyles = makeStyles({
  inputLabel: {
    color: '#000',
  },
});

export const ForgotPasswordTemplate: React.VFC<Props> = ({
  query,
  onForgotPasswordSubmit,
  email,
  onEmailChange,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container justifyContent="center">
      <form
        onSubmit={e => {
          e.preventDefault();
          onForgotPasswordSubmit();
        }}
      >
        <Box mb="3vh" width="300px">
          <FormControl fullWidth>
            <TextField
              size="small"
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              type="text"
              autoFocus
              value={email}
              label="メールアドレス"
              variant="outlined"
              onChange={e => onEmailChange(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box mt="4vh" mb="4vh">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
            style={{ width: '40%', borderRadius: '20px', padding: '12px' }}
          >
            <Typography variant="body1">送信</Typography>
          </Button>
        </Box>
        <Box mb="4vh">
          <Link
            onClick={() => {
              if (query) {
                history.push(`/${AUTH.LOGIN}/${query}`);
              } else {
                history.push(`/${AUTH.LOGIN}`);
              }
            }}
            color="primary"
            variant="body1"
            style={{ cursor: 'pointer' }}
          >
            キャンセル
          </Link>
        </Box>
      </form>
    </Grid>
  );
};
