import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { MgmtLayout } from '../../../components/layout/mgmtLayout/MgmtLayout';
import { CircularProgress } from '../../../components/ui/CircularProgress/CircularProgress';
import { MGMT } from '../../../helpers/constants/Routes';
import { USER_TYPES } from '../../../helpers/constants/user';
import { useRouter } from '../../../helpers/hooks/useRouter';
import { convertToKana } from '../../../helpers/utils/convertToKana';
import {
  saveUserFormDefaultValues,
  useSaveUserForm,
} from '../../../helpers/validator/useSaveUserForm';
import { useCreateUser } from '../../../store/api/useCreateUser';
import { useFetchManager } from '../../../store/api/useFetchManager';
import { UserCreateTemplate } from './UserCreateTemplate';

export const UserCreatePage: React.VFC = () => {
  const { history } = useRouter();

  // ---------- form ----------
  const methods = useSaveUserForm();
  const {
    reset,
    watch,
    setValue,
    formState: { dirtyFields },
    handleSubmit,
  } = methods;

  // ---------- api(query) ----------
  const { data: loginUser } = useFetchManager({
    enabled: false,
    onSuccess: res => {
      if (res.userType === USER_TYPES.MANAGER) {
        reset({ ...saveUserFormDefaultValues, officeIds: res.offices.map(office => office.id) });
      }
    },
  });
  const isManager = loginUser?.userType === USER_TYPES.MANAGER ?? false;

  // ---------- submit ----------
  const shouldBeDirtyFields = isManager
    ? ['name', 'kana', 'email']
    : ['name', 'kana', 'officeIds', 'email'];
  const isSubmitAble = shouldBeDirtyFields.every(field => Object.keys(dirtyFields).includes(field));
  const { mutate, isLoading: isMutating } = useCreateUser({
    onSuccess: () => history.push(`/${MGMT.USERS}`),
  });
  const onSubmit = handleSubmit(({ name, kana, email, officeIds, memo }) => {
    mutate({ name, kana, email, officeIds, memo });
  });

  // ---------- 所属クリニック ----------
  const officeList =
    loginUser?.offices.map(office => ({
      label: office.name,
      value: office.id,
      mutable: true,
    })) ?? [];

  // ---------- kana ----------
  const kanaInputValue = watch('kana');
  const handleConvertToKana = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setValue('kana', convertToKana(e.target.value), {
        shouldDirty: true,
      });
    },
    [setValue],
  );

  return (
    <MgmtLayout documentTitle="新規登録">
      <FormProvider {...methods}>
        {isMutating && <CircularProgress />}
        {loginUser && (
          <UserCreateTemplate
            isManager={isManager}
            submit={{
              isSubmitAble,
              onSubmit,
            }}
            kanaInput={{
              kanaInputValue,
              handleConvertToKana,
            }}
            officeList={officeList}
          />
        )}
      </FormProvider>
    </MgmtLayout>
  );
};
