import axios from 'axios';
import { endpoint, isLocalDev } from 'helpers/constants/misc';
import qs from 'qs';

export const mgmtAxios = axios.create({
  baseURL: isLocalDev ? '/api/mgmt' : `${endpoint}/mgmt`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  responseType: 'json',
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});
