import { format } from 'date-fns';

export const formatDate = (
  date: Date,
  formatTo: 'dateAndTime' | 'date' | 'time',
  dateDelimiter = '/',
): string => {
  const dateString = `yyyy${dateDelimiter}MM${dateDelimiter}dd`;
  if (formatTo === 'dateAndTime') {
    return format(date, `${dateString} HH:mm`);
  }
  if (formatTo === 'date') {
    return format(date, dateString);
  }

  return format(date, 'HH:mm');
};
