import { Box, Grid, Typography } from '@material-ui/core';
import { useCallback, useContext, useEffect, useReducer } from 'react';
import { Layout } from '../../../components/layout/AuthLayout';
import { Loader } from '../../../components/ui';
import { Title } from '../../../components/ui/Title';
import { ERROR_CODE } from '../../../helpers/constants/ErrorMessages';
import { AUTH } from '../../../helpers/constants/Routes';
import { ErrorMessages } from '../../../helpers/utils/ApiErrors';
import { ForgotPasswordUser } from '../../../store/api/ApiService';
import { ForgotPasswordValidator } from '../../../store/models/Validators';
import { RedirectContext } from '../../../store/repository/RedirectContext';
import { ForgotPasswordTemplate } from './ForgotPasswordTemplate';

interface State {
  email: string;
  errorMessage: string;
  loading: boolean;
}

type Action =
  | {
      type: 'inputEmail';
      payload: { email: string };
    }
  | {
      type: 'submitStart';
    }
  | {
      type: 'validateSuccess';
    }
  | {
      type: 'validateFailure';
      payload: { errorMessage: string };
    }
  | {
      type: 'requestFailure';
      payload: { errorMessage: string };
    }
  | {
      type: 'requestFailureNotMigrated';
    }
  | {
      type: 'requestFailureUnexpected';
    };

const initialState: State = {
  email: '',
  errorMessage: '',
  loading: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // ---------- input ----------
    case 'inputEmail':
      return {
        ...state,
        email: action.payload.email,
      };
    // ---------- submit ----------
    case 'submitStart':
      return {
        ...state,
        errorMessage: '',
      };
    case 'validateSuccess':
      return {
        ...state,
        loading: true,
      };
    case 'validateFailure':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    case 'requestFailure':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case 'requestFailureNotMigrated':
      return {
        ...state,
        loading: true,
      };
    case 'requestFailureUnexpected':
      return {
        ...state,
        errorMessage: '予期せぬエラーが発生しました。メールアドレスを確認してください。',
      };

    default:
      return state;
  }
};

export const ForgotPasswordPage: React.VFC = () => {
  const [{ email, loading, errorMessage }, dispatch] = useReducer<React.Reducer<State, Action>>(
    reducer,
    initialState,
  );
  const { query, redirectHandler, statusQuery } = useContext(RedirectContext);

  const redirectToApotoolForgotPasswordScreen = (userEmail: string) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${process.env.REACT_APP_APOTOOL_URL ?? ''}password/new`;

    const userEmailInput = document.createElement('input');
    userEmailInput.name = 'email';
    userEmailInput.value = userEmail;
    form.appendChild(userEmailInput);

    const apiKeyInput = document.createElement('input');
    apiKeyInput.name = 'apiKey';
    apiKeyInput.value = `${process.env.REACT_APP_APOTOOL_FORGOT_PASSWORD_API_SECRET ?? ''}`;
    form.appendChild(apiKeyInput);

    document.body.appendChild(form);
    form.style.display = 'none';
    form.submit();
  };

  const handleForgotPasswordSubmit = () => {
    dispatch({ type: 'submitStart' });

    const validationError = ForgotPasswordValidator({ userEmail: email });

    if (validationError) {
      dispatch({ type: 'validateFailure', payload: { errorMessage: validationError } });

      return;
    }

    dispatch({ type: 'validateSuccess' });

    void ForgotPasswordUser({ userEmail: email })
      .then(() => {
        redirectHandler(AUTH.CONFIRM_FORGOT_PASSWORD);
      })
      .catch((err: Error) => {
        const newErrorMessage = ErrorMessages(err.message);
        if (newErrorMessage === ERROR_CODE.USER_NOT_MIGRATED) {
          dispatch({ type: 'requestFailureNotMigrated' });
          redirectToApotoolForgotPasswordScreen(email);

          return;
        }

        dispatch({ type: 'requestFailure', payload: { errorMessage: newErrorMessage } });
      });
  };

  const handleEmailChange = useCallback((newValue: string) => {
    dispatch({ type: 'inputEmail', payload: { email: newValue } });
  }, []);

  useEffect(() => {
    if (statusQuery === 'failed') {
      dispatch({ type: 'requestFailureUnexpected' });
    }
  }, []);

  return (
    <>
      <Title title="パスワードを忘れた方" />
      <Layout loading={false} up="2vh">
        <Box mt="1em" mb="1rem">
          <Grid container justifyContent="center">
            <Box>
              <Box my="2vh">
                <Typography color="textSecondary" variant="h4">
                  パスワード再設定
                </Typography>
              </Box>
              <Box my="2vh">
                <Typography color="textSecondary" variant="body1">
                  パスワード再設定のご案内をメールでお送りします。
                </Typography>
              </Box>
              <Box my="3vh">
                <Typography color="error">{errorMessage || null}</Typography>
              </Box>
              {loading ? (
                <Box mt="0vh" mb="10vh">
                  <Loader />
                </Box>
              ) : statusQuery === 'success' ? (
                <Box my="1rem">
                  <Typography color="textSecondary" style={{ fontWeight: 'bold' }} variant="body1">
                    パスワードリセットメールを送信しました。メール内の案内より、10分以内にパスワードを設定してください
                  </Typography>
                </Box>
              ) : (
                <ForgotPasswordTemplate
                  query={query}
                  onForgotPasswordSubmit={handleForgotPasswordSubmit}
                  email={email}
                  onEmailChange={handleEmailChange}
                />
              )}
            </Box>
          </Grid>
        </Box>
      </Layout>
    </>
  );
};
