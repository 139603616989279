const basicColors = {
  hue: {
    grey: '#7f8c8c',
    blue: '#5c8dbe',
    indigo: '#8282cd',
    violet: '#a078c9',
    fuschia: '#c267c2',
    pink: '#c56d99',
    red: '#c67171',
    orange: '#b57d46',
    yellow: '#8e8e00',
    lime: '#509b04',
    green: '#0f9f0f',
    teal: '#0b9d55',
  },
  accent: {
    light: '#ffffdb',
    main: '#eee8a9',
    dark: '#bbb679',
    contrastText: '#fff',
  },
  warning: {
    main: '#b57d46',
  },
  status: {
    danger: 'orange',
  },
};

export const colorTheme = {
  palette: {
    primary: {
      light: '#54caca',
      main: '#009999',
      dark: '#006a6b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#8ebdf1',
      main: '#5c8dbe',
      dark: '#27608e',
      contrastText: '#fff',
    },
    ...basicColors,
  },
};
