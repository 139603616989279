import { TextField } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { Dialog } from '../Dialog/Dialog';

export interface DeleteConfirmProps {
  handleDelete: () => void;
  isDeleting: boolean;
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
}

export const DeleteConfirm: React.VFC<DeleteConfirmProps> = ({
  handleDelete,
  isDeleting,
  open,
  onClose,
  title,
  message,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value),
    [],
  );

  useEffect(() => {
    if (!open) {
      setInputValue('');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      message={message}
      maxWidth="xs"
      yesLabel="削除"
      yesButtonProps={{
        color: 'danger',
        disabled: inputValue !== 'DELETE',
        onClick: handleDelete,
      }}
      isMutating={isDeleting}
    >
      <TextField
        variant="filled"
        fullWidth
        helperText="削除するには「DELETE」と入力してください"
        inputProps={{
          autoComplete: 'off',
        }}
        value={inputValue}
        onChange={handleInput}
      />
    </Dialog>
  );
};
