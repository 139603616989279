import { type CheckboxProps, Checkbox as MuiCheckbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, type TooltipProps } from '../Tooltip/Tooltip';

interface Props extends CheckboxProps {
  isWrappedInScroll?: TooltipProps['isWrappedInScroll'];
}

const useStyles = makeStyles(theme => ({
  indeterminateCheckbox: {
    color: theme.palette.primary.main,
  },
}));

export const TableCheckbox: React.VFC<Props> = ({ isWrappedInScroll, ...checkboxProps }) => {
  const classes = useStyles();

  return (
    <Tooltip variant="standard" title="選択" isWrappedInScroll={isWrappedInScroll}>
      <MuiCheckbox
        classes={{ indeterminate: classes.indeterminateCheckbox }}
        color="primary"
        {...checkboxProps}
      />
    </Tooltip>
  );
};
