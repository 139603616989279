import { ERROR_CODE } from 'helpers/constants/ErrorMessages';

export const ErrorMessages: (error: string) => string = error => {
  if (error.includes(ERROR_CODE.SESSION_INVALID)) {
    // TODO: メッセージを考える
    return '';
  }
  // ログイン失敗が多く、アカウントロックされた場合も、UNAUTHORIZED
  if (error.includes(ERROR_CODE.UNAUTHORIZED)) {
    return 'メールアドレス、又はパスワードが正しくありません。入力し直してください。';
  }
  if (error.includes(ERROR_CODE.INVALID_CONFIRM_CODE)) {
    return '確認コードが一致しません。入力し直してください。';
  }
  // パスワード変更での確認コード入力で失敗が多い場合に発生する。
  if (error.includes(ERROR_CODE.LIMIT_EXCEEDED)) {
    return '試行回数が上限に達しました。時間を置いてもう一度試してください。';
  }
  if (error.includes(ERROR_CODE.INVALID_PASSWORD)) {
    return 'パスワードは半角英数字8~12文字で入力してください。';
  }
  if (error.includes(ERROR_CODE.USER_NOT_MIGRATED)) {
    return ERROR_CODE.USER_NOT_MIGRATED;
  }

  return '予期せぬエラーが発生しました。画面を再読み込みしてください。';
};
