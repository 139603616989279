import { Typography, type TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { UserActivity } from '../../../types';

interface Props extends TypographyProps {
  userActivity: UserActivity;
}

const useStyles = makeStyles(theme => ({
  login: {
    color: theme.palette.success.main,
  },
  logout: {
    color: theme.palette.error.main,
  },
}));

export const UserActivityLabel: React.VFC<Props> = ({ userActivity, ...typographyProps }) => {
  const classes = useStyles();
  if (userActivity === 'Login') {
    return (
      <Typography className={classes.login} variant="button" {...typographyProps}>
        ログイン
      </Typography>
    );
  }

  return (
    <Typography className={classes.logout} variant="button" {...typographyProps}>
      ログアウト
    </Typography>
  );
};
