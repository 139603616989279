import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  name: string;
  email: string;
  isAdmin: boolean;
}

const useStyles = makeStyles(theme => ({
  textContainer: {
    display: 'block',
    width: '100%',
    textAlign: 'right',
  },
  userName: {
    fontWeight: 500,
  },
  adminLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.25, 0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    lineHeight: 1.333,
    borderRadius: theme.shape.borderRadius,
  },
  email: {
    display: 'block',
    width: '100%',
    lineHeight: 2.2,
    textTransform: 'none',
  },
}));

export const UserInformation: React.VFC<Props> = ({ name, email, isAdmin }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.textContainer} component="p">
        <Typography className={classes.userName} variant="body2" component="span">
          {name}
        </Typography>
        {isAdmin && (
          <Typography className={classes.adminLabel} variant="caption" component="span">
            Admin
          </Typography>
        )}
      </Typography>
      <Typography className={classes.email} variant="overline" align="right" color="textSecondary">
        {email}
      </Typography>
    </div>
  );
};
