import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';
import { useRef } from 'react';
import { useScroll } from '../../../helpers/hooks/useScroll';
import { Chip, type ChipProps } from './Chip';

interface ChipArrayItem extends ChipProps {
  id: string;
}

interface Props {
  chipData: ChipArrayItem[];
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  scrollArea: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    columnGap: theme.spacing(1),
    overflowX: 'auto',
    scrollSnapType: 'x mandatory',
    '& > *': {
      scrollSnapAlign: 'start',
      scrollMarginInlineStart: `${theme.spacing(3)}px`,
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  scrollButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '100%',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    zIndex: 1,
    width: 24,
  },
  prevButton: {
    left: 0,
  },
  nextButton: {
    right: 0,
  },
}));

const SCROLL_DISTANCE = 500;

export const ChipArray: React.VFC<Props> = ({ chipData }) => {
  const classes = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [{ scrollLeft, isScrollLeftEnd }, handleScroll] = useScroll(scrollRef);

  return (
    <div className={classes.root}>
      <ButtonBase
        className={clsx(classes.scrollButton, classes.prevButton)}
        onClick={() => handleScroll('left', SCROLL_DISTANCE)}
        style={{ visibility: scrollLeft === 0 ? 'hidden' : 'visible' }}
      >
        <ChevronLeft />
      </ButtonBase>
      <div className={classes.scrollArea} ref={scrollRef}>
        {chipData.map(chipDatum => (
          <Chip key={chipDatum.id} {...chipDatum} />
        ))}
      </div>
      <ButtonBase
        className={clsx(classes.scrollButton, classes.nextButton)}
        onClick={() => handleScroll('right', SCROLL_DISTANCE)}
        style={{ visibility: isScrollLeftEnd ? 'hidden' : 'visible' }}
      >
        <ChevronRight />
      </ButtonBase>
    </div>
  );
};
