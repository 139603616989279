import { useCallback, useState } from 'react';

type UseSetStateReturnType<TState> = [TState, (value: Exclude<TState, undefined>) => void];

export const useSetState = <TState,>(initialState: TState): UseSetStateReturnType<TState> => {
  const [value, setValue] = useState(initialState);
  const handleSetValue = useCallback(newValue => {
    setValue(newValue);
  }, []);

  return [value, handleSetValue];
};
