import { List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FORM_GRID_NAMES,
  LIST_ITEM_HEIGHT,
  LIST_PADDING_TOP,
} from '../../../helpers/constants/layout';
import type { ListItem as ListItemType } from '../../../types';
import { FormLabel } from './FormLabel';

interface Props {
  label: string;
  list: ListItemType[];
  maxHeight?: React.CSSProperties['maxHeight'];
}

const useStyles = makeStyles(theme => ({
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    gridColumnStart: FORM_GRID_NAMES.LABEL,
    height: LIST_ITEM_HEIGHT,
    marginTop: LIST_PADDING_TOP,
  },
  listWrapper: {
    gridColumnStart: FORM_GRID_NAMES.FORM,
    backgroundColor: theme.palette.action.hover,
  },
  list: {
    maxHeight: ({ maxHeight }: Pick<Props, 'maxHeight'>) => maxHeight,
    overflowY: 'auto',
    scrollSnapType: 'y proximity',
    scrollPaddingTop: `${LIST_PADDING_TOP}px`,
    '& > *': {
      scrollSnapAlign: 'start',
    },
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

export const FormLabelList: React.VFC<Props> = ({ label, list, maxHeight = 'auto' }) => {
  const classes = useStyles({ maxHeight });

  return (
    <>
      <div className={classes.labelWrapper}>
        <FormLabel label={label} />
      </div>
      <Paper className={classes.listWrapper} elevation={0}>
        <List className={classes.list}>
          {list.map(listItem => (
            <ListItem key={listItem.value} className={classes.listItem}>
              <ListItemText primary={listItem.label} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
};
