import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

type Button = {
  bg?: string;
  m?: string;
  mt?: string;
  mb?: string;
  mr?: string;
  ml?: string;
  p?: string;
  value?: string;
  theme?: { main: string; dark: string; color: string };
};
export const Button = styled.button<Button>`
  background-color: inherit;
  border: none;
  border-radius: ${ds.get('radius')};
  box-sizing: border-box;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02857em;
  line-height: inherit;
  line-height: 1.75;
  margin: 0;
  margin: ${props => props.m};
  margin-bottom: ${props => props.mb || '0px'};
  margin-left: ${props => props.ml || '0px'};
  margin-right: ${props => props.mr || '0px'};
  margin-top: ${props => props.mt || '0px'};
  padding: ${props => props.p || '6px 16px'};

  &:focus {
    outline: 0;
  }

  /* サイズ */
  &.small {
    font-size: 1rem;
    padding: 4px 5px;
  }

  &.middium {
    width: 80%;
  }

  &.large {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.02857em;
    line-height: 1.5;
    padding: 10px 16px;
  }

  &.stretch {
    width: 100%;
  }

  &.stretch-sm {
    width: auto;

    @media (max-width: ${ds.bp('sm')}) {
      width: 100%;
    }
  }

  /* /ボタンデザイン */
  &.primary {
    background-color: ${ds.brand('lilDark')};
    border: none;
    color: ${ds.color('grey')};

    :hover {
      background-color: ${ds.brand('dark')};
    }
  }

  &.secondary {
    background-color: ${ds.color('amber', '700')};
    border: 1px solid ${ds.color('amber', '700')};
    color: ${ds.brand('white')};
    padding: 5px 15px;

    :hover {
      background-color: ${ds.color('amber', '800')};
    }
  }

  &.outlined {
    background-color: inherit;
    border: 1px solid ${ds.color('grey', '6')};
    color: ${ds.color('grey', '6')};

    :hover {
      background-color: ${ds.color('grey', '1')};
    }
  }

  &.disabled {
    background-color: ${ds.color('grey', '3')};
    border: 1px solid ${ds.color('grey', '3')};
    color: ${ds.color('grey', '2')};
  }

  &.text {
    appearance: none;
    background-color: transparent;
    border: none;
    color: ${ds.brand('main')};
    outline: none;
    padding: 0;

    :hover {
      text-decoration: underline;
    }
  }

  &.text-grey {
    appearance: none;
    background-color: transparent;
    border: none;
    color: ${ds.color('grey', '8')};
    outline: none;
    padding: 0;

    :hover {
      text-decoration: underline;
    }
  }

  &.theme {
    background-color: ${props => props.theme.main};

    :hover {
      background-color: ${props => props.theme.dark};
    }
  }

  &.link-style {
    background: none;
    border: none;
    color: ${props => props.theme.color};
    cursor: pointer;

    :hover {
      color: #002080;
      text-decoration: underline;
    }
  }

  &.shadow {
    box-shadow: ${ds.get('shadow.1')};
  }

  &.not-allowed {
    color: ${ds.color('grey', '5')};
    padding: 0;

    &:hover {
      cursor: not-allowed;
    }
  }
`;
