import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

export const Footer: React.VFC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography component="footer" variant="caption" color="textSecondary" align="center">
        © Stransa Co., Ltd. All rights reserved.
      </Typography>
    </div>
  );
};
