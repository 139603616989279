import { type Theme, createTheme } from '@material-ui/core/styles';
import { mixins } from './mixin';
import { overrides } from './overrides';
import { palette } from './palette';
import { typography } from './typography';

export const mgmtTheme: Theme = createTheme({
  palette,
  typography,
  mixins,
  overrides,
});
