import type { AxiosResponse } from 'axios';
import { type UseQueryOptions, type UseQueryResult, useQuery } from 'react-query';
import { ERROR } from '../../helpers/constants/ErrorMessages';
import { QUERY } from '../../helpers/constants/api';
import { useApiResponseHandler } from '../../helpers/hooks/useApiResponseHandler';
import type { AxiosApiError, FetchManagerResponse } from '../../types';
import { mgmtAxios } from './share/axios';

type FnData = FetchManagerResponse;
type Data = FetchManagerResponse['user'];
type UseFetchManager = (
  options?: UseQueryOptions<FnData, AxiosApiError, Data, string[]>,
) => UseQueryResult<Data, AxiosApiError>;

export const useFetchManager: UseFetchManager = options => {
  const { handleError } = useApiResponseHandler();
  const queryFn = async () => {
    const res: AxiosResponse<FnData> = await mgmtAxios.get('/manager');

    return res.data;
  };

  return useQuery([QUERY.MANAGER], queryFn, {
    onError: (err: AxiosApiError) =>
      handleError(ERROR.FETCH_MANAGER, err.response?.data ?? undefined),
    select: res => res.user,
    ...options,
  });
};
