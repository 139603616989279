import { CssBaseline } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ResetCSS } from 'components/ui/ResetCSS';
import { useLocation } from 'react-router-dom';
import { authTheme } from './authTheme';
import { mgmtTheme } from './mgmtTheme/mgmtTheme';

export const ThemeProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const isMgmt = location.pathname.includes('mgmt');
  const theme = isMgmt ? mgmtTheme : authTheme;

  return (
    <MuiThemeProvider theme={theme}>
      {isMgmt ? <CssBaseline /> : <ResetCSS />}
      {children}
    </MuiThemeProvider>
  );
};
