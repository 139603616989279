export const convertToDate = (dateString: string): Date => {
  const converted = new Date(dateString);

  const isInvalidDate = (date: Date) => Number.isNaN(date.getDate());

  if (isInvalidDate(converted)) {
    throw new Error('dateStringの値はDate型に変換できません');
  }

  return converted;
};
