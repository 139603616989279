import { useTheme } from '@material-ui/core/styles';
import { useEffect, useMemo } from 'react';
import { useRafState } from './useRafState';

type UseMediaQueryReturnType = 'xl' | 'lg' | 'md' | 'sm';

const generateQueryString = (width: number): string => `(min-width: ${width}px)`;

/**
 * material-uiのuseMediaQueryには下記のリンクのようなバグがあるため、オリジナルのuseMediaQueryを作成した。
 * バグのFIXはmaterial-uiのv5にマージされる。
 * v5にアップデートする際には、このhooksを削除しmaterial-uiのuseMediaQueryを使用する。
 * https://github.com/mui-org/material-ui/pull/23806
 */
export const useMediaQuery = (): UseMediaQueryReturnType => {
  const theme = useTheme();
  const isGreaterThanXlQuery = window.matchMedia(generateQueryString(theme.breakpoints.values.xl));
  const isGreaterThanLgQuery = window.matchMedia(generateQueryString(theme.breakpoints.values.lg));
  const isGreaterThanMdQuery = window.matchMedia(generateQueryString(theme.breakpoints.values.md));
  const [queryResult, setQueryResult] = useRafState<{
    isGreaterThanXl: boolean;
    isGreaterThanLg: boolean;
    isGreaterThanMd: boolean;
  }>({
    isGreaterThanXl: isGreaterThanLgQuery.matches,
    isGreaterThanLg: isGreaterThanLgQuery.matches,
    isGreaterThanMd: isGreaterThanMdQuery.matches,
  });
  useEffect((): (() => void) | void => {
    const handler = () => {
      setQueryResult({
        isGreaterThanXl: isGreaterThanLgQuery.matches,
        isGreaterThanLg: isGreaterThanLgQuery.matches,
        isGreaterThanMd: isGreaterThanMdQuery.matches,
      });
    };
    isGreaterThanXlQuery.addEventListener('change', handler);
    isGreaterThanLgQuery.addEventListener('change', handler);
    isGreaterThanMdQuery.addEventListener('change', handler);

    return () => {
      isGreaterThanXlQuery.addEventListener('change', handler);
      isGreaterThanLgQuery.removeEventListener('change', handler);
      isGreaterThanMdQuery.addEventListener('change', handler);
    };
  }, []);
  const mediaQuery: UseMediaQueryReturnType = useMemo(() => {
    if (queryResult.isGreaterThanXl) {
      return 'xl';
    }
    if (queryResult.isGreaterThanLg) {
      return 'lg';
    }
    if (queryResult.isGreaterThanMd) {
      return 'md';
    }

    return 'sm';
  }, [queryResult.isGreaterThanXl, queryResult.isGreaterThanLg, queryResult.isGreaterThanMd]);

  return mediaQuery;
};
