import { useCallback, useEffect } from 'react';
import { useIsMutating } from 'react-query';
import { Dialog, type DialogProps } from '../../components/ui/Dialog/Dialog';
import { useToggle } from './useToggle';

export type HookedDialogProps = Omit<DialogProps, 'open' | 'onClose'> &
  Partial<Pick<DialogProps, 'onClose'>>;
type HookedDialogType = React.VFC<HookedDialogProps>;
type UseDialogReturnType = [HookedDialogType, () => void, () => void];

export const useDialog = (autoClose = true): UseDialogReturnType => {
  const isMutating = Boolean(useIsMutating());

  const [isOpen, handleIsOpen] = useToggle(false);
  const handleOpen = useCallback(() => {
    handleIsOpen(true);
  }, [handleIsOpen]);
  const defaultHandleClose = useCallback(() => {
    handleIsOpen(false);
  }, [handleIsOpen]);
  const HookedDialog: HookedDialogType = useCallback(
    ({ children, ...dialogProps }) => (
      <Dialog open={isOpen} onClose={defaultHandleClose} isMutating={isMutating} {...dialogProps}>
        {children}
      </Dialog>
    ),
    [isOpen, defaultHandleClose, isMutating],
  );

  useEffect(() => {
    if (autoClose && !isMutating) {
      defaultHandleClose();
    }
  }, [isMutating]);

  return [HookedDialog, handleOpen, defaultHandleClose];
};
