import { Box, type BoxProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface Props extends BoxProps {
  spacing: number;
}

const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: ({ spacing }: Props) => theme.spacing(spacing),
    },
  },
}));

/**
 * material-ui v5にアップデートする際には、このコンポーネントを削除しmaterial-uiのStackを使用する。
 * https://next.material-ui.com/components/stack/#main-content
 */
export const Stack: React.FC<Props> = ({ children, spacing, className, ...boxProps }) => {
  const classes = useStyles({ spacing });

  return (
    <Box className={clsx(classes.root, className)} {...boxProps}>
      {children}
    </Box>
  );
};
