import styled from '@emotion/styled';

type Ribbon = {
  m?: string;
  color?: string;
};

export const Ribbon = styled.div<Ribbon>`
  &.box {
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    height: 400px;
    max-width: 600px;
    position: relative;
    width: 90%;
  }

  /* common */
  &.ribbon {
    height: 150px;
    overflow: hidden;
    position: absolute;
    width: 150px;
  }

  &.ribbon::before,
  &.ribbon::after {
    border: 5px solid #2980b9;
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
  }

  span {
    background-color: ${props => props.color || '#3498db'};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    display: block;
    font: 700 18px/1 'Lato', sans-serif;
    padding: 15px 0;
    position: absolute;
    right: -25px;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    top: 30px;
    transform: rotate(-45deg);
    width: 225px;
  }

  /* top left */
  &.ribbon-top-left {
    left: -10px;
    top: -10px;
  }

  &.ribbon-top-left::before,
  &.ribbon-top-left::after {
    border-left-color: transparent;
    border-top-color: transparent;
  }

  &.ribbon-top-left::before {
    right: 0;
    top: 0;
  }

  &.ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
`;
