import { Button, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useEffect, useRef } from 'react';
import { type UseControllerProps, useController } from 'react-hook-form';
import {
  FORM_GRID_NAMES,
  LIST_ITEM_HEIGHT,
  LIST_PADDING_TOP,
} from '../../../helpers/constants/layout';
import { useArray } from '../../../helpers/hooks/useArray';
import { useDialog } from '../../../helpers/hooks/useDialog';
import type { CheckboxListItem } from '../../../types';
import { CheckboxList } from '../SelectList/CheckboxList';
import { FormLabel } from './FormLabel';

interface Props<TFieldValues extends Record<string, number[]>>
  extends UseControllerProps<TFieldValues> {
  list: CheckboxListItem[];
  isSelectable?: boolean;
  label: string;
  required?: boolean;
  maxHeight?: React.CSSProperties['maxHeight'];
}

const useStyles = makeStyles(theme => ({
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    gridColumnStart: FORM_GRID_NAMES.LABEL,
    height: LIST_ITEM_HEIGHT,
    marginTop: LIST_PADDING_TOP,
  },
  formWrapper: {
    gridColumnStart: FORM_GRID_NAMES.FORM,
  },
  listWrapper: {
    backgroundColor: theme.palette.action.hover,
    marginBottom: theme.spacing(2),
  },
  list: {
    overflowY: 'auto',
    maxHeight: ({ maxHeight }: Pick<Props<Record<string, number[]>>, 'maxHeight'>) => maxHeight,
    scrollSnapType: 'y proximity',
    scrollPaddingTop: `${LIST_PADDING_TOP}px`,
    '& > *': {
      scrollSnapAlign: 'start',
    },
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  selectButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    columnGap: theme.spacing(1),
  },
}));

export const FormLabelSelectWithDialog = <TFieldValues extends Record<string, number[]>>({
  list,
  isSelectable = false,
  label,
  required = false,
  name,
  maxHeight = 'auto',
  ...controllerProps
}: Props<TFieldValues>): React.ReactElement => {
  const classes = useStyles({ maxHeight });

  // ---------- form ----------
  const {
    field: { value, onChange },
    formState: { errors },
  } = useController<TFieldValues>({
    name,
    ...controllerProps,
  });

  // ---------- state ----------
  const [uncommittedCheckedList, setUncommittedCheckList] = useArray<number>(value);
  const handleCommitCheckList = useCallback(() => {
    onChange(uncommittedCheckedList);
  }, [onChange, uncommittedCheckedList]);
  const resetUnCommittedCheckList = useCallback(() => {
    setUncommittedCheckList.reset();
  }, [setUncommittedCheckList]);

  const [Dialog, handleOpen, handleClose] = useDialog(false);
  const handleOpenSelectDialog = useCallback(() => {
    if (isSelectable) {
      handleOpen();
    }
  }, [handleOpen, isSelectable]);
  const currentSelectedList = list.filter(listItem => value.includes(listItem.value));

  const handleSetNewArrayRef = useRef(setUncommittedCheckList.setNewArray);
  useEffect(() => {
    handleSetNewArrayRef.current(value);
  }, [value]);

  return (
    <>
      <div className={classes.labelWrapper}>
        <FormLabel required={required} label={label} />
      </div>
      <div className={classes.formWrapper}>
        <Paper className={classes.listWrapper} elevation={0}>
          <List className={classes.list} onClick={handleOpenSelectDialog}>
            {currentSelectedList.length === 0 ? (
              <ListItem className={classes.listItem}>
                <ListItemText primary={`${label}を選択してください`} />
              </ListItem>
            ) : (
              currentSelectedList.map(currentSelectedListItem => (
                <ListItem key={currentSelectedListItem.value} className={classes.listItem}>
                  <ListItemText primary={currentSelectedListItem.label} />
                </ListItem>
              ))
            )}
          </List>
          {errors[name] && (
            <Typography variant="caption" color="error">
              {(errors[name] as { message: string }).message}
            </Typography>
          )}
        </Paper>
        {isSelectable && (
          <div className={classes.selectButtonWrapper}>
            {value.length > 0 && (
              <Typography variant="body2" color="textSecondary">
                {value.length}件選択中
              </Typography>
            )}
            <Button variant="outlined" color="primary" onClick={handleOpen}>
              選択
            </Button>
          </div>
        )}
      </div>
      <Dialog
        onClose={() => {
          handleClose();
          resetUnCommittedCheckList();
        }}
        title={`${label}を選択してください`}
        yesLabel="完了"
        yesButtonProps={{
          disabled: value === uncommittedCheckedList,
          onClick: () => {
            handleClose();
            handleCommitCheckList();
          },
        }}
        disableContentPadding
      >
        <CheckboxList
          list={list.filter(ele => ele.mutable)}
          checkedList={uncommittedCheckedList}
          toggleCheck={setUncommittedCheckList.toggle}
          wrappedInDialog
        />
      </Dialog>
    </>
  );
};
