import { ChipArray } from '../../../components/ui/Chip/ChipArray';
import type { User } from '../../../types';

interface Props {
  offices: User['offices'];
  officeIds: number[];
  toggleOfficeId: (officeId: number) => void;
  clearOfficeIds: () => void;
}

export const OfficeFilter: React.VFC<Props> = ({
  offices,
  officeIds,
  toggleOfficeId,
  clearOfficeIds,
}) => {
  const isSelected = (officeId: number) => officeIds.includes(officeId);
  const chipDataAllSelection = {
    id: '0',
    label: '全クリニック',
    isSelected: officeIds.length === 0,
    onClick: () => clearOfficeIds(),
  };
  const chipDataOfficeSelections = offices.map(office => ({
    id: `${office.id}`,
    label: office.name,
    isSelected: isSelected(office.id),
    onClick: () => toggleOfficeId(office.id),
  }));
  const chipData = [chipDataAllSelection, ...chipDataOfficeSelections];

  return <ChipArray chipData={chipData} />;
};
