import { useCallback, useState } from 'react';

export type DateRangeItem = Date | null;
export type DateRange = [DateRangeItem, DateRangeItem];
export type UseDateInputReturnType = [DateRange, (newDateRange: DateRange) => void];

export const useDateRange = (): UseDateInputReturnType => {
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const handleChangeDateRange = useCallback(
    (newDateRange: DateRange) => setDateRange(newDateRange),
    [],
  );

  return [dateRange, handleChangeDateRange];
};
