import { DialogContent, type DialogContentProps, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useScroll } from 'helpers/hooks/useScroll';
import { forwardRef, useRef } from 'react';

interface Props extends Omit<DialogContentProps, 'divider'> {
  disableContentPadding: boolean;
}

const useStyles = makeStyles(() => ({
  dialogContent: {
    position: 'relative',
  },
  disableContentPadding: {
    padding: 0,
  },
}));

export const DialogContentWithDivider: React.VFC<Props> = ({
  children,
  disableContentPadding,
  ...rest
}) => {
  const classes = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [{ hasVerticalScroll }] = useScroll(scrollRef);

  return (
    <>
      <DialogContentWithRef
        ref={scrollRef}
        className={clsx(
          classes.dialogContent,
          disableContentPadding && classes.disableContentPadding,
        )}
        {...rest}
      >
        {children}
      </DialogContentWithRef>
      {hasVerticalScroll && <Divider />}
    </>
  );
};

const DialogContentWithRef = forwardRef<HTMLDivElement | unknown, DialogContentProps>(
  (props, ref) => <DialogContent ref={ref} {...props} />,
);
