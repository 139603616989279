import { yupResolver } from '@hookform/resolvers/yup';
import { type UseFormReturn, useForm } from 'react-hook-form';
import { type SchemaOf, yup } from '../utils/yupCustom';

export interface SaveUserFormValues {
  name: string;
  kana: string;
  officeIds: number[];
  email: string;
  memo?: string;
}

export const SAVE_USER_FORM_MAX = {
  NAME: 30,
  KANA: 30,
  EMAIL: 254,
  MEMO: 200,
} as const;

export const saveUserSchema: SchemaOf<SaveUserFormValues> = yup.object().shape({
  name: yup.string().min(1).max(SAVE_USER_FORM_MAX.NAME).required(),
  kana: yup.string().katakana().min(1).max(SAVE_USER_FORM_MAX.KANA).required(),
  officeIds: yup.array().of(yup.number().required()).min(1).required(),
  email: yup.string().trim().matchEmail().email().max(SAVE_USER_FORM_MAX.EMAIL).required(),
  memo: yup.string().max(SAVE_USER_FORM_MAX.MEMO),
});

export const saveUserFormDefaultValues = {
  name: '',
  kana: '',
  officeIds: [],
  email: '',
  memo: '',
};

export const useSaveUserForm = (): UseFormReturn<SaveUserFormValues> =>
  useForm<SaveUserFormValues>({
    resolver: yupResolver(saveUserSchema),
    defaultValues: saveUserFormDefaultValues,
    shouldUnregister: true,
  });
