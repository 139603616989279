import { IconButton, InputAdornment, TextField, type TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HighlightOff, Search } from '@material-ui/icons';

interface Props {
  handleClear: () => void;
}

const useStyles = makeStyles(theme => ({
  inputRoot: {
    backgroundColor: theme.palette.common.white,
  },
  inputInput: {
    padding: '14.5px 14px',
    paddingLeft: 0,
  },
  searchIcon: {
    color: theme.palette.text.secondary,
  },
}));

export const SearchTextField: React.VFC<Props & TextFieldProps> = ({
  handleClear,
  ...textFieldProps
}) => {
  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder="ユーザー名かメールアドレスで検索"
      InputProps={{
        className: classes.inputRoot,
        classes: { input: classes.inputInput },
        startAdornment: (
          <InputAdornment position="start">
            <Search className={classes.searchIcon} />
          </InputAdornment>
        ),
        endAdornment:
          textFieldProps.value && textFieldProps.value !== '' ? (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleClear}>
                <HighlightOff />
              </IconButton>
            </InputAdornment>
          ) : undefined,
      }}
      {...textFieldProps}
    />
  );
};
