import styled from '@emotion/styled';
import { ds } from '../theme/authTheme/ds';

export const StyledSideNav = styled.div`
  background-color: ${ds.brand('lilDark')};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  position: fixed;
  width: 260px;

  @media (max-width: ${ds.bp('md')}) {
    display: none;
  }

  @media (max-width: ${ds.bp('md')}) {
    width: 200px;
  }

  img {
    height: 20px;
    margin-right: 1em;
    vertical-align: bottom;
    width: 20px;
  }

  a {
    border-bottom: 1px solid ${ds.brand('dark')};
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem 1em;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${ds.brand('dark')};
      color: #343078;
    }
  }
`;
