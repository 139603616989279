import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { type FC, type FormEvent } from 'react';

type AddMemberModalProps = {
  title: string;
  modal: {
    isShow: boolean;
    showToggler: () => void;
  };
  submit: {
    label: string;
    submit: (e: FormEvent) => void;
  };
  description: string;
};

const useStyles = makeStyles(() => ({
  button: {
    width: '100px',
  },
}));

export const ConfirmModal: FC<AddMemberModalProps> = ({ title, modal, submit, description }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xs'));

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    submit.submit(e);
  };

  return (
    <Dialog open={modal.isShow} onClose={modal.showToggler}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant={matches ? 'body2' : 'body1'} gutterBottom>
          {description}
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: '16px 24px' }}>
        <Button
          className={classes.button}
          onClick={modal.showToggler}
          color="primary"
          variant="text"
        >
          キャンセル
        </Button>
        <Button
          className={classes.button}
          type="submit"
          color="primary"
          variant="contained"
          onClick={submitHandler}
          autoFocus
          disableElevation
          style={{ borderRadius: '20px' }}
        >
          {submit.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
